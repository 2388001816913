import { Module } from 'vuex';
import DocTypesMethods from '@/store/docTypes/methods/docTypes.methods';
import { DOC_TYPE_EMPTY_STATE } from './docTypes.state';
import { DocTypeStateInterface } from './interfaces/docTypes.interface';
import { docTypeHttpRepository } from '@/modules/docTypes/repositories/docType.repository';
import { CountryDocType } from '@/modules/docTypes/interfaces/docType.interface';
import { getParams } from '@/utils/global-functions';

const docTypes: Module<DocTypeStateInterface, any> = {
    namespaced: true,
    state: DOC_TYPE_EMPTY_STATE,
    getters: {
        [DocTypesMethods.getters.GET_MAIN_DOC_TYPES](state): CountryDocType[] {
            return state.mainCountriesDocTypes;
        },
        [DocTypesMethods.getters.GET_SECONDARY_DOC_TYPES](state): CountryDocType[] {
            return state.secondaryCountriesDocTypes;
        },
        [DocTypesMethods.getters.GET_DOC_TYPES](state): CountryDocType[] {
            return state.docTypes;
        }
    },
    mutations: {
        [DocTypesMethods.mutations.SET_MAIN_DOC_TYPES](state, docTypes: CountryDocType[]): void {
            state.mainCountriesDocTypes = docTypes;
        },
        [DocTypesMethods.mutations.SET_SECONDARY_DOC_TYPES](state, docTypes: CountryDocType[]): void {
            state.secondaryCountriesDocTypes = docTypes;
        },
        [DocTypesMethods.mutations.SET_DOC_TYPES](state, docTypes: CountryDocType[]): void {
            state.docTypes = docTypes;
        },
        [DocTypesMethods.mutations.RESET_DOC_TYPES_DATA](state): void {
            state.mainCountriesDocTypes = [];
            state.secondaryCountriesDocTypes = [];
            state.docTypes = [];
        }
    },
    actions: {
        async [DocTypesMethods.actions.FETCH_DOC_TYPES]({ commit },params: any): Promise<boolean> {
            try {
                let mainDocTypes = [];
                let secondaryDocTypes = [];
                let queryParams = null
                let countryDocs: CountryDocType[] = []
                if (params) {
                    queryParams = '?'+ getParams(params)
                    countryDocs = await docTypeHttpRepository.getDocTypes(queryParams); 
                }
                else
                    countryDocs = await docTypeHttpRepository.getAllDocTypes(); 
                
                mainDocTypes = countryDocs.filter((el: { type_doc_type: string; }) => (el.type_doc_type === "Principal"  || el.type_doc_type === "Terciario"));
                secondaryDocTypes = countryDocs.filter((el: { type_doc_type: string; }) => el.type_doc_type === "Secundario");
                commit(DocTypesMethods.mutations.SET_DOC_TYPES,countryDocs);
                commit(DocTypesMethods.mutations.SET_MAIN_DOC_TYPES,mainDocTypes);
                commit(DocTypesMethods.mutations.SET_SECONDARY_DOC_TYPES,secondaryDocTypes);
                return true
            } catch (e) {
                return false;
            }
        },
        async [DocTypesMethods.actions.FETCH_DOC_TYPE_BY_ID]({ commit },docTypeId: number): Promise<CountryDocType | undefined> {
            try {
                let countryDoc: CountryDocType = await docTypeHttpRepository.getDocTypeById(docTypeId); 
                if (countryDoc.id_ident_doc_type) return countryDoc
                return undefined
            } catch (e) {
                return undefined;
            }
        }
    },
};

export default docTypes;