export default {
    mutations: {
        SET_BANK_ACCOUNTS: 'SET_BANK_ACCOUNTS',
        SET_ORIGIN_BANKS: 'SET_ORIGIN_BANKS',
        SET_BANK_ACCOUNT: 'SET_BANK_ACCOUNT',
        RESET_BANKS_DATA: 'RESET_BANKS_DATA',
    },
    getters: {
        GET_BANK_ACCOUNTS: 'GET_BANK_ACCOUNTS',
        GET_ORIGIN_BANKS: 'GET_ORIGIN_BANKS',
        GET_BANK_ACCOUNT: 'GET_BANK_ACCOUNT',
    },
    actions: {
        FETCH_BANKS: 'FETCH_BANKS',
        FETCH_BANKS_BY_PAY_METHOD: 'FETCH_BANKS_BY_PAY_METHOD',
        FETCH_BANK_BY_ID: 'FETCH_BANK_BY_ID',
        FETCH_BANK_ACCOUNTS: 'FETCH_BANK_ACCOUNTS',
        FETCH_BANK_ACCOUNTS_BY_PAY_METHOD: 'FETCH_BANK_ACCOUNTS_BY_PAY_METHOD',
        FETCH_BANK_ACCOUNT: 'FETCH_BANK_ACCOUNT',
    },
};