import { User } from "@/modules/auth/interfaces/user.interface"
import { REFERRAL_SRATUS, REFERRAL_SRATUS_ARRAY } from "../constants/ambassadot.constants"



export function getAmbassadorLevelColor(ambassadorLevel : number){
    if (ambassadorLevel === 1) return 'badge-bronce'
    else if (ambassadorLevel === 2) return 'badge-gold'
    else if (ambassadorLevel === 3) return 'badge-silver'
    else return ''
}

export function getReferralStatus(status : string){
    if (status === REFERRAL_SRATUS.ACTIVE.key) return 'badge-sent'
    else if (status === REFERRAL_SRATUS.INACTIVE.key) return 'badge-rejected'
    else if (status === REFERRAL_SRATUS.ABSENT.key) return 'badge-inProgress'
    else return ''
}

export function getReferralStatusName(status: string){
    return REFERRAL_SRATUS_ARRAY.find(el=> el.key === status).name
}

export function ambassadorLinkAvailable(user: User){
    if (user.id_verif_level > 0){
        if (user.id_verif_level > 1) return true
        else if (user.id_verif_level === 1 && user.verif_level_apb) return true
        else return false
    }
    else return false
}