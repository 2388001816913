export default {
    mutations: {
        SET_USER_ACCOUNTS: 'SET_USER_ACCOUNTS',
        RESET_USERS_DATA: 'RESET_USERS_DATA',
    },
    getters: {
        GET_USER_ACCOUNTS: 'GET_USER_ACCOUNTS',
    },
    actions: {
        FETCH_USER_ACCOUNTS:'FETCH_USER_ACCOUNTS',
        DELETE_USER_ACCOUNTS:'DELETE_USER_ACCOUNTS',
        SET_USER_ACCOUNTS:'SET_USER_ACCOUNTS',
        INSERT_USER_ACCOUNTS:'INSERT_USER_ACCOUNTS',
        VALIDATE_CODE:'VALIDATE_CODE',
    },
};
