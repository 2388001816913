import { AxiosRepository } from '@/http/axios.repository';
import { Country,CountryCurrency,NationalityCountry } from '../interfaces/countries.interface';

const RESID_RESOURCE = 'resid_countries/getActive';
const IP_RESOURCE = 'ip_countries/getActive';
const IP_RESOURCE_BY_NAME = 'ip_countries/getIdByName';
const ALL_COUNTRIES_RESOURCE = 'all_countries/getActive';
const ISO_CODE_RESOURCE = 'resid_countries/getISOCodeById'
const POL_EXPOSE_RESOURCE = 'resid_countries/isPolExp'
const DESTINY_COUNTRIES_RESOURCE = 'countries/destiny'
const ORIG_DEST_COUNTRY_CURRENCY = 'countries/countriesCurrencies'

export class CountriesRepository extends AxiosRepository {
    
    async getAllResidenceCountries(): Promise<Country[]> {
        return await super.getAll(RESID_RESOURCE+`?countriesType=${process.env.VUE_APP_EUROPE_VERSION ? 'eu' : 'latam'}`);
    }

    async getAllDestinyCountries(): Promise<Array<{idCountry: string,code:string,name:string}>> {
        return await super.getAll(DESTINY_COUNTRIES_RESOURCE);
    }

    async getAllNationalityCountries(): Promise<NationalityCountry[]> {
        return super.getAll(IP_RESOURCE);
    }

    async getAllCountries(): Promise<NationalityCountry[]> {
        return super.getAll(ALL_COUNTRIES_RESOURCE);
    }

    async getCountryIdByName(name: string): Promise<any[]> { // cambiar any
        return super.get(name,IP_RESOURCE_BY_NAME);
    }

    async getCountryCode(id: number): Promise<any>{
        return super.get(id,ISO_CODE_RESOURCE)
    }

    async isPoliticallyExpose(Countryd: number): Promise<any>{
        return super.get(Countryd,POL_EXPOSE_RESOURCE)
    }

    async getCountriesCurrencies(params: string): Promise<{origin: CountryCurrency[],destiny: CountryCurrency[],countriesUserHasSent?: CountryCurrency[]}>{
        return super.getAll(ORIG_DEST_COUNTRY_CURRENCY+params)
    }

    async getFrequentDestinations(email_user: string): Promise<Country[]>{
        return super.getAll(`reports/users/${email_user}/frequentDestinations/top`)
    }

}

export const countriesHttpRepository = new CountriesRepository();