import axios from "axios";
import { EnvironmentConstants } from "../constants/enviromentConstants";
import { RepositoryInterface } from "@/http/interfaces/repository.interface";
import { finishSession } from "@/modules/auth/functions/functions";
import router from "@/router";
import store from "@/store";
import { auth } from "@/store/namespaces"
import AuthMethods from "@/store/auth/methods/auth.methods"
import config from '@/constants/clientIp.variable'

axios.interceptors.response.use(response => {
    return response;
}, async error => {
    if (error.response && error.response.status === 401) {
        if (error.response.data.message === "There is already an active session with this user. Try again in a few minutes."){
            finishSession()
            // router.push({name: 'ActiveSession'}).catch(() => {});
        }
        else if (error.response.data.message === "Unauthorized"){
            finishSession()
            await router.push({name: 'LogOut', params:{title: 'La sesión ha expirado',subtitle:'Ha culminado el tiempo de la sesión. ¡Pero no se preocupe! Puede volver a ingresar presionando el siguiente botón',lg: '5',md:"6"}}).catch(() => {});
        }
    }
    return Promise.reject(error);
});

export abstract class AxiosRepository implements RepositoryInterface {
    private host = EnvironmentConstants.HOST;

    public async delete(id: number, resource: string): Promise<any> {
        return (await axios.delete(this.host + resource + '/' + id,{
            headers: {
              'Client-Ip': config.clientIp ? config.clientIp : '0.0.0.0'
            }
        })).data;
    }

    public async deleteAll(data: {}, resource: string): Promise<any> {
        return (await axios.delete(this.host + resource, { data })).data;
    }

    public async get(id: number | string, resource: string): Promise<any> {
        return (await axios.get(this.host + resource + '/' + id,{
            headers: {
              'Client-Ip': config.clientIp ? config.clientIp : '0.0.0.0'
            }
        })).data;
    }

    public async getAll(resource: string): Promise<any> {
        return (await axios.get(this.host + resource,{
            headers: {
              'Client-Ip': config.clientIp ? config.clientIp : '0.0.0.0'
            }
        })).data
    }

    async post(data: {}, resource: string): Promise<any> {
        return (await axios.post(this.host + resource, data,{
            headers: {
              'Client-Ip': config.clientIp ? config.clientIp : '0.0.0.0'
            }
        })).data;
    }

    async postData(data: {}, resource: string): Promise<any> {
        return (await axios.post(this.host + resource, data,{
            headers: {
              'Content-Type': 'multipart/form-data',
              'Client-Ip': config.clientIp ? config.clientIp : '0.0.0.0'
            }
        })).data;
    }

    // async put(id: number, data: {}, resource: string): Promise<any> {
    //     return (await axios.put(this.host + resource + '/' + id, data)).data;
    // }
    async put(data: {}, resource: string): Promise<any> {
        return (await axios.put(this.host + resource + '/', data,{
            headers: {
              'Client-Ip': config.clientIp ? config.clientIp : '0.0.0.0'
            }
        })).data;
    }

    async patch(id: number | string, resource: string, data?: any): Promise<any>{
        return (await axios.patch(this.host+resource+'/'+id,data ? data : {},{
            headers: {
              'Client-Ip': config.clientIp ? config.clientIp : '0.0.0.0'
            }
        })).data
    }

    async head(resource: string): Promise<any>{
        return (await axios.head(this.host+resource,{
            headers: {
              'Client-Ip': config.clientIp ? config.clientIp : '0.0.0.0'
            }
        })).data
    }

}