
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
/* CONSTANTS */
/* INTERFACES */
/* FUNCTIONS */
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { showToast } from '@/utils/global-functions';
import { REMITTANCE_TYPES } from '@/modules/remittance/constants/remittance.constants';
import { auth } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import { User } from '@/modules/auth/interfaces/user.interface';

@Component({
    components: {
        SvgIcon,
    },
})
export default class NoRemittancePopup extends Vue {
    @Prop() showModal!: boolean;
    @Prop() noClose?: boolean;
    show: boolean = false;
    loading: boolean = false;
    answer = null;

    /* MODAL */
    @Watch('showModal')
    async renderModal() {
        this.show = this.showModal;
        this.loading = true;
        this.loading = false;
        this.answer = null;
    }

    @Watch('show')
    sendToParent(newVal: boolean) {
        this.$emit('changeModalStatus', newVal);
    }

    goToLink() {
        window.location.href = `https://signup.getsilt.com/?company_app_id=${
            process.env.VUE_APP_HOST.includes('https://api.bithonor.com/')
                ? process.env.VUE_APP_EUROPE_VERSION &&
                process.env.VUE_APP_EUROPE_VERSION === 'true'
                    ? process.env.VUE_APP_SILT_ID_PROD_EUROPE
                    : process.env.VUE_APP_SILT_ID_PROD_LATAM
                : process.env.VUE_APP_SILT_ID_TEST
        }&redirect_url=${
            process.env.VUE_APP_DOMAIN
        }/tablero&meta={"email_user":"${encodeURIComponent(
            this.userData.email_user
        )}"}`;
        this.show = false;
    }

    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    /* END MODAL */
}
