import { ChatStateInterface} from './interfaces/chat.interface';

export const CHAT_EMPTY_STATE: ChatStateInterface = {
    messages: [],
    loginMessages: [
        // {
        //    uniq_id: 1,
        //    is_sent: false,
        //    file: undefined,
        //    message_body:{msg: 'Muy buenos días ☀️', type:'text'}
        // }
    ]
};
