export default {
    mutations: {
        SET_DEPOSIT_METHODS_BY_BANK: 'SET_DEPOSIT_METHODS_BY_BANK',
        RESET_PAY_METHODS_DATA: 'RESET_PAY_METHODS_DATA',
    },
    getters: {
        GET_DEPOSIT_METHODS_BY_BANK: 'GET_DEPOSIT_METHODS_BY_BANK',
    },
    actions: {
        FETCH_PAY_METHODS_BY_COUNTRY: 'FETCH_PAY_METHODS_BY_COUNTRY',
        FETCH_PAY_METHOD_BY_ID: 'FETCH_PAY_METHOD_BY_ID',
        FETCH_DEPOSIT_METHODS_BY_BANK: 'FETCH_DEPOSIT_METHODS_BY_BANK',
    },
};