
import { Component, Vue, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import AppearTransition from '@/components/utilities/transitions/AppearTransition.vue' 
import {chat,auth} from '@/store/namespaces';
import ChatMethods from '@/store/chat/methods/chat.methods';
import AuthMethods from '@/store/auth/methods/auth.methods';
import { LoginMessage } from '../interfaces/message.interface';
import {v4 as uuidv4} from 'uuid';
import { Socket } from 'vue-socket.io-extended'
import { MESSAGE_TYPES } from '../constants/message.constants';
import { epotchToTimeZone, getDMY } from '@/utils/date-functions';
import moment from 'moment-timezone';
import { User } from '@/modules/auth/interfaces/user.interface';
import AudioPlayer from '@/components/utilities/AudioPlayer.vue';

@Component({
  components: {
      SvgIcon,
      AppearTransition,
      AudioPlayer
  },
})
export default class WidgetChat extends Vue {
    showChat: boolean = false;
    newMessage = '';
    atcAsign = null;

    async mounted(){
        if (this.isAuthenticated){
            await this.setMessages([])
            this.connectToProChat()
        }
        else {
            this.connectToBasicChat()
            if (!this.uuid){
                let uuid = uuidv4();
                this.setUuid(uuid)
            }
        }
        this.scrollToBottom()
    }

    unmounted(){
        this.$socket.client.off('to_pro_chat',(newVal)=>{
        });
        this.$socket.client.off('chat_asign',(newVal)=>{
        });
    }

    @Watch('isAuthenticated')
    watchAuthentication(newVal: boolean){
        if (newVal) this.connectToProChat()
        else this.connectToBasicChat()
    }
    
    
    /* BASIC CHAT */
    connectToBasicChat(){
        this.$socket.client.emit('new_connection_basic_chat',this.uuid) 
        this.$socket.client.on('to_basic_chat',this.onMessageUpdate)
        this.$socket.client.on('chat_asign',this.onChatAsign)
    }

    onChatAsign(response: {atc_fake_name: string, email_user: string,uniq_id?: string}){
        this.atcAsign = response.atc_fake_name;
    }

    async onMessageUpdate(message: any){
        if (message.messages){
                if (message.messages.length > 0)
                    await this.setLoginMessages(message.messages)
        }
        else if (message.message){
           await this.loginMessages.push(message)
        }
        if (this.showChat){
            this.scrollToBottom();
        }
    }

    @Watch('showChat')
    watchShowChat(newVal: boolean){
        if (newVal && this.messages.length){
            this.scrollToBottom();
        }
        else if (!newVal && this.isAuthenticated){
            this.$socket.client.off('to_pro_chat',(newVal)=>{
            });
            this.$socket.client.off('chat_asign',(newVal)=>{
            });
        }
    }

       
    async sendBasicMessage(){
        if (this.newMessage.length){
            let date: string = new Date().toJSON();
            this.$socket.client.emit('from_basic_chat',{
                file: undefined,
                message_body:{msg: this.newMessage,type: MESSAGE_TYPES.TEXT},
                msg_date: date,
                is_sent: false,
                uniq_id: this.uuid,
                time_zone: moment.tz.guess()
            })
            this.newMessage = ''; 
        }
    }

    /* END BASIC CHAT */

    /* PRO CHAT */
    connectToProChat(){
        this.$socket.client.off('to_pro_chat',(newVal)=>{
        });
        this.$socket.client.off('chat_asign',(newVal)=>{
        });
        this.$socket.client.emit('new_connection', this.userData.email_user);    
        this.$socket.client.on('to_pro_chat',this.newMessages);
        this.$socket.client.on('chat_asign',this.onChatAsign);
    }

    async newMessages(response: any){
        let message = response;
         if (message.messages){
                if (message.messages.length > 0)
                    await this.setMessages(message.messages)
        }
        else if (message.message || message.file){
           if (response.type === MESSAGE_TYPES.IMAGE || response.type === MESSAGE_TYPES.VOICE){
                let blob = new Blob([new Uint8Array(message.file)]);
                message.fileSrc = {
                    src: window.URL.createObjectURL(blob)
                }
            }
            await this.proMessages.push(message);
        }
        this.scrollToBottom();
        //this.scrollToLastElement();
        // this.messages.find(el=> { if (el.type === MESSAGE_TYPES.IMAGE && el.file === undefined ) return el})
    }

    async sendProMessage(){
        if (typeof this.newMessage === 'string' && this.newMessage.trim().length === 0) {}
        else{
            let date: string = new Date().toJSON();
              this.$socket.client.emit('from_pro_chat',{
                email_user: this.userData.email_user,
                file: null,
                message_body:{msg: this.newMessage,type: MESSAGE_TYPES.TEXT},
                msg_date: date,
                is_sent: false,
                uniq_id: null,
                time_zone: null
            })
            this.newMessage = '';
        }
    }

    /* END PRO CHAT */

    sendMessage(){
        if (this.isAuthenticated) this.sendProMessage()
        else this.sendBasicMessage()
    }

    get messages(){
        if (this.isAuthenticated) return this.proMessages
        else return this.loginMessages
    }

    epochToDate(epoch: any){
        return getDMY(epotchToTimeZone(epoch))
    }

    scrollToBottom(){
        try{
            const element = document.getElementById("chat-messages");
            element.scrollTop = element.scrollHeight;
        }
        catch(e){
        }
    }

    scrollToLastElement(){
        var lastElement = document.getElementById("last-message");
        lastElement.scrollIntoView
    }


x
    /* CHAT */
    @chat.Getter(ChatMethods.getters.GET_LOGIN_MESSAGES)
    loginMessages!: LoginMessage[];
    @chat.Action(ChatMethods.actions.SET_LOGIN_MESSAGES)
    setLoginMessages!:(messages: LoginMessage[]) => Promise<void>;
    @chat.Action(ChatMethods.actions.SET_MESSAGES)
    setMessages!: (messages: Message[]) => Promise<void>
    @chat.Getter(ChatMethods.getters.GET_MESSAGES)
    proMessages!: Message[]
    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    @auth.Getter(AuthMethods.getters.GET_NOT_AUTHENTICATED_UUID)
    uuid!: string;
    @auth.Getter(AuthMethods.getters.GET_AUTHENTICATION)
    isAuthenticated!: boolean;
    @auth.Action(AuthMethods.actions.SET_NOT_AUTHENTICATED_UUID)
    setUuid!: (uuid: string) => void;
}
