import Vue from "vue"
import Vuex from "vuex"
import auth from '@/store/auth/auth';
import countries from '@/store/countries/countries';
import verifLevels from '@/store/verificationLevels/verificationLevels';
import notifications from '@/store/notifications/notifications';
import beneficiaries from '@/store/beneficiaries/beneficiaries';
import currencies from '@/store/currencies/currencies';
import payMethods from '@/store/payMethods/payMethods';
import chat from '@/store/chat/chat';
import banks from '@/store/banks/banks';
import remittance from '@/store/remittance/remittance';
import docTypes from '@/store/docTypes/docTypes';
import rates from '@/store/rates/rates';
import balances from '@/store/balances/balances';
import ambassadors from '@/store/ambassadors/ambassadors';
import operationRoutes from '@/store/operationRoutes/operationRoutes';
import exchange from '@/store/exchange/exchange';
import users from '@/store/users/users';
import wholesalePartners from '@/store/wholesalePartners/wholesalePartners'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    ambassadors,
    balances,
    banks,
    beneficiaries,
    chat,
    countries,
    currencies,
    docTypes,
    notifications,
    payMethods,
    rates,
    remittance,
    verifLevels,
    operationRoutes,
    exchange,
    users,
    wholesalePartners
  },
  plugins: [
    createPersistedState({
      paths:['auth.user','auth.isAuthenticated','auth.notAuthenticatedUUid', 'auth.ambassadorLink','countries.currentDestinyCountryCurrency','auth.publicIp'],
      storage: {
        getItem: (key) => ls.get('CR-'+key),
        setItem: (key, value) => ls.set('CR-'+key, value),
        removeItem: (key) => ls.remove('CR-'+key),
      },
    }),
  ],
})
