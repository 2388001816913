
import { Component, Prop, Vue,Watch } from 'vue-property-decorator';
import Layout from '@/components/layout/Layout.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { auth } from './store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods'
import config from '@/constants/clientIp.variable'
import {tronScanRepository} from '@/modules/exchange/repositories/tronScan.httpRepository'
import {btcRepository} from '@/modules/exchange/repositories/btc.httpRepository'
import {txIdValidation} from '@/modules/exchange/functions/txIdValidation.function'
import Loader from '@/components/utilities/Loader.vue';
import WidgetChat from '@/modules/chat/components/WidgetChat.vue';
import { EnvironmentConstants } from '@/constants/enviromentConstants';

@Component({
    components:{
        Layout,
        Loader,
        WidgetChat
    }
})
export default class App extends Vue{

    $refs: any = {}

    get idleVue(){
        return this.$store.state.idleVue.isIdle
    }

    @Watch('idleVue')
    async logOut(newVal: boolean){
        if (newVal && this.isAuthenticated){
            if (await this.logout()){
                await this.$router.push({name: 'LogOut', params:{title: 'La sesión ha expirado',subtitle:'Ha culminado el tiempo de la sesión. ¡Pero no se preocupe! Puede volver a ingresar presionando el siguiente botón',lg: '5',md:"6"}}).catch(() => {});
            }    
        }
    }

    beforeCreate(){
        const Http = new XMLHttpRequest();
        const url = 'https://api.ipify.org?format=json';
        Http.open('GET',url);
        Http.send();
        Http.onreadystatechange = async (e)=>{
            if (Http.responseText === '' || !Http.responseText || Http.responseText === undefined)
                config.clientIp = '0.0.0.0'
            else{
                config.clientIp = JSON.parse(Http.responseText).ip
            }
        }
    }

    get route(){
        return this.$route 
    }

    async created () {
        AOS.init();
        this.$socket.client.on("connect_error", () => {
            setTimeout(() => {
                this.$socket.client.connect();
            }, 1000);
        });
    }
    
    @auth.Getter(AuthMethods.getters.GET_AUTHENTICATION)
    isAuthenticated!: boolean;
    @auth.Action(AuthMethods.actions.FETCH_PUBLIC_IP)
    fetchPublicIp!:()=> Promise<boolean>
    @auth.Action(AuthMethods.actions.LOGOUT)
    logout!: () => Promise<boolean>
}
