import { AxiosRepository } from '@/http/axios.repository';
import { CountryCurrency } from '@/modules/countries/interfaces/countries.interface';
import { CryptoCurrency, Currency } from '../interfaces/currencies.interface';

const CURRENCIES_RESOURCE = 'currencies';


export class CurrenciesRepository extends AxiosRepository {
    
    async getCurrenciesByCountries(params: string): Promise<Currency[]> {
        return await super.getAll(CURRENCIES_RESOURCE+'?'+params);
    }

    async getTotalSentByCurrencies(emal_user: string): Promise<CountryCurrency[]> {
        return await super.getAll(`reports/users/${emal_user}/${CURRENCIES_RESOURCE}/totalAmount`);
    }

    async getCryptoCurrencies(): Promise<CryptoCurrency[]> {
        return await super.getAll(CURRENCIES_RESOURCE+'?type=crypto');
    }
}

export const currenciesHttpRepository = new CurrenciesRepository();