import { Module } from 'vuex';
import BanksMethods from '@/store/banks/methods/banks.methods';
import { BANKS_EMPTY_STATE } from './banks.state';
import { BanksStateInterface } from './interfaces/banks.interface';
import { banksHttpRepository } from '@/modules/banks/repositories/bank.repository';
import { getParams } from '@/utils/global-functions';
import { Bank } from '@/modules/banks/interfaces/bank.interface';
import { BankAccount } from '@/modules/banks/interfaces/bankAccounts.interface';

const banks: Module<BanksStateInterface, any> = {
    namespaced: true,
    state: BANKS_EMPTY_STATE,
    getters: {
        [BanksMethods.getters.GET_BANK_ACCOUNTS](state): BankAccount[]{
            return state.bankAccounts
        },
        [BanksMethods.getters.GET_ORIGIN_BANKS](state): Bank[]{
            return state.originBanks
        },
        [BanksMethods.getters.GET_BANK_ACCOUNT](state): BankAccount{
            return state.remittanceBankAccount
        },
    },
    mutations: {
        [BanksMethods.mutations.SET_BANK_ACCOUNTS](state, bankAccounts : BankAccount[]): void {
            state.bankAccounts = bankAccounts;
        },
        [BanksMethods.mutations.SET_ORIGIN_BANKS](state, originBanks : Bank[]): void {
            state.originBanks = originBanks;
        },
        [BanksMethods.mutations.SET_BANK_ACCOUNT](state, remittanceBankAccount : BankAccount): void {
            state.remittanceBankAccount = remittanceBankAccount;
        },
        [BanksMethods.mutations.RESET_BANKS_DATA](state): void {
            state.bankAccounts=[],
            state.originBanks= [],
            state.remittanceBankAccount= null
        }
    },
    actions: {
        async [BanksMethods.actions.FETCH_BANKS]({ commit }, params: any): Promise<Bank[] | undefined> {
            try {
                let httParams = getParams(params)
                let banks : Bank[] = await banksHttpRepository.getBanks(httParams)
                if (banks.length > 0){
                    if (params.save){
                        commit(BanksMethods.mutations.SET_ORIGIN_BANKS,banks)
                    }
                    return banks;
                }
                return undefined
            } catch (e) {
                return undefined;
            }
        },
        async [BanksMethods.actions.FETCH_BANKS_BY_PAY_METHOD]({ commit }, payMethodId: number): Promise<Bank[] | undefined> {
            try {
                let banks : Bank[] = await banksHttpRepository.getBanksByPayMethod(payMethodId)
                if (banks.length > 0){
                    return banks;
                }
                return undefined
            } catch (e) {
                return undefined;
            }
        },
        async [BanksMethods.actions.FETCH_BANK_BY_ID]({ commit }, bankId: number): Promise<Bank | undefined> {
            try {
                let bank : Bank = await banksHttpRepository.getBankById(bankId)
                if (bank.idBank){
                    return bank;
                }
                return undefined
            } catch (e) {
                return undefined;
            }
        },
        async [BanksMethods.actions.FETCH_BANK_ACCOUNTS]({ commit }, data:{countryId: number,currencyId:number}): Promise<boolean> {
            try {
                let banks : BankAccount[] = await banksHttpRepository.getBankAccounts(data.countryId,data.currencyId)
                commit(BanksMethods.mutations.SET_BANK_ACCOUNTS,banks)
                return true
            } catch (e) {
                return false;
            }
        },
        async [BanksMethods.actions.FETCH_BANK_ACCOUNTS_BY_PAY_METHOD]({ commit }, payMethodId: number): Promise<BankAccount[] | undefined> {
            try {
                let banks : BankAccount[] = await banksHttpRepository.getBanksAccountByPayMethod(payMethodId);
                if (banks.length > 0) return banks;
                return undefined
            } catch (e) {
                return undefined;
            }
        },
        async [BanksMethods.actions.FETCH_BANK_ACCOUNT]({ commit }, bankAccountId: number): Promise<boolean> {
            try {
                let bank : BankAccount = await banksHttpRepository.getBankAccountById(bankAccountId)
                let blob = new Blob([new Uint8Array(bank.image.data)]);
                bank.imageSrc= window.URL.createObjectURL(blob);
                commit(BanksMethods.mutations.SET_BANK_ACCOUNT,bank)
                return true
            } catch (e) {
                return false;
            }
        },
    }
};

export default banks;