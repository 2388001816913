import { AxiosRepository } from '@/http/axios.repository';
import { FrequentBeneficiary, TopFrequentBeneficiary } from '../interfaces/beneficiaries.interfaces';

const FREQUENT_BENEFICIARY_RESOURCE = 'beneficiaries/frequentBeneficiaries';
const BENEFICIARY_RESOURCE = 'beneficiaries';

export class BeneficiariesHttpRepository extends AxiosRepository {
    
    async getAllFrequentBeneficiaries(email_user: string): Promise<FrequentBeneficiary[]> { 
        return super.getAll(FREQUENT_BENEFICIARY_RESOURCE+'/'+email_user);
    }

    async createFrequentBeneficiary(body: FrequentBeneficiary,params: string): Promise<FrequentBeneficiary>{
        return super.post(body, FREQUENT_BENEFICIARY_RESOURCE+'?'+params)
    }
    async updateFrequentBeneficiary(body: FrequentBeneficiary,beneficiary: number): Promise<boolean>{
        return super.put(body, FREQUENT_BENEFICIARY_RESOURCE+'/'+beneficiary)
    }

    async deleteFrequentBeneficiary(beneficiaryId: number): Promise<boolean>{
        return super.delete(beneficiaryId,FREQUENT_BENEFICIARY_RESOURCE)
    }

    async getTopFrequentBeneficiaries(email: string): Promise<TopFrequentBeneficiary[]> { 
        return super.getAll(`reports/users/${email}/frequentBeneficiaries/top`);
    }

    async getContactRequirements(countryId: number): Promise<{requiredContact: boolean}> { 
        return super.getAll(BENEFICIARY_RESOURCE+'/contact-required/'+countryId);
    }
}

export const beneficiariesHttpRepository = new BeneficiariesHttpRepository();