export default {
    mutations: {
        SET_TOTAL_SENT_BY_CURRENCIES: 'SET_TOTAL_SENT_BY_CURRENCIES',
        SET_CRYPTO_CURRENCIES: 'SET_CRYPTO_CURRENCIES',
        RESET_CURRENCIES_DATA: 'RESET_CURRENCIES_DATA',
    },
    getters: {
        GET_TOTAL_SENT_BY_CURRENCIES: 'GET_TOTAL_SENT_BY_CURRENCIES',
        GET_CRYPTO_CURRENCIES: 'GET_CRYPTO_CURRENCIES',
    },
    actions: {
        FETCH_CURRENCIES_BY_COUNTRIES: 'FETCH_CURRENCIES_BY_COUNTRIES',
        FETCH_TOTAL_SENT_BY_CURRENCIES: 'FETCH_TOTAL_SENT_BY_CURRENCIES',
        FETCH_CRYPTO_CURRENCIES: 'FETCH_CRYPTO_CURRENCIES',
    },
};