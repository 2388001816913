
import { RatesStateInterface } from './interfaces/rates.state.interface';


export const RATES_EMPTY_STATE: RatesStateInterface = {
    rateTypes: [],
    rangeRates: [],
    promoRates: [],
    minAmmountInRemittance: {
        value:0,
        currency:''
    },
    userRates:{
        type: null,
        rates: null,
        firstCall: null
    },
    userLocalRates:{
        type: null,
        rates: null,
        firstCall: null
    },
    fullRates:{
        manualRates:[],
        improvedRate:null,
        expectedImprovedRate: null,
        regularRate: null,
        userRates: {
            promoRates: [],
            manualRates: [],
            individualRate: null
        },
        vipAcum: 0,
        localAmountLimit: 0
    },
    USDConvertions: {
        date: null,
        base: null,
        rates: {}
    },
    ratesTakenAdvantageOf: []
};


