
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueMarqueeBar from "vue-marquee-bar";
import SvgIcon from '@/components/utilities/SvgIcon.vue';

@Component({
    components:{
        // Footer,
        VueMarqueeBar,
        SvgIcon
    }
})
export default class Marquee extends Vue{
    @Prop() notifications!: Array<any>;
}
