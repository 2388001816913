export const DEFAULT_MESSAGES={
    REMITTANCE: {
        START: '¡Hola!, deseo enviar dinero a ',
        FINISH: ', ¿Cómo puedo proceder?'
    },
    CONSULTS:[
        {
            TITLE: '¿En cuánto tiempo se hacer efectivo mi pago al beneficiario?',
            SUBTITLE: `El tiempo de recepción del dinero varia desde 30 min a 6 horas, esto depende del tipo de pago seleccionado en origen y en destino.\n Por ejemplo:\nUn envío a Venezuela:\n ✔ Vía transferencia tradicional: entre 2 y 4 horas si el banco del beneficiario es Mercantil, Banesco, Provincial o Venezuela. A otros bancos el tiempo varia de acuerdo al procesamiento de transferencias interbancaria en Venezuela (normalmente 1 dio hábil)\n ✔ Via Pago Movil (recomendado): Entre 30 min a 1 hora a cualquier nivel nacional.`
        },
        {
            TITLE: '¿Puedo dividir o fraccionar mi operación?',
            SUBTITLE: 'Si, puedes dividir o fraccionar tu operación hasta un máximo de 3 beneficiarios en el mismo país de destino.',
        },
        {
            TITLE: '¿Cuales son los bancos o formas de pago disponibles?',
            SUBTITLE: 'Métodos de pago disponibles en cada país:\n\n 🇦🇷 Argentina: Transferencia Bancaria\n🇧🇷 Brasil: Transferencia Bancaria, Transferencia PIX y Deposito Bancario\n 🇨🇱 Chile: Transferencia Bancaria\n 🇨🇴 Colombia: Transferencia Bancaria y Deposito Bancario\n 🇵🇪 Perú: Transferencia Bancaria y Deposito Bancario\n 🇩🇴 Rep. Dominicana: Transferencia Bancaria\n 🇪🇺 España y el resto de Europa: Transferencia Bancaria SEPA y TPV Vitual (Tarjetas Visa y Mastercard)\n\n Bancos disponibles en cada pais:\n\n 🇦🇷 Argentina: Brubank\n 🇧🇷 Brasil: Itaú\n 🇨🇱 Chile: Banco de Chile\n 🇨🇴 Colombia: Bancolombia, Davivienda y Nequi\n 🇵🇪 Perú: Interbank, BCP, BBVA, Yape y Plin\n 🇩🇴 Rep. Dominicana: Banreservas\n 🇪🇺 España y el resto de Europa: Revolut',
        },
        {
            TITLE: '¿Como le hacen llegar el dinero a mis beneficiarios?',
            SUBTITLE: 'El dinero es enviado a cada beneficiarios través de los siguientes Bancos y métodos de pago en cada país:\n\n 🇦🇷 Argentina: Transferencia Bancaria Nacional\n 🇧🇷 Brasil: Transferencia PIX\n 🇨🇱 Chile: Transferencia Bancaria Nacional\n 🇨🇴 Colombia: Transferencia Bancaria (solo a Bancolombia, Davivienda o Nequi)\n 🇵🇪 Perú: Transferencia Bancaria (solo a BCP)\n 🇩🇴 Rep. Dominicana: Transferencia Bancaria via Banreservas\n 🇪🇺 España y el resto de Europa: Transferencia SEPA\n 🇻🇪 Venezuela: Transferencia Bancaria  (a cualquier banco vía Banesco, Mercantil, Provincial y Venezuela) y Pago Movil Interbancario (recomendado)',
        },
        {
            TITLE: '¿Ofrecen servicio de Pago Movil en Venezuela?',
            SUBTITLE: 'Si, es el servicio recomendado y preferido por nuestro clientes, facilita el envío de dinero a cualquier banco a nivel nacional en menos de 1 hora sin costos adicionales.',
        },
        {
            TITLE: '¿Puedo hacerle llegar Dólares en efectivo a mi beneficiario en Venezuela?',
            SUBTITLE: 'Si, puedes solicitar el servicio de entrega de Dólares en Efectivo en Venezuela a través de nuestro chat de atención web. Solo disponible en Caracas y Puerto Ordaz.',
        },
        {
            TITLE: '¿Aceptan criptomonedas?',
            SUBTITLE: 'Sí, aceptamos Bitcoin (BTC) y puedes:\n\n ✔️ Usar criptomonedas como forma de pago de tus operaciones\n ✔️ Comprar y enviar criptomonedas a tus beneficiarios',
        },
        {
            TITLE: '¿Hay un monto mínimo y máximo para el envío de dinero?',
            SUBTITLE: 'Si. Los montos mínimos son:\n\n Los montos máximos de envió varían de acuerdo a su nivel de verificación. Puedes consultar los montos máximo a tu asesor vía chat o a través enlace: Limites de Envío',
        },
    ]
}

export const MESSAGE_TYPES = {
    IMAGE: 'image',
    TEXT: 'text',
    VOICE: 'voice',
    PDF: 'pdf'
}
export const FILE_TYPES = {
   PDF: 'application/pdf',
   JPG: 'image/jpg',
   JPEG: 'image/jpeg',
   PNG: 'image/png',
}