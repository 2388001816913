export const VENEZUELAN_CI_TYPES=[
    'V',
    'E'
]

export const VENEZUELAN_RIF_TYPES=[
    'J'
]

export const ALT_DOC_TYPES = {
    'PASSPORT' : {
        label: 'Pasaporte',
        id: -2
    },
    'ID' : {
        label: 'Cédula/Doc. de Identidad',
        id: -3
    }
}