import { User } from "@/modules/auth/interfaces/user.interface";

export const levelNames = {
    "0": "Básico",
    "1": "Intermedio",
    "2": "Avanzado"
}


export const VL_NOTIFICATIONS = {
    LEVEL_ZERO:{
        TITLE: '¿Aún estas en Nivel Básico? 👎',
        SUBTITLE: 'Aumentando al Nivel de Verificación Intermedio podrás disfrutar de un mayor límite de envío semanal y mensual, transferencia desde Cuentas Bancarias de terceros, y mucho más. Para más información haz click en "Aumentar de Nivel".'
    },
    LEVEL_ONE:{
        APPROVED:{
            TITLE: 'No te quedes a mitad de camino...🔝',
            SUBTITLE: 'Aumentando al Nivel de Verificación Avanzado podrás disfrutar de un mayor límite de envío semanal y mensual, transferencia desde Cuentas Bancarias propias (en el exterior) y más. Para más información haz click en "Aumentar de Nivel"'
        },
        REJECTED:{
            TITLE:'Tu solicitud a Nivel Intermedio ha sido rechazada👎',
            SUBTITLE: 'No te desanimes, puedes solicitarlo de nuevo. Haz click en "Aumentar de Nivel" y carga nuevamente los datos que han sido rechazados.'
        }
    },
    LEVEL_TWO:{
        REJECTED:{
            TITLE: 'Tu solicitud a Nivel Avanzado ha sido rechazada👎',
            SUBTITLE: 'No te desanimes, puedes solicitarlo de nuevo. Haz click en "Aumentar de Nivel" y carga nuevamente los datos que han sido rechazados.'
        }
    }
}

export function getVerifLevelNotifications(userData: User){
    let title = null;
    let subtitle = null
    switch (userData.id_verif_level) {
        case 0:
            title = VL_NOTIFICATIONS.LEVEL_ZERO.TITLE;
            subtitle = VL_NOTIFICATIONS.LEVEL_ZERO.SUBTITLE;
            break;
        case 1:
            if (userData.verif_level_apb){
                title = VL_NOTIFICATIONS.LEVEL_ONE.APPROVED.TITLE;
                subtitle = VL_NOTIFICATIONS.LEVEL_ONE.APPROVED.SUBTITLE;
            }else{
                title = VL_NOTIFICATIONS.LEVEL_ONE.REJECTED.TITLE;
                subtitle = VL_NOTIFICATIONS.LEVEL_ONE.REJECTED.SUBTITLE;
            }
            break;
        case 2:
            if (!userData.verif_level_apb){
                title = VL_NOTIFICATIONS.LEVEL_TWO.REJECTED.TITLE;
                subtitle = VL_NOTIFICATIONS.LEVEL_TWO.REJECTED.SUBTITLE;
            }
        }
    return {title: title,subtitle: subtitle}
}