export default {
    mutations: {
        SET_FREQUENT_BENEFICIARIES: 'SET_FREQUENT_BENEFICIARIES',
        SET_TOP_FREQUENT_BENEFICIARIES: 'SET_TOP_FREQUENT_BENEFICIARIES',
        RESET_BENEFICIARIES_DATA: 'RESET_BENEFICIARIES_DATA'
    },
    getters: {
        GET_FREQUENT_BENEFICIARIES: 'GET_FREQUENT_BENEFICIARIES',
        GET_SIMULATED_FREQUENT_BENEFICIARIES: 'GET_SIMULATED_FREQUENT_BENEFICIARIES',
        GET_TOP_FREQUENT_BENEFICIARIES: 'GET_TOP_FREQUENT_BENEFICIARIES',
    },
    actions: {
        FETCH_FREQUENT_BENEFICIARIES: 'FETCH_FREQUENT_BENEFICIARIES',
        CREATE_FREQUENT_BENEFICIARY: 'CREATE_FREQUENT_BENEFICIARY',
        DELETE_FREQUENT_BENEFICIARY: 'DELETE_FREQUENT_BENEFICIARY',
        UPDATE_FREQUENT_BENEFICIARY: 'UPDATE_FREQUENT_BENEFICIARY',
        FETCH_TOP_FREQUENT_BENEFICIARIES: 'FETCH_TOP_FREQUENT_BENEFICIARIES',
        FETCH_BENEFICIARY_CONTACT_REQUIRED: 'FETCH_BENEFICIARY_CONTACT_REQUIRED',
    },
};