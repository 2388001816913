export const CRYPTO_CURRENCIES_LIST = [
    {
        currency: 'USDT',
        networks:[
            {
                networkAbrev: 'TRC20',
                network: 'Tron',
                showAbrev: true
            }
        ]
    },
    {
        currency: 'BTC',
        networks:[
            {
                networkAbrev: 'Bitcoin',
                network: 'Bitcoin',
                showAbrev: false
            }
        ]
    }
]

export const CRYPTO_CURRENCIES = {
    BTC: 'BTC',
    USDT: 'USDT'
}

export const CRYPTO_CURRENCIES_DECIMALS_CALC = {
    BTC: 5,
    USDT: 2
}

export const CURRENCY_FORMAT_CONFIG= {
    spaceBetweenAmountAndSymbol: true,
    symbolOnLeft: false,
    decimalSeparator: ',',
    thousandsSeparator:'.'
}
