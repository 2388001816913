import { AxiosRepository } from '@/http/axios.repository';
import { Exchange, PreExchange } from '../interfaces/exchange.interface';
import { ExchangeRangeRate, ExchangeRate } from '../interfaces/exchangeRate.interface';
import { ExchangeLimit } from '../interfaces/limit.interface';

const EXCHANGE_RESOURCE = 'exchanges';
const RATES_RESOURCE = '/rates';
const PRE_EXCHANGE__RESOURCE = '/pre_exchange';

export class ExchangeRepository extends AxiosRepository {
    
    async getExchanges(params?: string): Promise<Exchange[]> {
        return await super.getAll(EXCHANGE_RESOURCE+params);
    }

    async saveExchange(data: any,type: string): Promise<{id_exchange_pub:string,message:string,id_exchange:number,id_pre_exchange: number}> {
        return await super.postData(data,EXCHANGE_RESOURCE+`?type=${type}`);
    }

    async getExchangeRates(): Promise<ExchangeRate[]> {
        return await super.getAll(EXCHANGE_RESOURCE+RATES_RESOURCE);
    }

    async getExchangeRangeRates(): Promise<ExchangeRangeRate[]> {
        return await super.getAll(EXCHANGE_RESOURCE+RATES_RESOURCE+'/ranges');
    }

    async savePreExchange(data: {exchangeData: Exchange}): Promise<{message: string,id_pre_exchange: number,previous_id_pre_exchange: number}> {
        return await super.post(data,EXCHANGE_RESOURCE+PRE_EXCHANGE__RESOURCE);
    }

    async getPreExchange(email_user: string): Promise<PreExchange> {
        return await super.get(email_user,EXCHANGE_RESOURCE+PRE_EXCHANGE__RESOURCE);
    }

    async cancelPreExchange(preExchangeId: number): Promise<{message: string, email_user: string,pre_exchange: PreExchange}> {
        return await super.delete(preExchangeId,EXCHANGE_RESOURCE+PRE_EXCHANGE__RESOURCE);
    }

    async getExchangeLimits(params: string): Promise<{min: ExchangeLimit,max: ExchangeLimit}> {
        return await super.getAll(EXCHANGE_RESOURCE+'/limits'+params);
    }

}

export const exchangeHttpRepository = new ExchangeRepository();