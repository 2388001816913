
import { Component, Vue,Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class AppearTransition extends Vue { 
   
    @Prop() duration?: Number ;
    @Prop() delay?: Number ;
    @Prop() transition?: Array<any>;

   beforeEnter(el: any){
       el.style.opacity = 0;
       if (this.transition)
            el.style.transform = `translate(${this.transition.join(',')})`
   }

   enter(el: any){
       let transitions = `opacity ${this.duration ? this.duration : 500}ms ease-in-out ${this.delay ? this.delay : 100}ms`;
       if (this.transition)
            transitions += `, transform ${this.duration ? this.duration : 500}ms ease-in-out ${this.delay ? this.delay : 100}ms`;
        el.style.transition = transitions;
       getComputedStyle(el);       
       setTimeout(()=>{
            el.style.opacity = 1;
            el.style.transform = 'initial'
       })
   }

}
