
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// import Footer from '../layout/Footer.vue';
import Header from '@/components/layout/Header.vue';
import VueMarqueeBar from "vue-marquee-bar";
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Marquee from '@/components/utilities/Marquee.vue';
import { notifications } from '@/store/namespaces';
import NotificationsMethods from '@/store/notifications/methods/verificationLevels.methods';
import { Notif } from '@/modules/notifications/interfaces/notification.interface';

@Component({
    components:{
        // Footer,
        Header,
        VueMarqueeBar,
        SvgIcon,
        Marquee
    }
})
export default class Layout extends Vue{

    notif = []

    // notif= [
    //     "😃 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatibus ex alias eveniet ad. Necessitatibus aperiam quaerat corporis sit sapiente molestiae voluptate modi? Suscipit ea itaque architecto qui obcaecati necessitatibus accusamus!",
    //     "🎊 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatibus ex alias eveniet ad. Necessitatibus aperiam quaerat corporis sit sapiente molestiae voluptate modi? Suscipit ea itaque architecto qui obcaecati necessitatibus accusamus!",
    //     "⛱ Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatibus ex alias eveniet ad. Necessitatibus aperiam quaerat corporis sit sapiente molestiae voluptate modi? Suscipit ea itaque architecto qui obcaecati necessitatibus accusamus!",
    // ]

    mounted(){
        this.notif= this.marqueeNotifications.map(a => a.msg);
    }

    @Watch('marqueeNotifications')
    getNotifications(){
        this.notif= this.marqueeNotifications.map(a => a.msg);
    }

    @notifications.Getter(NotificationsMethods.getters.GET_MARQUEE_NOTIFICATIONS)
    marqueeNotifications!: Notif[]
}
