import { Module } from 'vuex';
import RatesMethods from '@/store/rates/methods/rates.methods';
import { ratesHttpRepository }from '@/modules/rates/repositories/rates.repository';
import { RATES_EMPTY_STATE } from './rates.state';
import { RatesStateInterface } from './interfaces/rates.state.interface';
import { RangeRate } from '@/modules/rates/interfaces/rangeRate.interface';
import { RateType } from '@/modules/rates/interfaces/rateType.interface';
import { FullRate, Rate } from '@/modules/rates/interfaces/rate.interface';
import { getParams } from '@/utils/global-functions';
import { MANUAL_RATES } from '@/modules/rates/constants/rate.constants';

const rates: Module<RatesStateInterface, any> = {
    namespaced: true,
    state: RATES_EMPTY_STATE,
    getters: {
        [RatesMethods.getters.GET_RANGE_RATES](state): RangeRate[] {
            return state.rangeRates;
        },
        [RatesMethods.getters.GET_PROMO_RATES](state): Rate[] {
            return state.promoRates;
        },
        [RatesMethods.getters.GET_RATE_TYPES](state): RateType[] {
            return state.rateTypes;
        },
        [RatesMethods.getters.GET_MIN_AMMOUNT_IN_REMITTANCE](state): {value:number,currency:string} {
            return state.minAmmountInRemittance;
        },
        [RatesMethods.getters.GET_FULL_RATES](state): FullRate {
            return state.fullRates;
        },
        [RatesMethods.getters.GET_USER_RATES](state): {type: string, rates: any} {
            return state.userRates;
        },
        [RatesMethods.getters.GET_USER_LOCAL_RATES](state): {type: string, rates: any} {
            return state.userLocalRates;
        },
        [RatesMethods.getters.GET_USD_TO_ALL_CURRENCIES](state): {date: string, base: string, rates: Object} {
            return state.USDConvertions
        },
        [RatesMethods.getters.GET_RATES_TAKEN_ADVANTAGE_OF](state): {rate_name: string, total: number}[] {
            return state.ratesTakenAdvantageOf;
        },
    },
    mutations: {
        [RatesMethods.mutations.SET_RANGE_RATES](state, rangeRates: RangeRate[]): void {
            state.rangeRates = rangeRates;
        },
        [RatesMethods.mutations.SET_PROMO_RATES](state, promoRates: Rate[]): void {
            state.promoRates = promoRates;
        },
        [RatesMethods.mutations.SET_RATE_TYPES](state, rateTypes: RateType[]): void {
            state.rateTypes = rateTypes;
        },
        [RatesMethods.mutations.SET_MIN_AMMOUNT_IN_REMITTANCE](state, minAmmountInRemittance: {value:number,currency:string}): void {
            state.minAmmountInRemittance = minAmmountInRemittance;
        },
        [RatesMethods.mutations.SET_FULL_RATES](state, data: FullRate): void {
            state.fullRates = data;
        },
        [RatesMethods.mutations.SET_USER_RATES](state, data:{type: string, rates: any,firstCall: boolean}): void {
            state.userRates = data;
        },
        [RatesMethods.mutations.SET_USER_LOCAL_RATES](state, data:{type: string, rates: any,firstCall: boolean}): void {
            state.userLocalRates = data;
        },
        [RatesMethods.mutations.SET_USD_TO_ALL_CURRENCIES](state, data: {date: string, base: string, rates: Object}): void {
            state.USDConvertions = data;
        },
        [RatesMethods.mutations.SET_RATES_TAKEN_ADVANTAGE_OF](state, data:{rate_name: string, total: number}[]): void {
            state.ratesTakenAdvantageOf = data;
        },
        [RatesMethods.mutations.RESET_RATES_DATA](state): void {
            state.rateTypes = [],
            state.rangeRates = [],
            state.promoRates = [],
            state.minAmmountInRemittance = {value:0,currency:''},
            state.userRates = {type: null,rates: null,firstCall: null},
            state.userLocalRates = { type: null, rates: null, firstCall: null},
            state.fullRates = {manualRates:[],improvedRate:null,expectedImprovedRate: null,regularRate: null,userRates: {promoRates: [],manualRates: [],individualRate: null},vipAcum: 0,localAmountLimit: 0},
            state.USDConvertions = { date: null, base: null, rates: {}},
            state.ratesTakenAdvantageOf = []
        },
    },
    actions: {  
        async [RatesMethods.actions.FETCH_RANGE_RATES]({ commit }): Promise<boolean> {
            try {
               let rangeRate : RangeRate[] =  await ratesHttpRepository.fetchRangeRates();
            //    let minAmmountInRemittance : RangeRate = rangeRate.find(el=> {
            //             if (el.id_country === data.idCountry && el.iso_cod === data.currencyIsoCode)
            //                 return el
            //         }
            //     ) 
               commit(RatesMethods.mutations.SET_RANGE_RATES,rangeRate)
             //  commit(RatesMethods.mutations.SET_MIN_AMMOUNT_IN_REMITTANCE,{value:minAmmountInRemittance.min_amount,currency: minAmmountInRemittance.iso_cod})
               return true
            } catch (e) {
                return false
            }
        },
        async [RatesMethods.actions.FETCH_RATE_TYPES]({ commit }): Promise<boolean> {
            try {
                let rateTypes : RateType[] =  await ratesHttpRepository.fetchRateTypes();
               commit(RatesMethods.mutations.SET_RATE_TYPES,rateTypes)
               return true
            } catch (e) {
                return false
            }
        },
        async [RatesMethods.actions.FETCH_PROMO_RATES]({ commit },data: any): Promise<boolean> {
            try {
               let params = getParams(data)
               let promoRates : Rate[] =  await ratesHttpRepository.fetchPromoRates(params);
               if (promoRates === null) promoRates = []
               commit(RatesMethods.mutations.SET_PROMO_RATES,promoRates)
               return true
            } catch (e) {
                return false
            }
        },
        async [RatesMethods.actions.FETCH_FULL_RATES]({ commit },data:any): Promise<boolean | FullRate> {
            try {
               let params = getParams(data);
               let rates : FullRate = await ratesHttpRepository.fetchAllRates(params,data.email_user);
               //@ts-ignore 
                if (rates.userRates){
                    if (rates.userRates.promoRates === null) rates.userRates.promoRates = []
                    if (rates.userRates.manualRates === null) rates.userRates.manualRates = []
                }
                else rates.userRates = null;
                rates.expectedImprovedRate = rates.manualRates.find(el=> el.rate_type_name === MANUAL_RATES.VIPF)
                if (data.do_not_save && data.do_not_save === true) return rates
                else 
                    commit(RatesMethods.mutations.SET_FULL_RATES,rates)
               return true;
            } catch (e) {
                return false;
            }
        },
        async [RatesMethods.actions.SAVE_USER_RATES]({ commit },data:{type: string, rates: any, firstCall: boolean}): Promise<boolean> {
            try {
               commit(RatesMethods.mutations.SET_USER_RATES,data)
               return true;
            } catch (e) {
                return false;
            }
        },
        [RatesMethods.actions.SAVE_MIN_AMMOUNT_IN_REMITTANCE]({ commit },data:{value:number,currency:string}): void {
            try {
               commit(RatesMethods.mutations.SET_MIN_AMMOUNT_IN_REMITTANCE,data)
            } catch (e) {
            }
        },
        async [RatesMethods.actions.FETCH_USER_RATES]({ commit },data: any): Promise<boolean> {
            try {
               let params = getParams(data)
               let userRates : any =  await ratesHttpRepository.fetchUserRates(params,data.email_user);
               if (userRates === null) userRates.rates = []
               userRates.firstCall = true;
               if (userRates.type){
                    if (data.to_local_country){
                        commit(RatesMethods.mutations.SET_USER_LOCAL_RATES,userRates)
                    }
                    else 
                        commit(RatesMethods.mutations.SET_USER_RATES,userRates)
                    return true;
                }
                return false
            } catch (e) {
                return false
            }
        },
        async [RatesMethods.actions.FETCH_RATES_TAKEN_ADVANTAGE_OF]({ commit },email_user: string): Promise<boolean> {
            try {
               let rates : {rate_name: string, total: number}[] =  await ratesHttpRepository.fetchRatesTakenAdventageOf(email_user)
                if (rates === null) rates = []
                commit(RatesMethods.mutations.SET_RATES_TAKEN_ADVANTAGE_OF,rates)
                return true
            } catch (e) {
                return false
            }
        },
    },
};

export default rates;
