import { Module } from 'vuex';
import WholeSalePartnersMethods from '@/store/wholesalePartners/methods/wholesalePartners.methods';
import { WHOLESALEPARTNER_EMPTY_STATE } from './wholesalePartners.state';
import { WholeSalePartnerStateInterface } from './interfaces/wholesalePartners.interface';
import { PageRequest } from '@/modules/wholesalePartners/interfaces/pageRequest.interface';
import {wholeSalePartnerHttpRepository} from '@/modules/wholesalePartners/repositories/wholesalePartner.httpRepository'
import { WholeSalePartnerUser } from '@/modules/wholesalePartners/interfaces/client.interface';
import { WholeSalePartnerUserRemittance } from '@/modules/wholesalePartners/interfaces/clientRemittance.interface';
import { WholesalePartnerReport } from '@/modules/wholesalePartners/interfaces/reports.interface';
import { Rate } from '@/modules/rates/interfaces/rate.interface';
const wholesalePartners: Module<WholeSalePartnerStateInterface, any> = {
    namespaced: true,
    state: WHOLESALEPARTNER_EMPTY_STATE,
    getters: {
        [WholeSalePartnersMethods.getters.GET_CLIENTS](state): WholeSalePartnerUser[]{
            return state.clients
        },
        [WholeSalePartnersMethods.getters.GET_CLIENTS_REMITTANCES](state): WholeSalePartnerUserRemittance[]{
            return state.clientsRemittances
        },
        [WholeSalePartnersMethods.getters.GET_REPORTS](state): WholesalePartnerReport{
            return state.reports
        },
        [WholeSalePartnersMethods.getters.GET_WHOLESALEPARTNER_RATES](state): Rate[] {
            return state.rates;
        }
    },
    mutations: {
        [WholeSalePartnersMethods.mutations.SET_CLIENTS](state, clients : WholeSalePartnerUser[]): void {
            state.clients = clients;
        },
        [WholeSalePartnersMethods.mutations.SET_CLIENTS_REMITTANCES](state, remittances : WholeSalePartnerUserRemittance[]): void {
            state.clientsRemittances = remittances;
        },
        [WholeSalePartnersMethods.mutations.SET_REPORTS](state, reports : WholesalePartnerReport): void {
            state.reports = reports;
        },
        [WholeSalePartnersMethods.mutations.SET_WHOLESALEPARTNER_RATES](state, rates: Rate[]): void {
            state.rates = rates;
        },
        [WholeSalePartnersMethods.mutations.RESET_WHOLESALEPARTNERS_DATA](state): void {
            state.clients = [],
            state.clientsRemittances = [],
            state.reports = null,
            state.rates = []
        }
    },
    actions: {
        async [WholeSalePartnersMethods.actions.SEND_WHOLESALEPARTNER_PAGE_INGO]({ commit },request: {body:PageRequest,email_user: string}): Promise<{error: boolean,message?: string}> {
            try {
                let bodyFormData = new FormData();
                bodyFormData.append('name', request.body.name);
                bodyFormData.append('slug', request.body.slug);
                bodyFormData.append('percent_profit', request.body.percent_profit.toString());
                bodyFormData.append('theme', request.body.theme);
                if (request.body.logo && request.body.logo.name){
                    let docFile = new File([request.body.logo], 'wp.png',{type: request.body.logo.type});
                    bodyFormData.append('logo', docFile);
                }
                let response = await wholeSalePartnerHttpRepository.sendWholeSalePartnerPageIngo(bodyFormData,request.email_user)
                if (response.message === 'Wholesale partner information successfuly inserted.') 
                    return {error:false};
                return {error:true,message:'Ha ocurrido un error creando su Página Web'};
            } catch (e) {
                if (e.response && e.response.data){
                    if (e.reponse.data.error === "Wholesale partner info already exists.")
                        return {error: true, message: "Usted ya ha realizado la solicitud"}
                    if (e.reponse.data.error === "Invalid wholesale partner.")
                        return {error: true, message: "Error creando la página. Asociado mayorista inválido"}
                    else return {error:true,message:'Ha ocurrido un error creando su Página Web'};
                }
                return {error:true,message:'Ha ocurrido un error creando su Página Web'};
            }
        },
        async [WholeSalePartnersMethods.actions.FETCH_CLIENTS]({ commit },slug: string): Promise<boolean> {
            try {
                let response: WholeSalePartnerUser[] = await wholeSalePartnerHttpRepository.getWholeSalePartnerClients(slug)
                commit(WholeSalePartnersMethods.mutations.SET_CLIENTS,response)
                return true
            } catch (e) {
               return false
            }
        },
        async [WholeSalePartnersMethods.actions.FETCH_CLIENTS_REMITTANCES]({ commit },slug: string): Promise<boolean> {
            try {
                let response = await wholeSalePartnerHttpRepository.getWholeSalePartnerClientsRemittances(slug)
                commit(WholeSalePartnersMethods.mutations.SET_CLIENTS_REMITTANCES,response)
                return true
            } catch (e) {
               return false
            }
        },
        async [WholeSalePartnersMethods.actions.UPDATE_PERCENT_PROFIT]({ commit },data:{slug: string,percentProfit: number}): Promise<boolean> {
            try {
                let response = await wholeSalePartnerHttpRepository.updatePercentProfit(data.slug,{percentProfit:data.percentProfit})
                if (response.message === "Percent profit succesfully updated.") return true
                return false
            } catch (e) {
               return false
            }
        },
        async [WholeSalePartnersMethods.actions.FETCH_REPORTS]({ commit },slug: string): Promise<boolean> {
            try {
                let response : WholesalePartnerReport = await wholeSalePartnerHttpRepository.getWholeSalePartnerReports(slug)
                commit(WholeSalePartnersMethods.mutations.SET_REPORTS,response)
                return true
            } catch (e) {
               return false
            }
        },
        async [WholeSalePartnersMethods.actions.FETCH_WHOLESALEPARTNER_RATES]({ commit },slug: string): Promise<boolean> {
            try {
                let response : Rate[] = await wholeSalePartnerHttpRepository.getWholeSalePartnerRates(slug)
                commit(WholeSalePartnersMethods.mutations.SET_WHOLESALEPARTNER_RATES,response)
                return true;
            } catch (e) {
                return false;
            }
        },
    }
};

export default wholesalePartners;