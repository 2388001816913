import { AxiosRepository } from '@/http/axios.repository';
import { Balance } from '../interfaces/balance.interface';

const BALANCE_RESOURCE = 'balances';
export class BalanceHttpRepository extends AxiosRepository {
    
async getUserBalance(email_user: string): Promise<{balances: Balance[]}> { 
        return super.get(email_user,BALANCE_RESOURCE);
    }
}

export const balanceHttpRepository = new BalanceHttpRepository();