import { Module } from 'vuex';
import PayMethodMethods from '@/store/payMethods/methods/payMethods.methods';
import { PAY_METHODS_EMPTY_STATE } from './payMethods.state';
import { PayMethodsStateInterface } from './interfaces/payMethods.interface';
import { payMethodHttpRepository } from '@/modules/payMethods/repositories/payMethods.repository';
import { PayMethod } from '@/modules/payMethods/interfaces/payMethods.interface';
import { DepositMethod } from '@/modules/payMethods/interfaces/depositMethods.interface';
import { getParams } from '@/utils/global-functions';

const payMethods: Module<PayMethodsStateInterface, any> = {
    namespaced: true,
    state: PAY_METHODS_EMPTY_STATE,
    getters: {
        [PayMethodMethods.getters.GET_DEPOSIT_METHODS_BY_BANK](state): DepositMethod[] {
            return state.depositMethodsByBank.depositMethods;
        },
    },
    mutations: {
        [PayMethodMethods.mutations.SET_DEPOSIT_METHODS_BY_BANK](state, depositMethodsByBank: {bankId: number, depositMethods: DepositMethod[]}): void {
            state.depositMethodsByBank = depositMethodsByBank
        },
        [PayMethodMethods.mutations.RESET_PAY_METHODS_DATA](state): void {
            state.depositMethodsByBank = {
                bankId: null,
                depositMethods: []
            }
        },  
    },
    actions: {
        async [PayMethodMethods.actions.FETCH_PAY_METHODS_BY_COUNTRY]({ commit },data: any): Promise<PayMethod[] | undefined> {
            try {
                let params = '?'+ getParams(data)
                let response : PayMethod[] = await payMethodHttpRepository.getPayMethodsByCountryId(params)
                if (response.length > 0){
                    return response;
                }
                return undefined;
            } catch (e) {
                return undefined;
            }
        },
        async [PayMethodMethods.actions.FETCH_PAY_METHOD_BY_ID]({ commit },payMethodId: number): Promise<PayMethod | undefined> {
            try {
                let response : PayMethod = await payMethodHttpRepository.getPayMethodById(payMethodId)
                if (response.idPayMethod){
                    return response;
                }
                return undefined;
            } catch (e) {
                return undefined;
            }
        },
        async [PayMethodMethods.actions.FETCH_DEPOSIT_METHODS_BY_BANK]({ commit },bankId: number): Promise<boolean> {
            try {
                let response : DepositMethod[] = await payMethodHttpRepository.getDepositMethodByBankId(bankId)
                commit(PayMethodMethods.mutations.SET_DEPOSIT_METHODS_BY_BANK,{
                    bankId: bankId,
                    depositMethods: response
                })
                return true;
            } catch (e) {
                return false;
            }
        },
    }
};

export default payMethods;