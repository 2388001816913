import { AxiosRepository } from '@/http/axios.repository';
import { RangeRate } from '../interfaces/rangeRate.interface';
import { FullRate, Rate } from '../interfaces/rate.interface';
import { RateType } from '../interfaces/rateType.interface';


const RATES_RESOURCE = 'rates';
const RANGES_RESOURCE = '/rangeRates';
const TYPES_RESOURCE = '/rateTypes'
const USER_RESOURCE = '/userRates';
const FULL_RATES = '/fullRates';
const PROMO_RATES = '/promo';

export class RatesRepository extends AxiosRepository {
    
    async fetchRangeRates(): Promise<RangeRate[]>{
        return super.getAll(RATES_RESOURCE+RANGES_RESOURCE)
    }

    async fetchRateTypes(): Promise<RateType[]>{
        return super.getAll(RATES_RESOURCE+TYPES_RESOURCE)
    }

    async fetchUserRates(params: string,email_user: string): Promise<any>{
        return super.getAll(RATES_RESOURCE+USER_RESOURCE+`/${email_user}`+'?'+params)
    }

    async fetchAllRates(params: string,email_user: string): Promise<FullRate>{
        return super.getAll(RATES_RESOURCE+FULL_RATES+`/${email_user}`+'?'+params)
    }

    async fetchPromoRates(params: string): Promise<Rate[]>{
        return super.getAll(RATES_RESOURCE+PROMO_RATES+'?'+params)
    }

    async fetchRatesTakenAdventageOf(email_user: string): Promise<{rate_name: string, total:number}[]>{
        return super.getAll(`reports/users/${email_user}/${RATES_RESOURCE}`)
    }
}

export const ratesHttpRepository = new RatesRepository();