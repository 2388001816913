export default {
    mutations: {
        SET_MESSAGES: 'SET_MESSAGES',
        SET_LOGIN_MESSAGES: 'SET_LOGIN_MESSAGES',
        RESET_CHAT_DATA: 'RESET_CHAT_DATA',
    },
    getters: {
        GET_MESSAGES: 'GET_MESSAGES',
        GET_LOGIN_MESSAGES: 'GET_LOGIN_MESSAGES',
    },
    actions: {
        SEND_MESSAGE: 'SEND_MESSAGE',
        FETCH_MESSAGES: 'FETCH_MESSAGES',
        SET_LOGIN_MESSAGES: 'SET_LOGIN_MESSAGES',
        SET_MESSAGES: 'SET_MESSAGES'
    },
};