import { Module } from 'vuex';
import CountryTypes from '@/store/countries/methods/countries.methods';
import { COUNTRIES_EMPTY_STATE } from './countries.state';
import { Country,CountryCurrency,NationalityCountry } from '@/modules/countries/interfaces/countries.interface';
import { CountriesStateInterface } from './interfaces/countries.interface';
import { countriesHttpRepository } from '@/modules/countries/repositories/countries.repository';
import store from "@/store"
import { State } from '@/modules/countries/interfaces/states.interface';
import { statesHttpRepository } from '@/modules/countries/repositories/states.repository';

const countries: Module<CountriesStateInterface, any> = {
    namespaced: true,
    state: COUNTRIES_EMPTY_STATE,
    getters: {
        [CountryTypes.getters.GET_ALL_RESIDENCE_COUNTRIES](state): Country[] {
            return state.residenceCountries;
        },
        [CountryTypes.getters.GET_DESTINY_COUNTRIES](state): Country[] {
            return state.destinyCountries;
        },
        [CountryTypes.getters.GET_ALL_NATIONALITY_COUNTRIES](state): NationalityCountry[] {
            return state.nationalityCountries;
        },
        [CountryTypes.getters.GET_ALL_COUNTRIES](state): NationalityCountry[] {
            return state.allCountries;
        },
        [CountryTypes.getters.GET_COUNTRY_STATES](state): State[] {
            return state.countryStates;
        },
        [CountryTypes.getters.GET_ORIGIN_COUNTRY_CURRENCY](state):  CountryCurrency[] {
            return state.originCountriesCurrencies;
        },
        [CountryTypes.getters.GET_DESTINY_COUNTRY_CURRENCY](state):  CountryCurrency[] {
            return state.destinyCountriesCurrencies;
        },
        [CountryTypes.getters.GET_USER_ORIGIN_REMITTANCES_COUNTRY_CURRENCY](state):  CountryCurrency[] {
            return state.userOriginRemittanceCountriesCurrencies;
        },
        [CountryTypes.getters.GET_GLOBAL_DESTINY_COUNTRY_CURRENCY](state):  CountryCurrency {
            return state.currentDestinyCountryCurrency;
        },
        [CountryTypes.getters.GET_GLOBAL_ORIGIN_COUNTRY_CURRENCY](state):  CountryCurrency {
            return state.currentOriginCountryCurrency;
        },
        [CountryTypes.getters.GET_FREQUENTS_DESTINATIONS](state):  Country[] {
            return state.frequentDestinations;
        }
    },
    mutations: {
        [CountryTypes.mutations.SET_RESIDENCE_COUNTRIES](state, countries: Country[]): void {
            state.residenceCountries = countries;
        },
        [CountryTypes.mutations.SET_DESTINY_COUNTRIES](state, countries: Country[]): void {
            state.destinyCountries = countries;
        },
        [CountryTypes.mutations.SET_NATIONALITY_COUNTRIES](state, countries: NationalityCountry[]): void {
            state.nationalityCountries = countries;
        },
        [CountryTypes.mutations.SET_ALL_COUNTRIES](state, countries: NationalityCountry[]): void {
            state.allCountries = countries;
        },
        [CountryTypes.mutations.SET_COUNTRY_STATES](state, states: State[]): void {
            state.countryStates = states;
        },
        [CountryTypes.mutations.SET_ORIGIN_COUNTRY_CURRENCY](state, countriesCurrencies: CountryCurrency[]): void {
            state.originCountriesCurrencies = countriesCurrencies;
        },
        [CountryTypes.mutations.SET_DESTINY_COUNTRY_CURRENCY](state, countriesCurrencies: CountryCurrency[]): void {
            state.destinyCountriesCurrencies = countriesCurrencies;
        },
        [CountryTypes.mutations.SET_USER_ORIGIN_REMITTANCES_COUNTRY_CURRENCY](state, userOriginRemittanceCountriesCurrencies: CountryCurrency[]): void {
            state.userOriginRemittanceCountriesCurrencies = userOriginRemittanceCountriesCurrencies;
        },
        [CountryTypes.mutations.SET_GLOBAL_DESTINY_COUNTRY_CURRENCY](state, countryCurrency: CountryCurrency): void {
            state.currentDestinyCountryCurrency = countryCurrency;
        },
        [CountryTypes.mutations.SET_GLOBAL_ORIGIN_COUNTRY_CURRENCY](state, countryCurrency: CountryCurrency): void {
            state.currentOriginCountryCurrency = countryCurrency;
        },
        [CountryTypes.mutations.SET_FREQUENTS_DESTINATIONS](state, frequentDestinations: Country[]): void {
            state.frequentDestinations = frequentDestinations;
        },
        [CountryTypes.mutations.RESET_COUNTRIES_DATA](state): void {
            state.residenceCountries = [],
            state.nationalityCountries = [],
            state.allCountries = [],
            state.destinyCountries = [],
            state.countryStates = [],
            state.originCountriesCurrencies = [],
            state.destinyCountriesCurrencies = [],
            state.userOriginRemittanceCountriesCurrencies = [],
            state.currentDestinyCountryCurrency = {iso_cod: '',id_country: null,id_currency: null,name_country: '',country_iso_code: '',viewing_name: ''},
            state.currentOriginCountryCurrency = {iso_cod: '',id_country: null,id_currency: null,name_country: '',country_iso_code: '',viewing_name: ''},
            state.frequentDestinations = []
        }
    },
    actions: {
        async [CountryTypes.actions.FETCH_ALL_RESIDENCE_COUNTRIES]({ commit }): Promise<boolean> {
            try {
                const countries: Country[] = await countriesHttpRepository.getAllResidenceCountries();
                commit(CountryTypes.mutations.SET_RESIDENCE_COUNTRIES, countries);
                return true;
            } catch (e) {
                return false;
            }
        },
        async [CountryTypes.actions.SAVE_GLOBAL_DESTINY_COUNTRY_CURRENCY]({ commit },countryCurrency: CountryCurrency): Promise<boolean> {
            try {
                commit(CountryTypes.mutations.SET_GLOBAL_DESTINY_COUNTRY_CURRENCY, countryCurrency);
                return true;
            } catch (e) {
                return false;
            }
        },
        async [CountryTypes.actions.SAVE_GLOBAL_ORIGIN_COUNTRY_CURRENCY]({ commit },countryCurrency: CountryCurrency): Promise<boolean> {
            try {
                commit(CountryTypes.mutations.SET_GLOBAL_ORIGIN_COUNTRY_CURRENCY, countryCurrency);
                return true;
            } catch (e) {
                return false;
            }
        },
        async [CountryTypes.actions.FETCH_DESTINY_COUNTRIES]({ commit }): Promise<boolean> {
            try {
                const countries: Array<{idCountry: string,name: string,code:string}> = await countriesHttpRepository.getAllDestinyCountries()
                const newCountries: Country[] = countries.map(function(el) {
                    return { id_country : el.idCountry, country_iso_code : el.code, name_country: el.name }
                 })
                 if (newCountries.length){
                    commit(CountryTypes.mutations.SET_DESTINY_COUNTRIES, newCountries);
                    return true;
                }else return false;
            } catch (e) {
                return false;
            }
        },
        async [CountryTypes.actions.FETCH_ALL_NATIONALITY_COUNTRIES]({ commit }): Promise<boolean> {
            try {
                const countries: NationalityCountry[] = await countriesHttpRepository.getAllNationalityCountries();
                commit(CountryTypes.mutations.SET_NATIONALITY_COUNTRIES, countries);
                return true;
            } catch (e) {
                return false;
            }
        },
        async [CountryTypes.actions.FETCH_ALL_COUNTRIES]({ commit }): Promise<boolean> {
            try {
                const countries: NationalityCountry[] = await countriesHttpRepository.getAllCountries();
                commit(CountryTypes.mutations.SET_ALL_COUNTRIES, countries);
                return true;
            } catch (e) {
                return false;
            }
        },
        async [CountryTypes.actions.FETCH_COUNTRY_STATES_BY_RESID_COUNTRY]({ commit },countryId: number): Promise<boolean> {
            try {
                const states: State[] = await statesHttpRepository.getStatesByCountry(countryId)
                commit(CountryTypes.mutations.SET_COUNTRY_STATES, states);
                return true;
            } catch (e) {
                return false;
            }
        },
        async [CountryTypes.actions.FETCH_COUNTRY_ID_BY_NAME]({ commit },countryName: string): Promise<any[]> {
            try {
                const country: any = await countriesHttpRepository.getCountryIdByName(countryName)
                return country;
            } catch (e) {
                return [];
            }
        },
        async [CountryTypes.actions.GET_ISO_CODE]({ commit },countryId: number): Promise<string | boolean> {
            try {
                const isoCode = await countriesHttpRepository.getCountryCode(countryId)
                if (isoCode.country_iso_code) return isoCode.country_iso_code.toLowerCase();
                return false
            } catch (e) {
                return false;
            }
        },
        async [CountryTypes.actions.POLITICALLY_EXPOSE_QUESTION]({commit}, countryId: number): Promise<boolean>{
            try {
                const polExp = await countriesHttpRepository.isPoliticallyExpose(countryId)
                if (polExp.is_pol_exp === true) return true
                return false
            } catch (e) {
                return false;
            }
        },
        async [CountryTypes.actions.FETCH_ORIGN_DEST_COUNTRY_CURRENCY]({commit},email_user?: string): Promise<boolean>{
            try {
                let params='';
                if (email_user){
                    let encodeEmailUser = encodeURIComponent(email_user)
                    params = '?email_user='+encodeEmailUser
                }
                let countriesCurrencies: {origin: CountryCurrency[],destiny: CountryCurrency[],countriesUserHasSent?: CountryCurrency[]} = await countriesHttpRepository.getCountriesCurrencies(params)
                commit(CountryTypes.mutations.SET_DESTINY_COUNTRY_CURRENCY,countriesCurrencies.destiny)
                commit(CountryTypes.mutations.SET_ORIGIN_COUNTRY_CURRENCY,countriesCurrencies.origin)
                if (countriesCurrencies.countriesUserHasSent === null) countriesCurrencies.countriesUserHasSent = []
                commit(CountryTypes.mutations.SET_USER_ORIGIN_REMITTANCES_COUNTRY_CURRENCY,countriesCurrencies.countriesUserHasSent)
                return true;
            } catch (e) {
                return false;
            }
        },
        async [CountryTypes.actions.FETCH_FREQUENTS_DESTINATIONS]({commit},email_user?: string): Promise<boolean>{
            try {
                let countries: Country[] = await countriesHttpRepository.getFrequentDestinations(email_user)
                if (countries === null) countries = []
                commit(CountryTypes.mutations.SET_FREQUENTS_DESTINATIONS,countries)
                return true;
            } catch (e) {
                return false;
            }
        },
    },
};

export default countries;