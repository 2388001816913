import { AxiosRepository } from '@/http/axios.repository';
// import { Route } from '@/modules/routes/interfaces/routes.interface';
import { User } from '@/modules/auth/interfaces/user.interface';
import { Limitation, LimitationType } from '@/modules/verificationLevels/interfaces/limitations.interface';
import { Limit } from '../interfaces/limits.interface';
import { MinAmount } from '../interfaces/minAmount.interface';
import { NewRemittance } from '../interfaces/newRemittance.interface';
import { NotificationType } from '../interfaces/notificationTypes.interface.interface';
import { PreRemittance } from '../interfaces/preRemittance.interface';
import { Remittance } from '../interfaces/remittance.interface';
import { RemittanceAcum } from '../interfaces/reports.interface';
// import { Test } from '@/modules/test/interfaces/test.interface';

const REMITTANCE_RESOURCE = 'remittances';
const WEBPAY_REMITTANCE_RESOURCE = 'remittances/webpay';
const PRE_REMITTANCE_RESOURCE = '/preRemittance';
const NOTIFICATION_TYPES_RESOURCE = '/notificationTypes';
const LIMITATION_RESOURCE = '/limitationsByCodPub';

export class RemittanceRepository extends AxiosRepository {
    
    async sendRemittance(body: any): Promise<any> { 
        //data: NewRemittance
        return super.postData(body,REMITTANCE_RESOURCE)
       // return super.post(data,REMITTANCE_RESOURCE);
    }
    async sendWebpayRemittance(body: any): Promise<any> { 
        //data: NewRemittance
        return super.post(body,WEBPAY_REMITTANCE_RESOURCE)
       // return super.post(data,REMITTANCE_RESOURCE);
    }
    async savePreRemittance(data: NewRemittance): Promise<{message: string,id_pre_remittance: number}> { 
        return super.post(data,REMITTANCE_RESOURCE+PRE_REMITTANCE_RESOURCE);
    }
    async cancelPreRemittance(preRemittanceId: number): Promise<{pre_remittance: PreRemittance}> { 
        return super.delete(preRemittanceId,REMITTANCE_RESOURCE+PRE_REMITTANCE_RESOURCE);
    }
    async getPreRemittance(email_user: string): Promise<PreRemittance> { 
        return super.get(email_user,REMITTANCE_RESOURCE+PRE_REMITTANCE_RESOURCE);
    }

    async fetchChatRemittances(email_user: string): Promise<Remittance[]>{
        return super.get(email_user,REMITTANCE_RESOURCE+'/chat')
    }

    async fetchRemittances(email_user: string,params): Promise<Remittance[]>{
        return super.getAll(REMITTANCE_RESOURCE+'/'+email_user+params)
    }

    async fetchNotificationTypes(): Promise<NotificationType[]>{
        return super.getAll(REMITTANCE_RESOURCE+NOTIFICATION_TYPES_RESOURCE)
    }
    
    async fetchLimitByUser(publicCode: string): Promise<{limits:Limit[],total_acum: number,weekly_acum: number,monthly_acum: number,currency: string}>{
        return super.get(publicCode,REMITTANCE_RESOURCE+LIMITATION_RESOURCE)
    }

    async fetchMinAmounts(): Promise<MinAmount[]>{
        return super.getAll(REMITTANCE_RESOURCE+'/countries/minAmounts')
    }

    async fetchWeeklyAndMonthlyAmounts(idCountry: number): Promise< {limitations: Limitation[], name_limitations: LimitationType[]}>{
        return super.get(idCountry,'veriflevels/getLimitationsByCountry')
    }

    async fetchRemittanceTotalSendedByMonth(email_user: string, params: string): Promise< RemittanceAcum[]>{
        return super.getAll('reports/users/'+email_user+'/remittances/totalAmount'+params)
    }

    async fetchRemittancesByStatus(email_user: string): Promise<{byStatus:{total: number, public_status: string}[],totalRemittances: number}>{
        return super.getAll(`reports/users/${email_user}/remittances`)
    }

    async fetchRemittancesQuantityByMonth(email_user: string,params: string): Promise<RemittanceAcum[]>{
        return super.getAll(`reports/users/${email_user}/remittances/month`+params)
    }
    
    async createWebpayTransaction(amount: number, params: string): Promise<{token: string, url: string}>{
        return super.getAll(`/transbank/webpayplus/create`+params)
    }
}

export const remittanceHttpRepository = new RemittanceRepository();