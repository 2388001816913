export default {
    mutations: {
        SET_MAIN_DOC_TYPES: 'SET_MAIN_DOC_TYPES',
        SET_SECONDARY_DOC_TYPES: 'SET_SECONDARY_DOC_TYPES',
        SET_DOC_TYPES: 'SET_DOC_TYPES',
        RESET_DOC_TYPES_DATA: 'RESET_DOC_TYPES_DATA',
    },
    getters: {
        GET_MAIN_DOC_TYPES: 'GET_MAIN_DOC_TYPES',
        GET_DOC_TYPES: 'GET_DOC_TYPES',
        GET_SECONDARY_DOC_TYPES: 'GET_SECONDARY_DOC_TYPES',
    },
    actions: {
        FETCH_DOC_TYPES: 'FETCH_DOC_TYPES',
        FETCH_DOC_TYPE_BY_ID: 'FETCH_DOC_TYPE_BY_ID',
    },
};