import { Module } from 'vuex';
import RoutesMethods from '@/store/operationRoutes/methods/operationRoutes.methods';
import { OPERATION_ROUTES_EMPTY_STATE } from './operationRoutes.state';
import { OperationRoutesStateInterface } from './interfaces/operationRoutes.interface';
import { routesHttpRepository } from '@/modules/operationRoutes/repositories/routes.httpRepository';
import { OperationRoute } from '@/modules/operationRoutes/interfaces/route.interface';

const operationRoutes: Module<OperationRoutesStateInterface, any> = {
    namespaced: true,
    state: OPERATION_ROUTES_EMPTY_STATE,
    getters: {
        [RoutesMethods.getters.GET_OPERATION_ROUTES](state): OperationRoute[] {
            return state.routes;
        },
    },
    mutations: {
        [RoutesMethods.mutations.SET_OPERATION_ROUTES](state, routes: OperationRoute[]): void {
            state.routes = routes
        },
        [RoutesMethods.mutations.RESET_OPERATION_ROUTES](state): void {
            state.routes = []
        },  
    },
    actions: {
        async [RoutesMethods.actions.FETCH_OPERATION_ROUTES]({ commit },bankId: number): Promise<boolean> {
            try {
                let response : OperationRoute[] = await routesHttpRepository.getOperationRoutes()
                commit(RoutesMethods.mutations.SET_OPERATION_ROUTES,response)
                return true;
            } catch (e) {
                return false;
            }
        },
    }
};

export default operationRoutes;