
import { Component, Vue,Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class AudioPlayer extends Vue {
    @Prop() id!: number;
    @Prop() src!: any;
    @Prop() iconColor!: string;

    mounted(){
      let playerButton = document.querySelector(`.player-button-${this.id}`);
      let audio = document.querySelector(`.audio-${this.id}`);
      let timeline = document.querySelector(`.timeline-${this.id}`);
      let soundButton = document.querySelector(`.sound-button-${this.id}`);
      let playIcon = `
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="${this.iconColor}">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
        </svg>
            `;
        let  pauseIcon = `
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="${this.iconColor}">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
        </svg>
            `;
        let    soundIcon = `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="${this.iconColor}">
        <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clip-rule="evenodd" />
        </svg>`;
        let    muteIcon = `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="${this.iconColor}">
        <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>`;

        function toggleAudio () {
            //@ts-ignore
            if (audio.paused) {
                //@ts-ignore
                audio.play();
                playerButton.innerHTML = pauseIcon;
            } else {
                //@ts-ignore
                audio.pause();
                //@ts-ignore
                // audio.stop();
                playerButton.innerHTML = playIcon;
            }
        }

        playerButton.addEventListener('click', toggleAudio);

        function changeTimelinePosition () {
            //@ts-ignore
            const percentagePosition = (100*audio.currentTime) / audio.duration;
            //@ts-ignore
            timeline.style.backgroundSize = `${percentagePosition}% 100%`;
            //@ts-ignore
            timeline.value = percentagePosition;
        }
        //@ts-ignore
        audio.ontimeupdate = changeTimelinePosition;

        function audioEnded () {
            playerButton.innerHTML = playIcon;
        }
        //@ts-ignore
        audio.onended = audioEnded;

        function changeSeek () {
            //@ts-ignore
            const time = (timeline.value * audio.duration) / 100;
            //@ts-ignore
            audio.currentTime = time;
        }

        timeline.addEventListener('change', changeSeek);

        function toggleSound () {
            //@ts-ignore
            audio.muted = !audio.muted;
            //@ts-ignore
            soundButton.innerHTML = audio.muted ? muteIcon : soundIcon;
        }

        soundButton.addEventListener('click', toggleSound);
    }
}
