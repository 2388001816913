import {levelNames} from '@/modules/verificationLevels/constants/verifLevels.constants'

export function verifLevelsNames(id: number | string){
   return levelNames[id]
}

export function getStatusName(verifLevelApb : boolean){
   if (verifLevelApb === false) return 'Rechazado'
   else if (verifLevelApb === true) return 'Verificado'
   else return 'En revisión'
}

export function getStatusFemaleName(verifLevelApb : boolean){
   if (verifLevelApb === false) return 'Rechazada'
   else if (verifLevelApb === true) return 'Verificada'
   else return 'En revisión'
}

export function getStatusColor(verifLevelApb : boolean){
   if (verifLevelApb === false) return 'badge-rejected'
   else if (verifLevelApb === true) return 'badge-sent'
   else return 'badge-inProgress'
}

export function getStatusIcon(verifLevelApb : boolean){
   if (verifLevelApb === false) return 'shield-slash-fill'
   else if (verifLevelApb === true ) return 'shield-fill-check'
   else return 'shield-fill-exclamation'
}