import { Module } from 'vuex';
import AuthMethods from '@/store/auth/methods/auth.methods';
import { authHttpRepository }from '@/modules/auth/repositories/auth.repository';
import { apifyHttpRepository }from '@/http/apiFy/apiFy.repository';
import { AUTH_EMPTY_STATE } from './auth.state';
import { AuthStateInterface } from './interfaces/auth.state.interface';
import { User, UserAuthResponse, UserLogin} from '@/modules/auth/interfaces/user.interface';
import store from '@/store';
import { USER_EMPTY_STATE } from '@/modules/auth/emptyStates/user.emptyState';
import {getCountry} from 'country-list-spanish';
import { finishSession } from '@/modules/auth/functions/functions';
import { spanishDateToEnglishDate } from '@/utils/date-functions';
import {EnvironmentConstants} from '@/constants/enviromentConstants'
import { ambassadorLinkAvailable } from '@/modules/ambassador/functions/ambassador.functions';
const auth: Module<AuthStateInterface, any> = {
    namespaced: true,
    state: AUTH_EMPTY_STATE,
    getters: {
        [AuthMethods.getters.GET_USER_DATA](state): User {
            return state.user;
        },
        [AuthMethods.getters.GET_AMBASSADOR_LINK](state): string {
            return state.ambassadorLink;
        },
        [AuthMethods.getters.GET_AUTHENTICATION](state): boolean {
            return state.isAuthenticated;
        },
        [AuthMethods.getters.GET_REGISTER_USER_DATA](state): User {
            return state.userSignUp;
        },
        [AuthMethods.getters.GET_COUNTRY_PHONE_CODE](state): string {
            return state.user.main_phone_code;
        },
        [AuthMethods.getters.GET_SELECTED_DOC_TYPE](state): number {
            return state.selectedTypeDocType;
        },
        [AuthMethods.getters.GET_SELECTED_COUNTRY_DOC](state): string {
            return state.selectedCountryIdDocType;
        },
        [AuthMethods.getters.GET_ATC_PHONE](state): string {
            return state.atcPhone;
        },
        [AuthMethods.getters.GET_LEVELS_QUESTIONS](state): Array<any>{
            return state.levelQuestions
        },
        [AuthMethods.getters.GET_REGISTER_PUBLIC_CODE](state): string{
            return state.registerPublicCode
        },
        [AuthMethods.getters.GET_NOT_AUTHENTICATED_UUID](state): string{
            return state.notAuthenticatedUUid
        },
        [AuthMethods.getters.GET_PUBLIC_IP](state): string{
            return state.publicIp
        },
        [AuthMethods.getters.HAS_MIGRATED_EXCEPTION](state): boolean{
            return state.user.limit_exception || state.user.phone_exception || state.user.country_exception || state.user.multi_currency_exception        }
    },
    mutations: {
        [AuthMethods.mutations.SET_USER_DATA](state, user: User): void {
            state.user = user;
        },
        [AuthMethods.mutations.SET_NOT_AUTHENTICATED_UUID](state, uuid: string): void {
            state.notAuthenticatedUUid = uuid;
        },
        [AuthMethods.mutations.SET_NEW_VERIF_LEVEL](state, data:{verif_level: number, verif_level_apb: boolean}): void {
            state.user.verif_level_apb = data.verif_level_apb;
            state.user.id_verif_level = data.verif_level;
        },
        [AuthMethods.mutations.SET_REGISTER_USER_DATA](state, user: User): void {
            state.userSignUp = user;
        },
        [AuthMethods.mutations.SET_COUNTRY_PHONE_CODE](state, code: string): void {
            state.userSignUp.main_phone_code = code;
        },
        [AuthMethods.mutations.SET_AUTH](state, isAuthenticated : boolean): void {
            state.isAuthenticated = isAuthenticated;
        },
        [AuthMethods.mutations.SET_AMBASSADOR_LINK](state, ambassadorLink : string): void {
            state.ambassadorLink = ambassadorLink;
        },
        [AuthMethods.mutations.SET_ATC_PHONE](state, atcPhone : string): void {
            state.atcPhone = atcPhone;
        },
        [AuthMethods.mutations.SET_LEVELS_QUESTIONS](state, questions : Array<any>): void {
            state.levelQuestions = questions;
        },
        [AuthMethods.mutations.SET_SELECTED_COUNTRY_DOC](state, countryDocId : string): void {
            state.selectedCountryIdDocType = countryDocId;
        },
        [AuthMethods.mutations.SET_SELECTED_DOC_TYPE](state, docTypeId : number): void {
            state.selectedTypeDocType = docTypeId;
        },
        [AuthMethods.mutations.SET_REGISTER_PUBLIC_CODE](state, publicCode : string): void {
            state.registerPublicCode = publicCode;
        },
        [AuthMethods.mutations.CLEAR_AUTH_STATE](state, cleanedState : AuthStateInterface): void {
            state = cleanedState;
        },
        [AuthMethods.mutations.SET_PUBLIC_IP](state, publicIp: string): void {
            state.publicIp = publicIp;
        },
        [AuthMethods.mutations.RESET_AUTH_DATA](state): void {
            state.user= USER_EMPTY_STATE,
            state.ambassadorLink= null,
            state.userSignUp= USER_EMPTY_STATE,
            state.isAuthenticated= false,
            state.selectedTypeDocType= 0,
            state.selectedCountryIdDocType= '',
            state.atcPhone= '',
            state.levelQuestions= [],
            state.registerPublicCode= '',
            state.publicIp='1.2.2.3'
        },
    },
    actions: {
        [AuthMethods.actions.SAVE_SELECTED_COUNTRY_ID_DOC]({ commit },countryDocId: string): void {
            try {
              commit(AuthMethods.mutations.SET_SELECTED_COUNTRY_DOC,countryDocId)
            } catch (e) {
            }
        },
        [AuthMethods.actions.SET_NOT_AUTHENTICATED_UUID]({ commit },uuid: string): void {
            try {
              commit(AuthMethods.mutations.SET_NOT_AUTHENTICATED_UUID,uuid)
            } catch (e) {
            }
        },
        [AuthMethods.actions.SAVE_USER_SIGNUP]({ commit },user: User): void {
            try {
              commit(AuthMethods.mutations.SET_REGISTER_USER_DATA,user)
            } catch (e) {
            }
        },
        [AuthMethods.actions.SET_NEW_VERIF_LEVEL]({ commit },data:{verif_level: number, verif_level_apb: boolean}): void {
            try {
              commit(AuthMethods.mutations.SET_NEW_VERIF_LEVEL,data)
            } catch (e) {
            }
        },
        [AuthMethods.actions.SAVE_SELECTED_DOC_QUESTION_NUMBER]({ commit },docTypeId: number): void {
            try {
              commit(AuthMethods.mutations.SET_SELECTED_DOC_TYPE,docTypeId)
            } catch (e) {
            }
        },
        [AuthMethods.actions.SAVE_AMBASSADOR_LINK]({ commit },user: User): void {
            try {
                commit(AuthMethods.mutations.SET_AMBASSADOR_LINK,EnvironmentConstants.DOMAIN+`/registro?cod_ref=${user.cust_cr_cod_pub}`)
            } catch (e) {
            }
        },
        async [AuthMethods.actions.LOGIN]({ commit }, user: UserLogin): Promise<{errorMessage?: string, successMessage?: string, redirect?: string,noResponse?: boolean}> {
            let errorMessage = 'Correo o clave inválida. Recuerde que con 3 intentos fallidos se bloqueará su cuenta'
            try {
                let userState: UserAuthResponse  = await authHttpRepository.login(user);
                if (userState.captchaSuccess === false)
                    return {errorMessage: userState.msg}
                else {
                    if (userState.userExists){
                        commit(AuthMethods.mutations.SET_ATC_PHONE, userState.atcPhone);
                        if (userState.loginAttempts >= 1 && userState.loginAttempts <=3) return {errorMessage: errorMessage}
                    }
                    if (userState.isAuthenticated){
                        if (userState.user.wholesale_partner_info){
                            let blob = new Blob([new Uint8Array(userState.user.wholesale_partner_info.logo.data)]);
                            userState.user.wholesale_partner_info.logoSrc= window.URL.createObjectURL(blob);
                        }
                        /* MIGRADOS */
                        // userState.user.id_migrated = 1;
                        // userState.user.completed_information_migrated = false;
                        console.log('userState',userState)
                        commit(AuthMethods.mutations.SET_USER_DATA, userState.user);
                        commit(AuthMethods.mutations.SET_AUTH, true);
                        if (ambassadorLinkAvailable(userState.user))
                            commit(AuthMethods.mutations.SET_AMBASSADOR_LINK,EnvironmentConstants.DOMAIN+`/registro?cod_ref=${userState.user.cust_cr_cod_pub}`)
                        return {successMessage: 'Inicio exitoso. Bienvenido a Bithonor'};
                    }else{
                        return {errorMessage: errorMessage};
                    }  
                }
            } catch (e) {
                if (e.response.data && e.response.data.message === "There is already an active session with this user. Try again in a few minutes.")
                    return {redirect: 'ActiveSession'}
                if (e.response.data && e.response.data.user_blocked === true) { 
                    commit(AuthMethods.mutations.SET_ATC_PHONE,e.response.data.atcPhone);
                    return {redirect: 'UserBlocked'}
                }
                if (e.response.data && e.response.data.id_verif_level === 0 && e.response.data.verif_level_apb === null){
                    return {redirect: 'LevelCeroVerification'}
                }
                return {errorMessage: errorMessage};
            }
        },
        async [AuthMethods.actions.LOGOUT]({ commit }, email_user: string): Promise<boolean> {
            try {
               let response : {message: string} = await authHttpRepository.logout(email_user);
               if (response.message === 'Logged out succesfully'){
                    finishSession()
                    return true
                }
               return false
            } catch (e) {
               return false
            }
        },
        async [AuthMethods.actions.VERIFY_IDENT_USER_EXISTENCE]({ commit },data:{email_user?:string,phone_number?:string}): Promise<{msg?: string, error: boolean}> {
            try {
               let response : {message: string} = await authHttpRepository.verifyIdentUserExistence(data)
               if (response.message === 'User does not exist'){
                    return {error: false}
                }
                else if (response.message === 'User exists') return {error: true, msg: `Su correo o número telefónico ya se encuentra registrado`}
            } catch (e) {
               return {error: true, msg: 'Ha ocurrido un error validando sus datos'}
            }
        },
        async [AuthMethods.actions.VERIFY_IDENT_USER_EXISTENCE_EXCEPT_THEMSELF]({ commit },data:{phone_number:string,except:string}): Promise<{msg?: string, error: boolean}> {
            try {
               let response : {message: string} = await authHttpRepository.verifyIdentUserExistenceExceptThemself(data)
               console.log('response',response);
                if (response.message === 'User does not exist')
                {
                    return {error: false}
                }
                else if (response.message === 'User exists') return {error: true, msg: `Su número telefónico ya se encuentra registrado`}
            } catch (e) {
               return {error: true, msg: 'Ha ocurrido un error validando sus datos'}
            }
        },
        async [AuthMethods.actions.DEACTIVATE_USER]({ commit },data:{email_user:string}): Promise<{msg: string, error: boolean}> {
            try {
               let response  = await authHttpRepository.deactivateUser(data);
               if (response.message === 'User does not exist'){
                    return {error: true, msg: 'El usuario no se encuentra registrado'}
                }
                else if (response.message === 'User succesfully deactivated') return {error: false, msg: 'Alguno de sus datos ya se encuentra registrado'}
            } catch (e) {
               return {error: true, msg: 'Ha ocurrido un error desactivando su cuenta'}
            }
        },
        async [AuthMethods.actions.CREATE_CLIENT]({ commit }, user: Partial<User>): Promise<{errorMessage: string} | boolean> {
            try {
                 let phone_code = user.main_phone_code
                 user.date_birth = spanishDateToEnglishDate(user.date_birth)
                 let register: UserAuthResponse = await authHttpRepository.createClient(user);
                 if (register.user && register.captchaSuccess){
                    commit(AuthMethods.mutations.SET_REGISTER_USER_DATA, USER_EMPTY_STATE);
                    commit(AuthMethods.mutations.SET_COUNTRY_PHONE_CODE,phone_code);
                    commit(AuthMethods.mutations.SET_REGISTER_PUBLIC_CODE,register.user.cust_cr_cod_pub);
                    return true
                 }
                 else return {errorMessage: 'Ha ocurrido un error al crear el usuario'}
            } catch (e) {
                // if (e.response.data.error || !e.response.captchaSuccess)
                //     return {errorMessage: e.response.data.msg}
                if (e.response && e.response.data.msg === "Email already exists")
                    return {errorMessage: 'Alguno de sus datos ya se encuentran registrados'}
                else return {errorMessage: 'Ha ocurrido un error al crear el usuario'};
            }
        },
        async [AuthMethods.actions.SEND_EMAIL]({ commit }, data: {email_user: string,id_resid_country?: number | string, type: string,first_name:string,last_name:string}): Promise<{error: boolean, msg: string}> {
            try {
                 let response: any = undefined;
                 if (data.type === 'recoverPassword') response = await authHttpRepository.sendRecoverPasswordEmail({email_user: data.email_user});
                 else if (data.type === 'register') response = await authHttpRepository.sendRegisterVerificationEmail({email_user: data.email_user,id_resid_country:data.id_resid_country,first_name:data.first_name,last_name:data.last_name})
                 if (response.msg === "Code generated" && response.mailResp === "Email succesfully sent") 
                    return {error: false, msg:"Correo enviado exitosamente"}
                else 
                    return {error: true,msg: "Ha ocurrido un error enviando el correo"};
            } catch (e) {
                if (e.response && e.response.data.msg === "Requests limit by ip has been reached") return {error: true,msg: "Ha excedido la cantidad de códigos de verificación enviados. Intente más tarde"};
                else if (e.response && e.response.data.msg  === "An error ocurred generating code.") return {error: true, msg: "El correo ingresado es inválido" }
                else return {error: true,msg: "Ha ocurrido un error enviando el correo"};
            }
        },
        async [AuthMethods.actions.SEND_SMS]({ commit }, data: {main_phone_full: string,first_name:string,last_name:string}): Promise<{error: boolean, msg: string}> {
            try {
                 let response: any = undefined;
                 response = await authHttpRepository.sendRegisterVerificationSms(data)
                if (response.msg === "Code generated" ) 
                    return {error: false, msg:"SMS enviado exitosamente"}
                else 
                    return {error: true,msg: "Ha ocurrido un error enviando el SMS"};
            } catch (e) {
                if (e.response && e.response.data.msg === "Requests limit by ip has been reached") return {error: true,msg: "Ha excedido la cantidad de códigos de verificación enviados. Intente más tarde"};
                return {error: true,msg: "Ha ocurrido un error enviando el SMS"};
            }
        },
        async [AuthMethods.actions.SEND_WHATSAPP]({ commit }, data: {main_phone_full: string,first_name:string,last_name:string}): Promise<{error: boolean, msg: string}> {
            try {
                 let response: any = undefined;
                 response = await authHttpRepository.sendRegisterVerificationWhatsapp(data)
                if (response.msg === "Code generated" ) 
                    return {error: false, msg:"Se ha enviado el mensaje exitosamente"}
                else 
                    return {error: true,msg: "Ha ocurrido un error enviando el mensaje"};
            } catch (e) {
                if (e.response && e.response.data.msg === "Requests limit by ip has been reached") return {error: true,msg: "Ha excedido la cantidad de Códigos de Verificación enviados. Intente más tarde"};
                return {error: true,msg: "Ha ocurrido un error enviando el mensaje"};
            }
        },
        async [AuthMethods.actions.CHANGE_PASSWORD]({ commit }, data: {email_user: string, new_password: string,last_password: string}): Promise<{error: boolean, msg: string}> {
            try {
                let response: any = await authHttpRepository.changePassword(data);
                if (response.msg === "Password succesfully changed") return {error: false, msg: 'Contraseña actualizada exitosamente'}
                return {error: true, msg: 'Ha ocurrido un error actualizando la contraseña'}
            } catch (e) {
                if (e.response && e.response.data.msg === "The password cannot be equal to last 3")
                    return {error: true, msg: "La nueva contraseña debe ser distinta a las últimas tres"}
                else if (e.response && e.response.data.msg === "Incorrect last password") return {error: true, msg: 'Contraseña incorrecta, intente de nuevo'}
                return  {error: true, msg: 'Ha ocurrido un error actualizando la contraseña'}
            }
        },
        async [AuthMethods.actions.VERIF_CLIENT]({ commit }, email: string): Promise<boolean> {
            try {
                 //user.id_nationality_country = 1;
                 let userLogged: any = await authHttpRepository.verifClient(email);
                 if (userLogged.message === "User level cero approved succesfully"){
                    return true
                 }
                 else return false
            } catch (e) {
                return false;
            }
        },
        async [AuthMethods.actions.CODE_REFERRER_EXISTS]({ commit },cust_cr_cod_pub: string): Promise<boolean> {
            try {
                let response = await authHttpRepository.isReferrer(cust_cr_cod_pub);
                return true
            } catch (e) {
                return false;
            }
        },
        async [AuthMethods.actions.PROTECTED_ROUTE]({ commit }): Promise<boolean> {
            try {
                await authHttpRepository.protectedRoute();
                return false
            } catch (e) {
                return false;
            }
        },
        async [AuthMethods.actions.FETCH_PUBLIC_IP]({ commit }): Promise<boolean> {
            try {
                let response : {ip: string} = await apifyHttpRepository.get();
                if (response.ip)
                    commit(AuthMethods.mutations.SET_PUBLIC_IP,response.ip)
                return true
            } catch (e) {
                return false;
            }
        },
    } 
};

export default auth;
