export default {
    mutations: {
        SET_REFERRALS: 'SET_REFERRALS',
        SET_REFERRALS_OPERATIONS: 'SET_REFERRALS_OPERATIONS',
        SET_REFERRALS_BY_COUNTRIES: 'SET_REFERRALS_BY_COUNTRIES',
        SET_REFERRALS_BY_STATUS: 'SET_REFERRALS_BY_STATUS',
        SET_CRYPTOMILES: 'SET_CRYPTOMILES',
        RESET_AMBASSADORS_DATA: 'RESET_AMBASSADORS_DATA'
    },
    getters: {
        GET_REFERRALS: 'GET_REFERRALS',
        GET_REFERRALS_OPERATIONS: 'GET_REFERRALS_OPERATIONS',
        GET_REFERRALS_BY_COUNTRIES: 'GET_REFERRALS_BY_COUNTRIES',
        GET_REFERRALS_BY_STATUS: 'GET_REFERRALS_BY_STATUS',
        GET_CRYPTOMILES: 'GET_CRYPTOMILES',
    },
    actions: {
        FETCH_REFERRALS: 'FETCH_REFERRALS',
        FETCH_REFERRALS_OPERATIONS: 'FETCH_REFERRALS_OPERATIONS',
        FETCH_REFERRALS_BY_COUNTRIES: 'FETCH_REFERRALS_BY_COUNTRIES',
        FETCH_REFERRALS_BY_STATUS: 'FETCH_REFERRALS_BY_STATUS',
        SEND_AMBASSADOR_REQUEST: 'SEND_AMBASSADOR_REQUEST',
        FETCH_CRYPTOMILES: 'FETCH_CRYPTOMILES',
    },
};