import { LEVEL_ONE_REQUEST_EMPTY_STATE, LEVEL_TWO_REQUEST_EMPTY_STATE } from '@/modules/verificationLevels/emptyStates/requests.emptyState';
import { RequestStatus } from '@/modules/verificationLevels/interfaces/requests.interface';
import store from "@/store";
import { VerifLevelsStateInterface } from './interfaces/verificationLevels.interface';

export const VERIF_LEVELS_EMPTY_STATE: VerifLevelsStateInterface = {
    levelQuestions: [],
    wholesaleResidCountries: [],
    migrationStatus : [],
    limitations: {
        limitations: [],
        name_limitations : []
    },
    verifLevelReq: {
        level_one: [],
        level_two:[],
        email_user: ''
    },
    wholeSalePartnerReq:{
        questions:[],
        email_user: ''
    },
    levelOneRequest: LEVEL_ONE_REQUEST_EMPTY_STATE,
    levelTwoRequest: LEVEL_TWO_REQUEST_EMPTY_STATE,
    levelOneRequestStatus: RequestStatus.BLANK,
    levelTwoRequestStatus: RequestStatus.BLANK,
    migratedData: null
};


export const getDefaultVerifLevelState = () => {
    store.state.verifLevels.levelQuestions= [];
    store.state.verifLevels.wholesaleResidCountries= [];
    store.state.verifLevels.migrationStatus = [];
    store.state.verifLevels.limitations= {
        limitations: [],
        name_limitations : []
    };
    store.state.verifLevels.verifLevelReq= {
        level_one: [],
        level_two:[],
        email_user: ''
    };
    store.state.verifLevels.wholeSalePartnerReq={
        questions:[],
        email_user: ''
    };
    store.state.verifLevels.levelOneRequest= LEVEL_ONE_REQUEST_EMPTY_STATE;
    store.state.verifLevels.levelTwoRequest= LEVEL_TWO_REQUEST_EMPTY_STATE;
    store.state.verifLevels.levelOneRequestStatus= RequestStatus.BLANK;
    store.state.verifLevels.levelTwoRequestStatus= RequestStatus.BLANK;
    store.state.verifLevels.migratedData= null
}