export const NOTIFICATION_TYPES_LABELS= {
    "Numero telefono" : {label:"Teléfono a Notificar",toFill: 'phoneNumber'},
    "Correo": {label:"Correo a Notificar",toFill: 'email'}
}

export const NOTIFICATION_VALUES = {
    PHONE_NUMBER: 'phoneNumber',
    EMAIL: 'email'
}

export const NOT_NOTIFY = 'No Notificar'

