export const REFERRAL_SRATUS={
    ACTIVE: {
        key: 'Active',
        name: 'Activo',
        description: 'Ha realizado operaciones recientemente'
    },
    INACTIVE: { 
        key: 'Inactive',
        name: 'Inactivo',
        description: 'No ha realizado operaciones'
    },
    ABSENT: {
        key: 'Absent',
        name: 'Ausente',
        description: '+ 90 días sin enviar'
    }
}

export const REFERRAL_SRATUS_ARRAY = Object.values(REFERRAL_SRATUS)