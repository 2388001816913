import { ExchangeStateInterface } from './interfaces/exchange.interface';

export const EXCHANGE_EMPTY_STATE: ExchangeStateInterface = {
    exchanges: [],
    rates: [],
    rangeRates: [],
    ratesUpdated: false,
    preExchange: {
        date: null,
        active: null,
        was_expired: null,
        pre_exchange: null,
        date_last_shown: null,
        id_pre_exchange: null
    },
    buyMaxAmount: null,
    buyMinAmount:null
};
