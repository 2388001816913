import { Module } from 'vuex';
import AmbassadorsMethods from '@/store/ambassadors/methods/ambassadors.methods';
import { AMBASSADORS_EMPTY_STATE } from './ambassadors.state';
import { AmbassadorsStateInterface } from './interfaces/ambassadors.interface';
import { ambassadorsHttpRepository } from '@/modules/ambassador/repositories/ambassadors.httpRepository';
import { getParams } from '@/utils/global-functions';
import { User } from '@/modules/auth/interfaces/user.interface';
import { ReferralOperation } from '@/modules/ambassador/interfaces/referralOperation.interface';
import { ReferralsByCountry, ReferralsByStatus } from '@/modules/ambassador/interfaces/reports.interfaces';
import { CryptoMile } from '@/modules/ambassador/interfaces/cryptomile.interface';

const ambassadors: Module<AmbassadorsStateInterface, any> = {
    namespaced: true,
    state: AMBASSADORS_EMPTY_STATE,
    getters: {
        [AmbassadorsMethods.getters.GET_REFERRALS](state): {total_referrals: number, referrals: User[]} {
            return state.referralsInfo;
        },
        [AmbassadorsMethods.getters.GET_REFERRALS_OPERATIONS](state): {total_operations: number, operations: ReferralOperation[]} {
            return state.referralsOperations;
        },
        [AmbassadorsMethods.getters.GET_REFERRALS_BY_COUNTRIES](state): ReferralsByCountry[] {
            return state.referralsByCountries;
        },
        [AmbassadorsMethods.getters.GET_REFERRALS_BY_STATUS](state): ReferralsByStatus[] {
            return state.referralsByStatus;
        },
        [AmbassadorsMethods.getters.GET_CRYPTOMILES](state): CryptoMile[] {
            return state.cryptoMiles;
        },

    },
    mutations: {
        [AmbassadorsMethods.mutations.SET_REFERRALS](state, referralsInfo: {total_referrals: number, referrals: User[]}): void {
            state.referralsInfo = referralsInfo
        }, 
        [AmbassadorsMethods.mutations.SET_REFERRALS_OPERATIONS](state, referralsOperations: {total_operations: number, operations: ReferralOperation[]}): void {
            state.referralsOperations = referralsOperations
        },
        [AmbassadorsMethods.mutations.SET_REFERRALS_BY_COUNTRIES](state, referralsByCountries: ReferralsByCountry[]): void {
            state.referralsByCountries = referralsByCountries
        }, 
        [AmbassadorsMethods.mutations.SET_REFERRALS_BY_STATUS](state, referralsByStatus: ReferralsByStatus[]): void {
            state.referralsByStatus = referralsByStatus
        }, 
        [AmbassadorsMethods.mutations.SET_CRYPTOMILES](state, cryptoMiles: CryptoMile[]): void {
            state.cryptoMiles = cryptoMiles
        }, 
        [AmbassadorsMethods.mutations.RESET_AMBASSADORS_DATA](state): void {
            state.referralsInfo={
                total_referrals:0,
                referrals:[]
            },
            state.referralsOperations={
                operations: [],
                total_operations: 0
            },
            state.referralsByCountries= [],
            state.referralsByStatus= [],
            state.cryptoMiles=[]
        },
    },
    actions: {
        async [AmbassadorsMethods.actions.FETCH_REFERRALS]({ commit },data: any): Promise<boolean> {
            try {
                let params = '?'+getParams(data)
                let response : {total_referrals: number, referrals: User[]} = await ambassadorsHttpRepository.getReferrals(params)
                if (response && response.referrals === null){
                    response.referrals = []
                }
                if (response.referrals){
                    commit(AmbassadorsMethods.mutations.SET_REFERRALS,response)
                    return true;
                }
                return false;
            } catch (e) {
                return false;
            }
        },
        async [AmbassadorsMethods.actions.FETCH_REFERRALS_OPERATIONS]({ commit },email_user: string): Promise<boolean> {
            try {
                let referralOperations : {total_operations: number, operations: ReferralOperation[]} = await ambassadorsHttpRepository.getReferralsOperations(email_user)
                if (referralOperations && referralOperations.operations === null) referralOperations.operations = []
                commit(AmbassadorsMethods.mutations.SET_REFERRALS_OPERATIONS,referralOperations)
                return true;
            } catch (e) {
                return false;
            }
        },
        async [AmbassadorsMethods.actions.FETCH_REFERRALS_BY_COUNTRIES]({ commit },email_user: string): Promise<boolean> {
            try {
                let referralsByCountry : ReferralsByCountry[] = await ambassadorsHttpRepository.getReferralsByCountries(email_user)
                if (referralsByCountry === null)
                referralsByCountry = []
                commit(AmbassadorsMethods.mutations.SET_REFERRALS_BY_COUNTRIES,referralsByCountry)
                return true;
            } catch (e) {
                return false;
            }
        },
        async [AmbassadorsMethods.actions.FETCH_REFERRALS_BY_STATUS]({ commit },email_user: string): Promise<boolean> {
            try {
                let referralsByStatus : ReferralsByStatus[] = await ambassadorsHttpRepository.getReferralsByStatus(email_user)
                if (referralsByStatus === null)
                referralsByStatus = []
                commit(AmbassadorsMethods.mutations.SET_REFERRALS_BY_STATUS,referralsByStatus)
                return true;
            } catch (e) {
                return false;
            }
        },
        async [AmbassadorsMethods.actions.SEND_AMBASSADOR_REQUEST]({ commit },email_user: string): Promise<boolean> {
            try {
                let response : {mailResp: string} = await ambassadorsHttpRepository.sendAmbassadorRequest(email_user);
                if (response.mailResp === "Email succesfully sent") return true;
                return false;
            } catch (e) {
                return false;
            }
        },
        async [AmbassadorsMethods.actions.FETCH_CRYPTOMILES]({ commit },email_user: string): Promise<boolean> {
            try {
                let response : CryptoMile[] = await ambassadorsHttpRepository.getCryptomiles(email_user);
                if (response === null) response = [];
                commit(AmbassadorsMethods.mutations.SET_CRYPTOMILES,response)
                return true;
            } catch (e) {
                return false;
            }
        }
    }
};

export default ambassadors;