import { CountriesStateInterface } from './interfaces/countries.interface';

export const COUNTRIES_EMPTY_STATE: CountriesStateInterface = {
    residenceCountries: [],
    nationalityCountries: [],
    allCountries: [],
    destinyCountries: [],
    countryStates: [],
    originCountriesCurrencies: [],
    destinyCountriesCurrencies: [],
    userOriginRemittanceCountriesCurrencies:[],
    currentDestinyCountryCurrency: {iso_cod: '',id_country: null,id_currency: null,name_country: '',country_iso_code: '',viewing_name: ''},
    currentOriginCountryCurrency: {iso_cod: '',id_country: null,id_currency: null,name_country: '',country_iso_code: '',viewing_name: ''},
    frequentDestinations: []
};
