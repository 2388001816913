
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Notif } from '../interfaces/notification.interface';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import NotificationsMethods from '@/store/notifications/methods/verificationLevels.methods';
import { notifications } from '@/store/namespaces';
import { showToast } from '@/utils/global-functions';

@Component({
    components: {
        SvgIcon
    },
})
export default class NotificationPanel extends Vue {
    @Prop() notifications!: Notif[];
    activeSidebar = false;
    confirmationModal ={
        show: false,
        id_notification: -1
    };
    notificationslocal : Notif[] = [];

  

    @Watch('notifications')
    notificationsReverse(){
        this.notificationslocal = this.notifications;
    }

    get isAllRead() : boolean{
        let allRead : boolean = true;
        this.notificationslocal.forEach(el =>{
            if (el.is_read === false) { allRead = false}
        })
        return allRead
    }

    async deleteNotif(){
        if (this.confirmationModal.id_notification !== -1 && await this.deleteNotificacion(this.confirmationModal.id_notification)){
            this.confirmationModal.show = false;
            // showToast('Se ha eliminado la notificación correctamente','success');
        }else showToast('Ha ocurrido un problema eliminando la notificación','error')
    }

    async markAsRead(){
        if (this.confirmationModal.id_notification !== -1 && await this.markAsReadNotificacion(this.confirmationModal.id_notification)){
        }else showToast('Ha ocurrido un problema modificando la notificación','error')
    }

    @notifications.Action(NotificationsMethods.actions.DELETE_NOTIFICATION)
        deleteNotificacion!: (id_notification: number) => Promise<boolean>
    @notifications.Action(NotificationsMethods.actions.MARK_AS_READ_NOTIFICATIONS)
        markAsReadNotificacion!: (id_notification: number) => Promise<boolean>
    
}
