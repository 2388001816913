export const RATE_OPERATIONS={
    MULTIPLICATION : 'mul',
    DIVISION: 'div'
}

export const RATE_CATEGORIES={
    VIP: 'VIP',
    SPECIAL: 'SPECIAL',
    MANUAL: 'MANUAL',
    AUTOMATIC: 'AUTOMATIC'
}

export const RATE_TYPES = {
    NORMAL: 'Normal',
    PREFERENTIAL: 'Preferencial',
    WHOLESALER: 'Mayorista'
}

export const MANUAL_RATES = {
    NORMAL: 'Normal',
    PREFERENTIAL: 'Preferencial',
    WHOLESALER: 'Mayorista',
    VIPF: 'VIP Fidelidad',
    WHOLESALE_PARTNER: 'Asociado Mayorista',
    BIRTHDAY: 'Cumpleaños',
    FIRST_OP: 'Primera Operación'
}

