import store from "@/store";
import { USER_EMPTY_STATE } from "../emptyStates/user.emptyState";
import router from '@/router/index';
import { AUTH_EMPTY_STATE } from "@/store/auth/auth.state";
import { cleanStore } from "@/store/cleaner";

export function finishSession(){
    localStorage.removeItem('_secure__ls__metadata');
    localStorage.removeItem('CR-vuex');
    store.state.auth.isAuthenticated = false;
    store.state.auth.user = USER_EMPTY_STATE;
    store.commit('beneficiaries/RESET_BENEFICIARIES_DATA', null, { root: true });
    store.commit('ambassadors/RESET_AMBASSADORS_DATA', null, { root: true });
    store.commit('auth/RESET_AUTH_DATA', null, { root: true });
    store.commit('balances/RESET_BALANCE_DATA', null, { root: true });
    store.commit('banks/RESET_BANKS_DATA', null, { root: true });
    store.commit('chat/RESET_CHAT_DATA', null, { root: true });
    store.commit('countries/RESET_COUNTRIES_DATA', null, { root: true });
    store.commit('currencies/RESET_CURRENCIES_DATA', null, { root: true });
    store.commit('docTypes/RESET_DOC_TYPES_DATA', null, { root: true });
    store.commit('exchange/RESET_EXCHANGES_DATA', null, { root: true });
    store.commit('notifications/RESET_NOTIFICATIONS_DATA', null, { root: true });
    store.commit('operationRoutes/RESET_OPERATION_ROUTES', null, { root: true });
    store.commit('payMethods/RESET_PAY_METHODS_DATA', null, { root: true });
    store.commit('rates/RESET_RATES_DATA', null, { root: true });
    store.commit('remittance/RESET_REMITTANCES_DATA', null, { root: true });
    store.commit('users/RESET_USERS_DATA', null, { root: true });
    store.commit('verifLevels/RESET_VERIF_LEVEL_DATA', null, { root: true });
    store.commit('wholesalePartners/RESET_WHOLESALEPARTNERS_DATA', null, { root: true });
    
    store.state.auth.ambassadorLink = null;
    cleanStore();
}

export function getCategoryColor(categoryName : string){
    if (categoryName === 'Bronce') return 'icon-bronce'
    else if (categoryName === 'Oro') return 'icon-gold'
    else if (categoryName === 'Plata') return 'icon-silver'
    else return ''
}

