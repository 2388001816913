
import { NEW_REMITTANCE_EMPTY_STATE } from '@/modules/remittance/constants/remittance.emptyState.constant';
import { RemittanceStateInterface } from './interfaces/remittance.state.interface';


export const REMITTANCE_EMPTY_STATE: RemittanceStateInterface = {
    remittances: [],
    chatRemittances: [],
    preRemittance: {
        date:null,
        pre_remittance: null,
        active: null,
        was_expired: null,
        date_last_shown: null,
        id_pre_remittance: null
    },
    newRemittance: NEW_REMITTANCE_EMPTY_STATE,
    currentBeneficiaries: [],
    notificationTypes:[],
    limits: [],
    maxRemittanceAmmounts:{
        weeklyAmmount: 0,
        monthlyAmmount: 0
    },
    totalAcum: 0,
    weeklyAcum: 0,
    monthlyAcum: 0,
    residCountryCurrency:'',
    validOwnExtTrans: null,
    validThirdNatTrans: null,
    validThirdExtTrans: null,
    maxBeneficiariesPerRemittance: 0,
    minAmounts: [],
    acumLimitsByCountry: null,
    remittanceAcumByMonth:[],
    remittancesByStatus: [],
    totalRemittances:0,
    remittanceQuantityByMonth:[],
    webpay: {
        token: null,
        url: null,
    }
};


