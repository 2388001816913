import { AxiosRepository } from '@/http/axios.repository';
import { Notif } from '../interfaces/notification.interface';

const NOTIFICATIONS_RESOURCE = 'verifLevels/notifications';

export class NotificationsRepository extends AxiosRepository {
    
    async getAllNotificactions(email: string): Promise<Notif[]> { 
        return super.get(email,NOTIFICATIONS_RESOURCE);
    }

    async deleteNotification(id: number): Promise<{msg: string}>{
        return super.delete(id, NOTIFICATIONS_RESOURCE)
    }

    async markAsRead(id: number): Promise<{msg: string}>{
        return super.patch(id, NOTIFICATIONS_RESOURCE)
    }

}

export const notificationsHttpRepository = new NotificationsRepository();