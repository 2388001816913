export default {
    mutations: {
        SET_CLIENTS: 'SET_CLIENTS',
        SET_CLIENTS_REMITTANCES: 'SET_CLIENTS_REMITTANCES',
        SET_REPORTS: 'SET_REPORTS',
        SET_WHOLESALEPARTNER_RATES: 'SET_WHOLESALEPARTNER_RATES',
        RESET_WHOLESALEPARTNERS_DATA: 'RESET_WHOLESALEPARTNERS_DATA',
    },
    getters: {
        GET_CLIENTS: 'GET_CLIENTS',
        GET_CLIENTS_REMITTANCES: 'GET_CLIENTS_REMITTANCES',
        GET_REPORTS: 'GET_REPORTS',
        GET_WHOLESALEPARTNER_RATES: 'GET_WHOLESALEPARTNER_RATES',
    },
    actions: {
        SEND_WHOLESALEPARTNER_PAGE_INGO: 'SEND_WHOLESALEPARTNER_PAGE_INGO',
        FETCH_CLIENTS: 'FETCH_CLIENTS',
        FETCH_CLIENTS_REMITTANCES: 'FETCH_CLIENTS_REMITTANCES',
        FETCH_REPORTS: 'FETCH_REPORTS',
        UPDATE_PERCENT_PROFIT: 'UPDATE_PERCENT_PROFIT',
        FETCH_WHOLESALEPARTNER_RATES: 'FETCH_WHOLESALEPARTNER_RATES'
    },
};