export default {
    mutations: {
        SET_OPERATION_ROUTES: 'SET_OPERATION_ROUTES',
        RESET_OPERATION_ROUTES: 'RESET_OPERATION_ROUTES',
    },
    getters: {
        GET_OPERATION_ROUTES: 'GET_OPERATION_ROUTES',
    },
    actions: {
        FETCH_OPERATION_ROUTES: 'FETCH_OPERATION_ROUTES',
    },
};