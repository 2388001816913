
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';

@Component({
    components: {
        SvgIcon
    }
})
export default class InfoModal extends Vue {
    @Prop() title!: string;
    @Prop() button?: string;
    @Prop() showModal!: boolean;
    @Prop() size?: string;
    @Prop() buttonLarge?: boolean;
    @Prop() hideButton?: boolean;
    @Prop() hideCloseButton?: boolean;
    @Prop() secondAction?: boolean
    @Prop() secondButton?: string
    show: boolean = false;

    /* MODAL */
    @Watch('showModal')
     renderModal(){
        this.show = this.showModal;
    }

    @Watch('show')
    sendToParent(newVal: boolean){
        this.$emit('changeModalStatus', newVal)
    }
    /* END MODAL */

    confirm(){
        this.$emit('confirm')
    }

    secondButtonPress(){
        if (this.secondAction)
            this.$emit('secondActionSubmit')
        else this.show = false
    }



}
    
