import { BeneficiariesStateInterface } from './interfaces/beneficiaries.interface';

export const BENEFICIARIES_EMPTY_STATE: BeneficiariesStateInterface = {
    frequentBeneficiaries: [],
    simulatedFrequentBeneficiaries: [
        {
            id_beneficiary:"15",
            nickname:"Mamá Vzla",
            owner_name:"Beneficiario 1",
            identification:"123456789",
            account:"786876876876",
            account_type:null,
            phone_number:null,
            email:null,
            name_bank:"Bancolombia",
            name_pay_method:"Transferencia Bancaria",
            name_doc_type:"Documento Nacional de Identidad",
            id_doc_type:12,
            id_bank:6,
            id_pay_method:3,
            country_code:"CO",
            id_country:"2",
            currency_name:"COP",
            check:false,
            frequent:true
        },
        {
            id_beneficiary:"17",
            nickname:"Papá BRA",
            owner_name:"Beneficiario 2",
            identification:"32423423",
            account:"234234234234",
            account_type:null,
            phone_number:null,
            email:null,
            name_bank:"Bancolombia",
            name_pay_method:"Transferencia Bancaria",
            name_doc_type:"Pasaporte",
            id_doc_type:40,
            id_bank:6,
            id_pay_method:3,
            country_code:"CO",
            id_country:"2",
            currency_name:"COP",
            check:false,
            frequent:true
        },
        {
            id_beneficiary:"17",
            nickname:"Hermano BRA",
            owner_name:"Beneficiario 3",
            identification:"32423423",
            account:"234234234234",
            account_type:null,
            phone_number:null,
            email:null,
            name_bank:"Bancolombia",
            name_pay_method:"Transferencia Bancaria",
            name_doc_type:"Pasaporte",
            id_doc_type:40,
            id_bank:6,
            id_pay_method:3,
            country_code:"CO",
            id_country:"2",
            currency_name:"COP",
            check:false,
            frequent:true
        }
    ],
    topFrequentBeneficiaries: []
};
