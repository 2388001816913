
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
    components: {

    }
})
export default class ConfirmationModal extends Vue {
    @Prop() title!: string;
    @Prop() showModal!: boolean;
    show: boolean = false;

    /* MODAL */
    @Watch('showModal')
     renderModal(){
        this.show = this.showModal;
    }

    @Watch('show')
    sendToParent(newVal: boolean){
        this.$emit('changeModalStatus', newVal)
    }
    /* END MODAL */

    confirm(){
        this.$emit('confirm')
    }

}
    
