export const EnvironmentConstants = {
    HOST: process.env.VUE_APP_HOST +
        // ':' +
        //  process.env.VUE_APP_PORT +
         process.env.VUE_APP_BASE_API_URL,
    SOCKETHOST:
         process.env.VUE_APP_HOST ,
        // ':' +
        // process.env.VUE_APP_PORT,
    COOKIE_SESSION: process.env.VUE_APP_COOKIE_SESSION,
    DOMAIN: process.env.VUE_APP_DOMAIN,
    CURRENCY_FREAKS_URL: process.env.VUE_APP_CURRENCY_FREAKS_API_URL,
    EMAIL_SERVER_HOST: process.env.VUE_APP_EMAIL_SERVER_HOST
};