import { Module } from 'vuex';
import BalancesMethods from '@/store/balances/methods/balances.methods';
import { BALANCES_EMPTY_STATE } from './balances.state';
import { BalancesStateInterface } from './interfaces/balances.interface';
import { balanceHttpRepository } from '@/modules/balances/repositories/balances.httpRepository';
import { PayMethod } from '@/modules/payMethods/interfaces/payMethods.interface';
import { DepositMethod } from '@/modules/payMethods/interfaces/depositMethods.interface';
import { getParams } from '@/utils/global-functions';
import { Balance } from '@/modules/balances/interfaces/balance.interface';

const balances: Module<BalancesStateInterface, any> = {
    namespaced: true,
    state: BALANCES_EMPTY_STATE,
    getters: {
        [BalancesMethods.getters.GET_CRYPTO_BALANCE](state): Balance[] {
            return state.cryptoBalance;
        },
        [BalancesMethods.getters.GET_FIAT_BALANCE](state): Balance[] {
            return state.fiatBalance;
        },
    },
    mutations: {
        [BalancesMethods.mutations.SET_CRYPTO_BALANCE](state, balance: Balance[]): void {
            state.cryptoBalance = balance
        }, 
        [BalancesMethods.mutations.SET_FIAT_BALANCE](state, balance: Balance[]): void {
            state.fiatBalance = balance
        },
        [BalancesMethods.mutations.RESET_BALANCE_DATA](state): void {
            state.cryptoBalance = []
            state.fiatBalance = []
        },
    },
    actions: {
        async [BalancesMethods.actions.FETCH_BALANCE_BY_DESTINY_COUNTRY]({ commit },email_user: string): Promise<any> {
            try {
                let response : {balances: Balance[]} = await balanceHttpRepository.getUserBalance(email_user)
                if (response.balances){
                    let cryptoBalance = response.balances.filter(el=>el.currency_type === 'crypto')
                    let fiatBalance = response.balances.filter(el=>el.currency_type === 'fiat')
                    commit(BalancesMethods.mutations.SET_CRYPTO_BALANCE,cryptoBalance)
                    commit(BalancesMethods.mutations.SET_FIAT_BALANCE,fiatBalance)
                    return true;
                }
                return false;
            } catch (e) {
                return false;
            }
        }
    }
};

export default balances;