export const YEAR_DAYS= {
  '01': 31,
  '02': 29,
  '03': 31,
  '04': 30,
  '05': 31,
  '06': 30,
  '07': 31,
  '08': 31,
  '09': 30,
  '10': 31,
  '11': 30,
  '12': 31,
}

export function getYMD(date: any){
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); 
    let yyyy = date.getFullYear();
    return yyyy+mm+dd
}

export function getDMY(date: any){
  let dd = String(date.getDate()).padStart(2, '0');
  let mm = String(date.getMonth() + 1).padStart(2, '0'); 
  let yyyy = date.getFullYear();
  return dd+'/'+mm+'/'+yyyy;
}

export function dateToDDMMYYY(date: Date){
  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  if(month < 10){
    return `${day}-0${month}-${year}`
  }else{
    return `${day}-${month}-${year}`
  }
}

/* Obtains a date from the current one, subtracting days, months or years */
export function deltaDate(input: Date, days : number, months : number, years : number) {
    return new Date(
        input.getFullYear() + years, 
        input.getMonth() + months, 
        Math.min(
          input.getDate() + days,
          new Date(input.getFullYear() + years, input.getMonth() + months + 1, 0).getDate()
        )
    );
}

/* Returns true if compareDate is bigger than date */
export function isLessOrEqualToOneYear(month: number,year:number,monthToCompare:number,yearToCompare: number) {
  if ((yearToCompare === year && monthToCompare >= month) || (yearToCompare === year+1 && monthToCompare <= month)){
    return true
  } 
  else return false
}


/* Convert Epoch to 02-03-2022 12:54:03 */
export function epochDateToDDMMYYYAndHour(date: Date){
  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  let hour = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

    return `${day < 10 ? '0'+day : day}-${month < 10 ? '0'+month : month}-${year} ${hour< 10 ? '0'+hour: hour}:${minutes < 10 ? '0'+minutes : minutes}:${seconds < 10 ? '0'+seconds : seconds}`
}

/* Convert 2013-03-10T02:00:00Z to 10/03/2013 */
export function isoDateToDDMMYYYY(date: string){
  let newDate : any = new Date(date)
  newDate = newDate.toISOString().substring(0, 10).toString();
  return formatDate(newDate)
}

export function getLastNMonthEpochDate(n: number){
  let date : any = new Date(); 
  date.setMonth(date.getMonth() - n);
  date.setHours(0, 0, 0, 0);
return date / 1000
}

/* Convert epoch to 10/03/2013 */
export function epochToDDMMYYYY(date: number){
  let newDate : any = new Date(date * 1000)
  newDate = newDate.toISOString().substring(0, 10).toString();
  return formatDate(newDate)
}

/* Convert 2021-07-12 to 12/07/2021  */
export function formatDate(currentDate : string){
   let  newDate = currentDate.split("-");
   return newDate[2] + '/' + newDate[1] + '/' + newDate[0]
}

/* Convert 2021/10/11 to 11/10/2021 */
export function usToSpanishFormat(currentDate: string){
    var datePart = currentDate.match(/\d+/g),
    year = datePart[0], month = datePart[1], day = datePart[2];
    return day+'/'+month+'/'+year;
}

/* Convert 11/10/2021 to 10/11/2021 */
export function spanishDateToEnglishDate(date: string){
  let day = date.substr(0,2)
  let month = date.substr(3,2)
  return month+'/'+day+'/'+date.substr(6,date.length)
}

/* Convert 44235435345 to 24/04/2022, 10:24:33 */
export function epochToDateAndHour(epoch: number){
  let date = new Date(epoch*1000);
  return date.toLocaleString("es-ES")
}

/* Convert 44235435345000 to 24/04/2022, 10:24:33 */
export function epochInMsToDateAndHour(epoch: number){
  let date = new Date(epoch);
  return date.toLocaleString("es-ES")
}

/* Validate dd/mm/yyyy date */
export const validFormatDate = (date : string) =>{
    let reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
    if (date !== null && date.match(reg)) return true
    else return false
}
/* Validates that the year is less than or equal to the current one */
export const validYearDate = (date : string) =>{
  let splidDate = []
  if (date !== null){
    splidDate  = date.split('/');
    if (YEAR_DAYS[splidDate[1]] < splidDate[0]) return false
    if (splidDate[2] > new Date().getFullYear()) return false
  }
  return true
}
/* Validates that the date indicates that the user is of legal age */
export const validAdultYearDate = (date : string) =>{
  let splidDate = []
  if (date !== null){
    splidDate  = date.split('/');
    if (splidDate[2] > (new Date().getFullYear() - 18)) return false
  }
  return true
}

export function compareNowTimeStampDayAndMonth(date: string){
  let newDate = new Date(date).toISOString().substring(5, 10);
  let nowDate = new Date().toISOString().substring(5, 10);
  if (newDate === nowDate) return true
  return false
}

export function lessThan10(number: string | number){
  if (typeof number === 'number')
      number = number.toString()
  if (typeof number === 'string')
      if (parseInt(number) < 10) return '0'+number
  else return number;
}

export function epotchToTimeZone(epotchId: number){
  return new Date(epotchId * 1000);
}

export function epotchToSpanishTimeZone(epotchId: number, small?: boolean): string{
  const date = new Date (epotchId*1000);
  let locale = 'es-GB';
  let options : any = { dateStyle: 'full', timeStyle: 'long' }
  if (small) options = {}
  const finalDate  = new Intl.DateTimeFormat(locale, options).format(date)
  return finalDate;
}

const intervals={
  second: 1000,
  minute: 1000 * 60,
  hour: 1000 * 60 * 60,
  day: 1000 * 60 * 60 * 24
}

export function updateDiffs(initDate: any,date: any) {
  let days = 0
  let hours = 0
  let minutes = 0
  let seconds = 0
  let diff = Math.abs(initDate.getTime() - date.getTime());
  days = Math.floor(diff / intervals.day);
  diff -= days * intervals.day;
  hours = Math.floor(diff / intervals.hour);
  diff -= hours * intervals.hour;
  minutes = Math.floor(diff / intervals.minute);
  diff -= minutes * intervals.minute;
  seconds = Math.floor(diff / intervals.second);
  hours += days*24;
  return {days: lessThan10(days),hours: lessThan10(hours),minutes: lessThan10(minutes),seconds: lessThan10(seconds)}
}

