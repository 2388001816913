import { Module } from 'vuex';
import NotificationsMethods from '@/store/notifications/methods/verificationLevels.methods';
import { NOTIFICATIONS_EMPTY_STATE } from './notifications.state';
import { NotificationsStateInterface } from './interfaces/notifications.interface';
import { Question } from '@/modules/verificationLevels/interfaces/questions.interface';
import { Notif } from '@/modules/notifications/interfaces/notification.interface';
import { notificationsHttpRepository } from '@/modules/notifications/repositories/notificationsHttp.repository';
import { dateToDDMMYYY, epochDateToDDMMYYYAndHour } from '@/utils/date-functions';
import store from '@/store';
import { NOTIFICATION_TYPES } from '@/modules/notifications/constants/notification.constants';

const notificacions: Module<NotificationsStateInterface, any> = {
    namespaced: true,
    state: NOTIFICATIONS_EMPTY_STATE,
    getters: {
        [NotificationsMethods.getters.GET_NOTIFICATIONS](state): Notif[]{
            return state.notifications
        },
        [NotificationsMethods.getters.GET_MARQUEE_NOTIFICATIONS](state): Notif[]{
            return state.marqueeNotifications
        }
    },
    mutations: {
        [NotificationsMethods.mutations.SET_NOTIFICATIONS](state, notifications : Notif[]): void {
            state.notifications = notifications;
        },
        [NotificationsMethods.mutations.SET_MARQUEE_NOTIFICATIONS](state, notifications : Notif[]): void {
            state.marqueeNotifications = notifications;
        },
        [NotificationsMethods.mutations.RESET_NOTIFICATIONS_DATA](state): void {
            state.notifications = [],
            state.marqueeNotifications = []
        }
    },
    actions: {
        async [NotificationsMethods.actions.FETCH_NOTIFICATIONS]({ commit }, email_user: string): Promise<boolean> {
            try {
                let notifications : Notif[] = await notificationsHttpRepository.getAllNotificactions(email_user);
                notifications.forEach(el =>{
                    el.notification_date = epochDateToDDMMYYYAndHour(new Date(el.date_creation * 1000))
                })
                let marqueeNotifications = notifications.filter(el=> el.type === NOTIFICATION_TYPES.MARQUEE)
                notifications = notifications.filter(el=> el.type === NOTIFICATION_TYPES.NOTIFICATIONS)
                notifications = notifications.reverse();
                if (notifications.length > 0){
                    commit(NotificationsMethods.mutations.SET_NOTIFICATIONS,notifications);
                    commit(NotificationsMethods.mutations.SET_MARQUEE_NOTIFICATIONS,marqueeNotifications);
                    return true;
                }
                return false
            } catch (e) {
                return false;
            }
        },
        async [NotificationsMethods.actions.DELETE_NOTIFICATION]({commit},id_notification: number): Promise<boolean>{
            try{
                
                let response = await notificationsHttpRepository.deleteNotification(id_notification);
                if (response.msg === "Notification succesfully deactivated") {
                    let notifications : Notif[] = store.state.notifications.notifications
                    notifications = notifications.filter(el => el.id_notification !== id_notification)
                    commit(NotificationsMethods.mutations.SET_NOTIFICATIONS,notifications)
                    return true
                }
                return false
            }catch(e){
                return false;
            }
        },
        async [NotificationsMethods.actions.MARK_AS_READ_NOTIFICATIONS]({commit},id_notification: number): Promise<boolean>{
            try{
                let response = await notificationsHttpRepository.markAsRead(id_notification);
                if (response.msg === "Notification read") {
                    let notifications : Notif[] = store.state.notifications.notifications
                    notifications.forEach(el => {
                        if (el.id_notification === id_notification){
                            el.is_read = true;
                        }
                    })
                    commit(NotificationsMethods.mutations.SET_NOTIFICATIONS,notifications)
                    return true
                }
                return false
            }catch(e){
                return false;
            }
        }
    },
};

export default notificacions;