import { AxiosRepository } from '@/http/axios.repository';
import { State } from '@/modules/countries/interfaces/states.interface';

const STATES_RESOURCE = 'countries/states';


export class StatesRepository extends AxiosRepository {
    
    async getStatesByCountry(countryId: number): Promise<State[]> {
        return await super.get(countryId,STATES_RESOURCE);
    }


}

export const statesHttpRepository = new StatesRepository();