export default {
    mutations: {
        SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
        SET_MARQUEE_NOTIFICATIONS: 'SET_MARQUEE_NOTIFICATIONS',
        RESET_NOTIFICATIONS_DATA: 'RESET_NOTIFICATIONS_DATA',
    },
    getters: {
        GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
        GET_MARQUEE_NOTIFICATIONS: 'GET_MARQUEE_NOTIFICATIONS',
    },
    actions: {
        FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
        DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
        MARK_AS_READ_NOTIFICATIONS: 'MARK_AS_READ_NOTIFICATIONS',

    },
};