import { USER_EMPTY_STATE } from '@/modules/auth/emptyStates/user.emptyState';
import { AuthStateInterface } from './interfaces/auth.state.interface';

export const AUTH_EMPTY_STATE: AuthStateInterface = {
    user: USER_EMPTY_STATE,
    ambassadorLink: null,
    userSignUp: USER_EMPTY_STATE,
    isAuthenticated: false,
    selectedTypeDocType: 0,
    selectedCountryIdDocType: '',
    atcPhone: '',
    levelQuestions: [],
    registerPublicCode: '',
    notAuthenticatedUUid: '',
    publicIp:'1.2.2.3'
};
