import { Module } from 'vuex';
import ChatMethod from '@/store/chat/methods/chat.methods';
import { CHAT_EMPTY_STATE } from './chat.state';
import { ChatStateInterface } from './interfaces/chat.interface';
import { chatHttpRepository } from '@/modules/chat/repositories/chat.httpRepository';
import { getParams } from '@/utils/global-functions';
import { Bank } from '@/modules/banks/interfaces/bank.interface';
import { LoginMessage, Message } from '@/modules/chat/interfaces/message.interface';
import { MESSAGE_TYPES } from '@/modules/chat/constants/message.constants';
import { email } from 'vuelidate/lib/validators';

const chat: Module<ChatStateInterface, any> = {
    namespaced: true,
    state: CHAT_EMPTY_STATE,
    getters: {
        [ChatMethod.getters.GET_MESSAGES](state): Message[] {
            return state.messages;
        },
        [ChatMethod.getters.GET_LOGIN_MESSAGES](state): LoginMessage[] {
            return state.loginMessages;
        },
    },
    mutations: {
        [ChatMethod.mutations.SET_MESSAGES](state, messages: Message[]): void {
            state.messages = messages;
        },
        [ChatMethod.mutations.SET_LOGIN_MESSAGES](state, messages: LoginMessage[]): void {
            state.loginMessages = messages;
        },
        [ChatMethod.mutations.RESET_CHAT_DATA](state): void {
            state.messages= [],
            state.loginMessages= []
        },
    },
    actions: {
        async [ChatMethod.actions.SET_LOGIN_MESSAGES]({ commit }, messages: LoginMessage[]): Promise<void> {
            commit(ChatMethod.mutations.SET_LOGIN_MESSAGES,messages)
        },
        async [ChatMethod.actions.SET_MESSAGES]({ commit }, messages: Message[]): Promise<void> {
            let messagesToSave =  messages;
            messagesToSave.forEach(el =>{
                if (el.type === MESSAGE_TYPES.IMAGE || el.type === MESSAGE_TYPES.VOICE){
                    //@ts-ignore
                    let blob = new Blob([new Uint8Array(el.file)]);
                    el.fileSrc = {
                        src: window.URL.createObjectURL(blob)
                    }
                }   
            })
            commit(ChatMethod.mutations.SET_MESSAGES,messagesToSave)
        },
        async [ChatMethod.actions.SEND_MESSAGE]({ commit }, message: Message): Promise<boolean> {
            try {
                let file = undefined;
                //@ts-ignore
                if (message.message_body.type === MESSAGE_TYPES.IMAGE ){ 
                    //@ts-ignore
                    file = message.file
                }else if (message.message_body.type === MESSAGE_TYPES.VOICE){
                    file = new File([message.file],new Date().getTime()+'.webm',{type: 'audio/webm'})
                }
                let bodyFormData = new FormData();
                bodyFormData.append('email_user', message.email_user)
                bodyFormData.append('emp_username', null)
                bodyFormData.append('message_body',JSON.stringify( message.message_body))
                bodyFormData.append('msg_date', message.msg_date)
                bodyFormData.append('file', file)
                bodyFormData.append('is_sent', message.is_sent.toString())
                bodyFormData.append('time_zone',null);
                bodyFormData.append('uniq_id',null);
                let response : {message: string} = await chatHttpRepository.sendMessage(bodyFormData)
                if (response.message === "Message delivered"){
                    return true
                }
                return false
            } catch (e) {
                return false;
            }
        },
        async [ChatMethod.actions.FETCH_MESSAGES]({ commit }, emailUser: string): Promise<boolean> {
            try {       
                commit(ChatMethod.mutations.SET_MESSAGES,[])
                let messages : Message[] = await chatHttpRepository.getMessages(emailUser);
                messages.forEach(el=>{
                    if (el.type === MESSAGE_TYPES.VOICE || el.type === MESSAGE_TYPES.IMAGE){
                        //@ts-ignore
                        let blob = new Blob([new Uint8Array(el.file.data)]);
                        el.fileSrc = {
                            src: window.URL.createObjectURL(blob)
                        }
                    }
                })
                if (messages.length > 0){
                    commit(ChatMethod.mutations.SET_MESSAGES,messages)
                    return true
                }
                return false
            } catch (e) {
                return false;
            }
        }
    }
};

export default chat;