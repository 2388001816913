import { REMITTANCE_DETOUR_STATUS, REMITTANCE_PUBLIC_STATUS } from "../constants/remittance.constants";
import { NewRemittance } from "../interfaces/newRemittance.interface";
import { Remittance } from "../interfaces/remittance.interface";

export function remittanceBadgeColor(publicStatus: string): string{
    switch(publicStatus){
        case REMITTANCE_PUBLIC_STATUS.IN_PROGRESS:
            return  'badge-inProgress'
        case REMITTANCE_PUBLIC_STATUS.IN_PROGRESS_2:
            return  'badge-inProgress'
        case REMITTANCE_PUBLIC_STATUS.PENDING_FOR_TRANSFER:
            return 'badge-toBeChecked'
        case REMITTANCE_PUBLIC_STATUS.FINISHED:
            return 'badge-sent'
        case REMITTANCE_PUBLIC_STATUS.CANCELED:
            return 'badge-rejected'
        case REMITTANCE_PUBLIC_STATUS.STARTED:
            return 'badge-toBeChecked'
        default:
            return 'badge-rejected'
    }
}

export function remittanceDetourStatusName(publicStatus: string): string{
    switch(publicStatus){
        case REMITTANCE_DETOUR_STATUS.CANCELED:
            return  'Atención Cliente'
        case REMITTANCE_DETOUR_STATUS.FAIL_BANK_VERF:
            return 'Fallo en Rcp. Fondos'
        case REMITTANCE_DETOUR_STATUS.IN_CLAIM:
            return 'Reclamo'
        default:
            return null
    }
}


export function convertToAmountFee(remittance: NewRemittance){
    if (!remittance.amountFee && remittance.percentFee){
        return remittance.totalComission * remittance.totalDeposited /100;
    }
    else return remittance.totalComission
}