import { AxiosRepository } from '@/http/axios.repository';
import { CountryDocType } from '../interfaces/docType.interface';

const DOC_TYPES_RESOURCE = 'doc_types'; 


export class DocTypeRepository extends AxiosRepository {
    
    async getDocTypes(params: string): Promise<CountryDocType[]> { 
        return super.getAll(DOC_TYPES_RESOURCE+'/getActive'+params);
    }

    async getAllDocTypes(): Promise<CountryDocType[]> { 
        return super.getAll(DOC_TYPES_RESOURCE+'/getActive');
    }

    async getDocTypeById(docTypeId: number): Promise<CountryDocType> { 
        return super.getAll(DOC_TYPES_RESOURCE+'/'+docTypeId);
    }

}

export const docTypeHttpRepository = new DocTypeRepository();