import { Module } from 'vuex';
import ExchangeMethods from '@/store/exchange/methods/exchange.methods';
import { EXCHANGE_EMPTY_STATE } from './exchange.state';
import { ExchangeStateInterface } from './interfaces/exchange.interface';
import { exchangeHttpRepository } from '@/modules/exchange/repositories/exchange.httpRepository';
import { ExchangeRangeRate, ExchangeRate } from '@/modules/exchange/interfaces/exchangeRate.interface';
import { Exchange, PreExchange } from '@/modules/exchange/interfaces/exchange.interface';
import { getParams } from '@/utils/global-functions';
import { ExchangeLimit } from '@/modules/exchange/interfaces/limit.interface';

const exchange: Module<ExchangeStateInterface, any> = {
    namespaced: true,
    state: EXCHANGE_EMPTY_STATE,
    getters: {
        [ExchangeMethods.getters.GET_EXCHANGES](state): Exchange[] {
            return state.exchanges;
        },
        [ExchangeMethods.getters.GET_RATES](state): ExchangeRate[] {
            return state.rates;
        },
        [ExchangeMethods.getters.GET_RANGE_RATES](state): ExchangeRangeRate[] {
            return state.rangeRates;
        },
        [ExchangeMethods.getters.GET_RATES_UPDATED](state): boolean {
            return state.ratesUpdated;
        },
        [ExchangeMethods.getters.GET_PRE_EXCHANGE](state): PreExchange {
            return state.preExchange;
        },
        [ExchangeMethods.getters.GET_MIN_AMOUNT](state): ExchangeLimit {
            return state.buyMinAmount;
        },
        [ExchangeMethods.getters.GET_MAX_AMOUNT](state): ExchangeLimit {
            return state.buyMaxAmount;
        },
    },
    mutations: {
        [ExchangeMethods.mutations.SET_EXCHANGES](state, exchanges: Exchange[]): void {
            state.exchanges = exchanges
        }, 
        [ExchangeMethods.mutations.SET_RATES](state, rates: ExchangeRate[]): void {
            state.rates = rates
        }, 
        [ExchangeMethods.mutations.SET_RANGE_RATES](state, rangeRates: ExchangeRangeRate[]): void {
            state.rangeRates = rangeRates
        }, 
        [ExchangeMethods.mutations.SET_RATES_UPDATED](state, ratesUpdated: boolean): void {
            state.ratesUpdated = ratesUpdated
        }, 
        [ExchangeMethods.mutations.SET_PRE_EXCHANGE](state, preExchange: PreExchange): void {
            state.preExchange = preExchange
        }, 
        [ExchangeMethods.mutations.SET_MIN_AMOUNT](state, limit: ExchangeLimit): void {
            state.buyMinAmount = limit
        }, 
        [ExchangeMethods.mutations.SET_MAX_AMOUNT](state, limit: ExchangeLimit): void {
            state.buyMaxAmount = limit
        }, 
        [ExchangeMethods.mutations.RESET_EXCHANGES_DATA](state): void {
            state.exchanges = [],
            state.rates = [],
            state.rangeRates = [],
            state.ratesUpdated = false,
            state.preExchange = {
                date: null,
                active: null,
                was_expired: null,
                pre_exchange: null,
                date_last_shown: null,
                id_pre_exchange: null
            },
            state.buyMaxAmount = null,
            state.buyMinAmount = null
        }, 
    },
    actions: {
        async [ExchangeMethods.actions.SAVE_RATES]({ commit },rates: ExchangeRate[]): Promise<boolean> {
            try {
                commit(ExchangeMethods.mutations.SET_RATES,rates)
                return true;
            } catch (e) {
                return false;
            }
        },
        async [ExchangeMethods.actions.CHANGE_RATES_UPDATED]({ commit },update): Promise<boolean> {
            try {
                commit(ExchangeMethods.mutations.SET_RATES_UPDATED,update)
                return true;
            } catch (e) {
                return false;
            }
        },
        async [ExchangeMethods.actions.FETCH_EXCHANGES]({ commit },data: any): Promise<boolean> {
            try {
                let encodeData = data;
                encodeData.email_user = encodeURIComponent(encodeData.email_user)
                let params = '?'+getParams(encodeData)
                let exchanges : Exchange[] = await exchangeHttpRepository.getExchanges(params)
                if (exchanges === null) exchanges = []
                exchanges.forEach(el=>{
                    if (el.captures){
                        el.captures.forEach(cap=>{
                            if (cap.content){
                                let blob = new Blob([new Uint8Array(cap.content.data)]);
                                cap.imageSrc= window.URL.createObjectURL(blob);
                            }
                        })
                    }
                    else el.captures = [];
                })
                commit(ExchangeMethods.mutations.SET_EXCHANGES,exchanges)
                return true;
            } catch (e) {
                return false;
            }
        },
        async [ExchangeMethods.actions.FETCH_RATES]({ commit }): Promise<boolean> {
            try {
                let rates : ExchangeRate[] = await exchangeHttpRepository.getExchangeRates()
                commit(ExchangeMethods.mutations.SET_RATES,rates)
                return true;
            } catch (e) {
                return false;
            }
        },
        async [ExchangeMethods.actions.FETCH_RANGE_RATES]({ commit }): Promise<boolean> {
            try {
                let rangeRates : ExchangeRangeRate[] = await exchangeHttpRepository.getExchangeRangeRates()
                commit(ExchangeMethods.mutations.SET_RANGE_RATES,rangeRates)
                return true;
            } catch (e) {
                return false;
            }
        },
        async [ExchangeMethods.actions.SAVE_PRE_EXCHANGE]({ commit },exchange: Exchange): Promise<boolean> {
            try {
               let response : {message: string,id_pre_exchange: number,previous_id_pre_exchange: number} = await  exchangeHttpRepository.savePreExchange({exchangeData:exchange})
               if (response.message === "Pre-exchange succesfully inserted."){
                   return true
               }
               return false
            } catch (e) {
                return false
            }
        },
        async [ExchangeMethods.actions.GET_PRE_EXCHANGE]({ commit },email_user: string): Promise<boolean> {
            try {
               let exchange : PreExchange = await  exchangeHttpRepository.getPreExchange(email_user)
                if (exchange.pre_exchange.email_user === email_user){
                   commit(ExchangeMethods.mutations.SET_PRE_EXCHANGE,exchange)
                   return true
                }
               return false
            } catch (e) {
                return false
            }
        },
        async [ExchangeMethods.actions.SET_PRE_EXCHANGE]({ commit },preExchange: PreExchange): Promise<boolean> {
            try {
                   commit(ExchangeMethods.mutations.SET_PRE_EXCHANGE,preExchange)
                   return true
            } catch (e) {
                return false
            }
        },
        async [ExchangeMethods.actions.CANCEL_PRE_EXCHANGE]({ commit },preExchangeId: number): Promise<boolean> {
            try {
               let remittance :{message: string, email_user: string,pre_exchange: PreExchange} = await  exchangeHttpRepository.cancelPreExchange(preExchangeId)
                if (remittance.message === "Pre-exchange succesfully cancelled."){
                   commit(ExchangeMethods.mutations.SET_PRE_EXCHANGE,{
                        date: null,
                        active: null,
                        was_expired: null,
                        pre_exchange: null,
                        date_last_shown: null,
                        id_pre_exchange: null
                    })
                   return true
                }
               return false
            } catch (e) {
                return false
            }
        },
        async [ExchangeMethods.actions.SEND_EXCHANGE]({ commit },data:{exchange: Exchange,type: string}): Promise<{error:boolean,exchangePubID?:string,message?:string}> {
            try {
                let bodyFormData = new FormData();
                bodyFormData.append('exchange', JSON.stringify(data.exchange));
                if (data.exchange.captures[0]){
                    bodyFormData.append('firstCapture', data.exchange.captures[0].content);
                }
                if (data.exchange.captures[1])
                    bodyFormData.append('secondCapture', data.exchange.captures[1].content);
               let response : {id_exchange_pub:string,message:string,id_exchange:number,id_pre_exchange: number} = await  exchangeHttpRepository.saveExchange(bodyFormData,data.type);
               if (response.id_exchange_pub && response.message === "Exchange started"){
                   return {error: false, exchangePubID: response.id_exchange_pub}
               }
               return {error:true,message:'Ha ocurrido un error registrando su conversión'}
            } catch (e) {
                if (e.response){
                    if (e.response.data.message && e.response.data.message ==="Txid already used.")
                        return {error:true,message:'El TxId ya se encuentra registrado en otra transacción'}
                    else if (e.response.data.message && e.response.data.message === "Account has insufficient balance for requested action.")
                        return {error:true,message:'La Wallet no tiene el saldo suficiente'}
                    else if (e.response.data.msg && e.response.data.msg === "Wallet has not enough funds.")
                        return {error:true,message:'La Wallet no tiene el saldo suficiente'}
                    else if (e.response.data.msg && e.response.data.msg === "Wallet has not enough credits.")
                        return {error:true,message:'La Wallet no tiene el saldo suficiente'}
                    else if (e.response.data.msg && e.response.data.msg === "This user already has operations in progress.")
                        return {error:true,message:'Usted posee una operación pendiente por ejecutar'}
                    else if (e.response.data.message && e.response.data.message === "Operation could not be executed immediately.")
                        return {error:true,message:'Imposible ejecutar la conversión. Intente de nuevo'}
                }
                return {error:true,message:'Ha ocurrido un error registrando su conversión'}
            }
        },
        async [ExchangeMethods.actions.FETCH_LIMIT_AMOUNTS]({ commit },data: any): Promise<boolean> {
            try {
                let params = '?'+getParams(data)
               let exchange : {min: ExchangeLimit, max: ExchangeLimit} = await  exchangeHttpRepository.getExchangeLimits(params)
                if (exchange.min && exchange.max){
                   commit(ExchangeMethods.mutations.SET_MIN_AMOUNT,exchange.min)
                   commit(ExchangeMethods.mutations.SET_MAX_AMOUNT,exchange.max)
                   return true
                }
               return false
            } catch (e) {
                return false
            }
        },
    }
};

export default exchange;