import { Module } from 'vuex';
import UserMethods from '@/store/users/methods/users.methods';
import { usersHttpRepository }from '@/modules/client/repositories/users.httpRepository';
import { USERS_EMPTY_STATE } from './users.state';
import { UserStateInterface } from './interfaces/users.state.interface';
import { Account } from '@/modules/client/interfaces/account.interface';

const users: Module<UserStateInterface, any> = {
    namespaced: true,
    state: USERS_EMPTY_STATE,
    getters: {
        [UserMethods.getters.GET_USER_ACCOUNTS](state): Account[] {
            return state.accounts;
        }
    },
    mutations: {
        [UserMethods.mutations.SET_USER_ACCOUNTS](state, accounts: Account[]): void {
            state.accounts = accounts;
        },
        [UserMethods.mutations.RESET_USERS_DATA](state): void {
            state.accounts = [];
        },
    },
    actions: {
        async [UserMethods.actions.SET_USER_ACCOUNTS]({ commit }, accounts: Account[]): Promise<boolean> {
            try {
                commit(UserMethods.mutations.SET_USER_ACCOUNTS,accounts);
                return true;
            } catch (e) {
                return false;
            }
        },
        async [UserMethods.actions.FETCH_USER_ACCOUNTS]({ commit }, email: string): Promise<boolean> {
            try {
                let accounts: Account[] = await usersHttpRepository.getUserAccounts(email);
                accounts.forEach(el => {
                    el.selected=false;
                })
                commit(UserMethods.mutations.SET_USER_ACCOUNTS,accounts);
                return true;
            } catch (e) {
                return false;
            }
        },
        async [UserMethods.actions.INSERT_USER_ACCOUNTS]({ commit }, data:{email_user:string,account: Partial<Account>}): Promise<{account: Account,error:boolean,msg:string}> {
            try {
                let account: Account = await usersHttpRepository.insertUserAccount(data.email_user,data.account)
                if (account.id_user_account){
                    account.selected = false;
                    return {account:account,error:false,msg:'Su cuenta ha sido creada exitosamente'};
                }else return null
            } catch (e) {
                if (e.response){
                    if (e.response.data.msg === "Limit exceeded")
                    return  {account:null,error:true,msg:'Ha exedido el límite de cuentas permitidas'};
                }
                return  {account:null,error:true,msg:'Ha ocurrido un error al crear la cuenta'};
            }
        },
        async [UserMethods.actions.DELETE_USER_ACCOUNTS]({ commit }, userAccount: number): Promise<boolean> {
            try {
                let response: {message: string} = await usersHttpRepository.deleteUserAccount(userAccount)
                if (response.message === "User account successfully deleted") return true
                else return true;
            } catch (e) {
                return false;
            }
        },
        async [UserMethods.actions.VALIDATE_CODE]({ commit }, data:{ident_user: string, code: string}): Promise<{ident_user: string, msg:string}> {
            try {
                let response: {ident_user: string, msg:string} = await usersHttpRepository.validateCode(data)
                // if (account.id_user_account){
                //     account.selected = false;
                //     return {account:account,error:false,msg:'Su cuenta ha sido creada exitosamente'};
                // }else return null
                return response
            } catch (e) {
                if (e.response && e.response.data) return e.response.data
                else return null
                // if (e.response){
                //     if (e.response.data.msg === "Limit exceeded")
                //     return  {account:null,error:true,msg:'Ha exedido el límite de cuentas permitidas'};
                // }
                // return  {account:null,error:true,msg:'Ha ocurrido un error al crear la cuenta'};
            }
        },
    } 
};

export default users;
