
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
/* CONSTANTS */
/* INTERFACES */
/* FUNCTIONS */
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';

@Component({
    components:{
        SvgIcon,
    }
})
export default class RemittanceTypePopup extends Vue {
    @Prop() showModal!: boolean;
    show: boolean = false;

    loading: boolean = false;
    sendRemittanceModal = false;

    /* MODAL */
        @Watch('showModal')
        async renderModal(){
            this.show = this.showModal
            this.loading = true;
            this.loading = false;
        }

        @Watch('show')
        sendToParent(newVal: boolean){
            this.$emit('changeModalStatus', newVal)
        }
    /* END MODAL */

    openSecondModal(){
        this.$emit('openSecondModal');
        this.show = false;
    }

}

