
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* STORE */
import { auth, notifications, balances, remittance, countries ,exchange, verifLevels} from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import NotificationsMethods from '@/store/notifications/methods/verificationLevels.methods';
import RemittanceMethods from '@/store/remittance/methods/remittance.methods';
import BalancesMethods from '@/store/balances/methods/balances.methods';
import CountriesMethods from '@/store/countries/methods/countries.methods'
import ExchangeMethods from '@/store/exchange/methods/exchange.methods'
import VerifLevelMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
/* INTERFACES */
import { MigratedData } from '@/modules/verificationLevels/interfaces/migrated.interface';
import { User } from '@/modules/auth/interfaces/user.interface';
import { PreExchange } from '@/modules/exchange/interfaces/exchange.interface';
import { Socket } from 'vue-socket.io-extended'
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { Notif } from '@/modules/notifications/interfaces/notification.interface';
import { dateToDDMMYYY, epochDateToDDMMYYYAndHour } from '@/utils/date-functions';
import NotificationPanel from '@/modules/notifications/components/NotificationPanel.vue';
import { finishSession, getCategoryColor } from '@/modules/auth/functions/functions';
import {getStatusName, getStatusColor, getStatusIcon} from '@/modules/verificationLevels/functions/verifLevels.functions'
import { capitalize, showToast } from '@/utils/global-functions';
import { verifLevelsNames } from '@/modules/verificationLevels/functions/verifLevels.functions';
import Loader from '@/components/utilities/Loader.vue';
import SendRemittancePopup from '@/modules/remittance/components/sendRemittance/popups/SendRemittancePopup.vue'
import RemittanceTypePopup from '@/modules/remittance/components/sendRemittance/popups/RemittanceTypePopup.vue'
import NoRemittancePopup from '@/modules/verificationLevels/components/NoRemittancePopup.vue'
import { PreRemittance } from '@/modules/remittance/interfaces/preRemittance.interface';
import InfoModal from '@/components/utilities/InfoModal.vue'
import ConfirmationModal from '@/components/utilities/ConfirmationModal.vue';
import { NewRemittance } from '@/modules/remittance/interfaces/newRemittance.interface';
import { Rate } from '@/modules/rates/interfaces/rate.interface';
import { Balance, BalanceEquivalentAmount } from '@/modules/balances/interfaces/balance.interface';
import { CountryCurrency } from '@/modules/countries/interfaces/countries.interface';
import store from '@/store';
import { ambassadorLinkAvailable } from '@/modules/ambassador/functions/ambassador.functions';
import { CRYPTO_CURRENCIES_LIST,CRYPTO_CURRENCIES } from '@/modules/currencies/constants/currencies.constants';
import { REMITTANCE_TYPES } from '@/modules/remittance/constants/remittance.constants';
import {NO_REMITTANCE_COUNTRIES} from '@/modules/countries/constants/noRemittanceCountries.constants'
/* EXTRA REPOSITORIES */
import {currencyFreaksRepository} from '@/modules/currencies/repositories/currencyFreaks.httpRepository';
import {CURRENCY_FORMAT_CONFIG} from '@/modules/currencies/constants/currencies.constants';

@Component({
    components: {
        SvgIcon,
        NotificationPanel,
        Loader,
        RemittanceTypePopup,
        SendRemittancePopup,
        InfoModal,
        ConfirmationModal,
        NoRemittancePopup
    },
})
export default class Header extends Vue {
    link : {name: string} = {name: ''}
    links = {
      firstLevel : 'LevelOne',
      secondLevel : 'LevelTwo'
    }
    $refs: any = {}
    sendRemittanceModal = false;
    sendRemittanceModal2 = false;
    remittanceTypeModal = false;
    noRemittanceModal = false;
    expiredPreRemittanceInfoModal= {
        title: 'Ha culminado el tiempo límite para subir el comprobante de pago. Si ya realizó la transferencia, debe iniciar una nueva operación indicando que YA posee el comprobante',
        open: false
    }
    expiredPreExchangeInfoModal= {
        title: 'Ha culminado el tiempo límite para subir el comprobante de pago de su Compra. Comuníquese con Atención al Cliente',
        open: false
    }
    remittanceInProcessInfoModal= {
        title: 'Usted ha iniciado una operación en los pasados 15 minutos y no ha anexado su comprobante de pago. Si desea completarla, presione "Completar operación", si desea cancelar su operación anterior, presione "Cancelar".',
        open: false
    }
    deleteConfirmationModal = false;
    balanceSideBar = false;
    menuSideBar = false;
    loading = true;
    usdtToCryptoRates:{date:string,base:string,rates:{}}= null;
    cryptoCurrenciesList = CRYPTO_CURRENCIES;
    currenciesFormatConfig = CURRENCY_FORMAT_CONFIG;

    /* MODAL */
    remittanceTypeModalStatus(newVal: boolean){
      this.remittanceTypeModal = newVal
    }
    sendRemittanceModalStatus(newVal: boolean) {
        this.sendRemittanceModal = newVal;
    }
    noRemittanceModalStatus(newVal: boolean){
      this.noRemittanceModal = newVal;
    }
    openSecondModal(){
    //  this.sendRemittanceModal = true;
           if (this.$route.name !== 'SendRemittance' || (this.$route.name === 'SendRemittance' && this.$route.params.modo !== REMITTANCE_TYPES.NO_CAPTURE))
            this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.NO_CAPTURE}`}).catch();
    }
    expiredPreRemittanceModalStatus(newVal: boolean){
        this.expiredPreRemittanceInfoModal.open = newVal;
    }
    expiredPreExchangeModalStatus(newVal: boolean){
        this.expiredPreExchangeInfoModal.open = newVal;
    }
    remittanceInProcessModalStatus(newVal: boolean){
        this.remittanceInProcessInfoModal.open = newVal;
    }
    deleteConfirmationModalStatus(newVal: boolean){
        this.deleteConfirmationModal = newVal;
    }
    /* END MODAL */

    get verifLevelsNames(){
      return verifLevelsNames(this.user.id_verif_level)
    }

    get isUncompletedMigratedUser(){
        // DESCOMENTAR CUANDO SE HAGA EL FLUJO DE MIGRADOS
        // return this.userData.id_migrated && !this.userData.completed_information_migrated
        return false
    }

    @Socket()
    connect(){
       // this.$socket.client.emit('new_connection', this.user.email_user)
    }

    onLevelUpgrade(body : {id_verif_level : number, verif_level_apb: false, email_user: string,wallets?: any, noti: Notif[],new_cod_pub: string,new_name_profile?: string}) {
      if (body.email_user === this.user.email_user){
        if (body.id_verif_level)
          this.setVerifLevel({verif_level: body.id_verif_level, verif_level_apb: body.verif_level_apb})
        if (body.new_cod_pub){ 
          this.user.cust_cr_cod_pub = body.new_cod_pub;
          store.state.auth.user.cust_cr_cod_pub = body.new_cod_pub
        }
        if (body.wallets){
          this.user.wallets = body.wallets;
          store.state.auth.user.wallets = body.wallets;
        }
        if (body.new_name_profile){
          this.user.name_profile = body.new_name_profile
          store.state.auth.user.name_profile = body.new_name_profile
        }
        if (body.noti){
          let newNotification = body.noti[0];
          newNotification.notification_date = epochDateToDDMMYYYAndHour(new Date(newNotification.date_creation * 1000))
          this.notifications.unshift(newNotification)
        }
        if (ambassadorLinkAvailable(this.user)){
            this.saveAmbassadorLink(this.user)
        }
      }
    }

    async onExpiredPreRemittance(data: {pre_remittance:PreRemittance,email_user: string, id_pre_remittance: number,message: string}){
      if (data.email_user === this.user.email_user){ 
        await this.setPreRemittance(data.pre_remittance);
        this.expiredPreRemittanceInfoModal.open = true;
        this.$router.push({name: 'Dashboard'}).catch();;
      }
    }
    async onExpiredPreExchange(data: {pre_exchange:PreExchange,email_user: string, id_pre_exchange: number,message: string}){
      if (data.email_user === this.user.email_user){ 
        await this.setPreExchange(data.pre_exchange);
        this.expiredPreExchangeInfoModal.open = true;
        this.$router.push({name: 'Dashboard'}).catch();;
      }
    }

  //  async onRateChange(rates: Rate[]){
  //   await this.saveRates({rates: rates,firstCall:false})
  //  }
    async onLoginAttemp(data:{email_user: string}){
        if (data.email_user === this.user.email_user){
          await finishSession()
          this.$router.push({name: 'ActiveSessionDetected'}).catch();
        }
    }

    created(){ 
       
       this.$socket.client.emit('new_connection', this.user.email_user) 
       this.$socket.client.on('level_upgrade',this.onLevelUpgrade)
       this.$socket.client.on('expired_remittance',this.onExpiredPreRemittance)
       this.$socket.client.on('expired_exchange',this.onExpiredPreExchange)
       this.$socket.client.on('login_attempt',this.onLoginAttemp)
    }

    async mounted(){
        await this.fetchNotifications(this.user.email_user);
        await this.fetchPreRemittance(this.user.email_user);
        await this.fetchPreExchange(this.user.email_user)
        await this.fetchBalances(this.user.email_user);
        this.usdtToCryptoRates = await currencyFreaksRepository.getCurrenciesRatesByBase(CRYPTO_CURRENCIES.USDT,this.cryptoCurrenciesISOCodes);
        this.loading = false;
        if (this.isAuthenticated){
            this.link = {name: 'Dashboard'};
        }else this.link = {name: 'Login'};
    }

    async logOut() {
        this.$refs.loader.showLoader();
        if (await this.logout(this.user.email_user)){
           this.$router.push({name: 'LogOut', params:{title: '¿Ya te vas?',subtitle:'Gracias por ingresar a Bithonor, ¡hasta la próxima!'}}).catch();
        }    
        this.$refs.loader.hideLoader();
    }

    capitalizeText(text: string){
      return capitalize(text)
    }
//iso_code_resid_country
    sendToRemittance(){
      if (this.hasMigratedException || this.user.id_verif_level === 1 && this.user.verif_level_apb === null) showToast('Esperando aprobación para verificación de usuario','warning')
      else if (((this.user.id_verif_level === 1 && this.user.verif_level_apb === true) || this.user.id_verif_level > 1)){
        if (!this.preRemittance.id_pre_remittance){
          // this.remittanceTypeModal = true;
          this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.NO_CAPTURE}`}).catch();
        }
        else if (this.preRemittance.active && !this.preRemittance.was_expired)
          {
            this.remittanceInProcessInfoModal.open = true;
          }
        else
        this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.NO_CAPTURE}`}).catch();
        //  this.remittanceTypeModal = true;
      }else this.noRemittanceModal = true;
    }

    get cannotSendRemittance(){
      return NO_REMITTANCE_COUNTRIES.includes(this.user.iso_code_resid_country)
    }

    async confirmPreRemittanceSave(){
        
    }


    async cancelPreRemitanceConfirmed(){
        this.deleteConfirmationModal = false;
        this.$refs.loader.showLoader();
        if (await this.cancelPreRemitance(this.preRemittance.id_pre_remittance))
            showToast('Operación cancelada exitosamente','success')
        else showToast('Ha ocurrido un error cancelando la operación','success')
        this.$refs.loader.hideLoader();
    }

    async completePreRemittance(){
         this.remittanceInProcessInfoModal.open = false;
        //  await this.setRemittance(this.preRemittance.pre_remittance);
        this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.NO_CAPTURE}`}).catch();;
    }

    cancelPreRemittanceConfirmation(){
        this.remittanceInProcessInfoModal.open = false;
        this.deleteConfirmationModal = true;
    }

    getEquivalentInDestinyCurrency(equivalents: BalanceEquivalentAmount[]){
      // globalDestinyCountryCurrency
      let equivalent = equivalents.find(el=> el.currency_iso_code === this.globalDestinyCountryCurrency.iso_cod)
      if (equivalent && equivalent.amount){
        return '≈ '+this.$options.filters.currency(equivalent.amount) + ` ${equivalent.currency_iso_code}`
      }
      else return null
    }

    get badgeName(){
        return getStatusName(this.user.verif_level_apb);
    }

    get badgeColor(){
        return getStatusColor(this.user.verif_level_apb);
    }

    get badgeIcon(){
        return getStatusIcon(this.user.verif_level_apb);
    }

    get categoryColor(){
        return getCategoryColor(this.user.cod_rank);
    }

    /* CURRENCIES */

        get cryptoCurrenciesISOCodes(){
            return this.cryptoBalance.map(el=> el.currency_iso_code).join(',')
        }

    /* END CURRENCIES */


  goToWholesalePartnerSection(){
    if (this.$route.name !== 'WholeSalePartner' && this.$route.name !== 'WholeSalePartnerDashboard'){
      if (this.user.wholesale_partner_info) this.$router.push({name: 'WholeSalePartnerDashboard'}).catch()
      else this.$router.push({name: 'WholeSalePartner'}).catch()
    }
  }



  @auth.Getter(AuthMethods.getters.GET_AUTHENTICATION)
     isAuthenticated!: boolean;
  @auth.Getter(AuthMethods.getters.HAS_MIGRATED_EXCEPTION)
    hasMigratedException!: boolean;
  @auth.Action(AuthMethods.actions.LOGOUT)
    logout!: (email_user: string) => Promise<boolean>
  @auth.Action(AuthMethods.actions.SET_NEW_VERIF_LEVEL)
    setVerifLevel!:(data:{verif_level: number, verif_level_apb: boolean}) => void
  @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    user!: User;
  @auth.Action(AuthMethods.actions.SAVE_AMBASSADOR_LINK)
  saveAmbassadorLink!: (user: User) => void
  @notifications.Action(NotificationsMethods.actions.FETCH_NOTIFICATIONS)
    fetchNotifications!: (email_user: string) => Promise<boolean>
  @notifications.Getter(NotificationsMethods.getters.GET_NOTIFICATIONS)
    notifications!: Notif[]
  @remittance.Action(RemittanceMethods.actions.SET_PRE_REMITTANCE)
    setPreRemittance:(preRemittance: PreRemittance) => Promise<boolean>
  @remittance.Getter(RemittanceMethods.getters.GET_PRE_REMITTANCE)
    preRemittance!: PreRemittance;
  @remittance.Action(RemittanceMethods.actions.CANCEL_PRE_REMITTANCE)
    cancelPreRemitance!:(pre_remittance_id: number)=> Promise<boolean>
  @remittance.Action(RemittanceMethods.actions.SET_REMITTANCE)
    setRemittance!:(remittance: NewRemittance)=> Promise<boolean>
  @remittance.Action(RemittanceMethods.actions.GET_PRE_REMITTANCE)
    fetchPreRemittance!: (email_user: string)=> Promise<boolean>
    // @rates.Action(RatesMethods.actions.SAVE_ALL_RATES)
    // saveRates!:(data:{rates: Rate[],firstCall:boolean})=> Promise<boolean>
  /* BALANCES */
  @balances.Action(BalancesMethods.actions.FETCH_BALANCE_BY_DESTINY_COUNTRY)
  fetchBalances!: (email_user: string) => Promise<boolean>
  @balances.Getter(BalancesMethods.getters.GET_FIAT_BALANCE)
  fiatBalance!: Balance[];
  @balances.Getter(BalancesMethods.getters.GET_CRYPTO_BALANCE)
  cryptoBalance!: Balance[];
  /* COUNTRIES */
  @countries.Getter(CountriesMethods.getters.GET_GLOBAL_DESTINY_COUNTRY_CURRENCY)
  globalDestinyCountryCurrency!: CountryCurrency;
  /* EXCHANGE */
  @exchange.Action(ExchangeMethods.actions.GET_PRE_EXCHANGE)
  fetchPreExchange!: (email_user: string) => Promise<boolean>
  @exchange.Action(ExchangeMethods.actions.SET_PRE_EXCHANGE)
  setPreExchange!: (preExchange: PreExchange) => Promise<boolean>
  /* VERIF LEVELS */
  @verifLevels.Getter(VerifLevelMethods.getters.GET_MIGRATED_LEVEL_ONE_DATA)
    migratedData!: MigratedData
}
