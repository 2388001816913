import { ReqLevelOne, ReqLevelTwo } from "../interfaces/requests.interface";

export const LEVEL_ONE_REQUEST_EMPTY_STATE : ReqLevelOne ={
    date_birth: {
        value: '',
        rejected: null
    },
    id_nationality_country: {
        value: undefined,
        rejected: null
    },
    main_phone: {
        value: '',
        rejected: null
    },
    main_phone_code: {
        value: '',
        rejected: null
    },
    main_phone_full: {
        value: '',
        rejected: null
    },
    gender: {
        value: '',
        rejected: null
    },
    state_name: {
        value: '',
        rejected: null
    },
    resid_city: {
        value: '',
        rejected: null
    },
    pol_exp_per: {
        value: null,
        rejected: null
    },
    truthful_information: {
        value: null,
        rejected: null
    },
    lawful_funds: {
        value: null,
        rejected: null
    },
    ok_legal_terms: {
        value: null,
        rejected: null
    },
    email_user: {
        value: '',
        rejected: null
    },
    id_country: {
        value: null,
        rejected: null
    },
    id_ident_doc_type: {
        value: null,
        rejected: null
    },
    ident_doc_number: {
        value: '',
        rejected: null
    },
    occupation: {
        value: '',
        rejected: null
    },
    doc: {
        value: undefined,
        rejected: null
    },
    selfie: {
        value: undefined,
        rejected: null
    },
    main_sn_platf: {
        value: '',
        rejected: null
    },
    user_main_sn_platf: {
        value: '',
        rejected: null
    },
    domicile_address:{
        value: '',
        rejected: null
    },
    new_password:{
        value: '',
        rejected: null
    },
    new_email:{
        value: '',
        rejected: null
    },
    migrated: false,
    residCountry: null
}

export function resetLevelOneRequest(obj: ReqLevelOne){
    obj.date_birth= {
        value: '',
        rejected: null
    };
    obj.id_nationality_country= {
        value: undefined,
        rejected: null
    };
    obj.main_phone= {
        value: '',
        rejected: null
    };
    obj.main_phone_code= {
        value: '',
        rejected: null
    };
    obj.main_phone_full= {
        value: '',
        rejected: null
    };
    obj.gender= {
        value: '',
        rejected: null
    };
    obj.state_name= {
        value: '',
        rejected: null
    };
    obj.resid_city= {
        value: '',
        rejected: null
    };
    obj.pol_exp_per= {
        value: null,
        rejected: null
    };
    obj.truthful_information= {
        value: null,
        rejected: null
    };
    obj.lawful_funds= {
        value: null,
        rejected: null
    };
    obj.ok_legal_terms= {
        value: null,
        rejected: null
    };
    obj.email_user= {
        value: '',
        rejected: null
    };
    obj.id_country= {
        value: null,
        rejected: null
    };
    obj.id_ident_doc_type= {
        value: null,
        rejected: null
    };
    obj.ident_doc_number= {
        value: '',
        rejected: null
    };
    obj.occupation= {
        value: '',
        rejected: null
    };
    obj.doc= {
        value: undefined,
        rejected: null
    };
    obj.selfie= {
        value: undefined,
        rejected: null
    };
    obj.main_sn_platf= {
        value: '',
        rejected: null
    };
    obj.user_main_sn_platf= {
        value: '',
        rejected: null
    };
    obj.domicile_address= {
        value: '',
        rejected: null
    };
    obj.new_password= {
        value: '',
        rejected: null
    };
    obj.new_email= {
        value: '',
        rejected: null
    };
    obj.migrated= false;
    obj.residCountry= null
    return obj
}

export const LEVEL_TWO_REQUEST_EMPTY_STATE : ReqLevelTwo = {
    email_user: {
        value: 'string'
    },
    funds_source: {
        value: [],
        rejected: null
    },
    residency_proof: {
        value: undefined,
        rejected: null
    },
    job_title: {
        value: '',
        rejected: null
    },
    account_uses:{
        value: [],
        rejected: null
    },
    monthly_amount:{
        value: [],
        rejected: null
    },
    work_industry:{
        value: [],
        rejected: null
    },
    other_industry:{
        value: '',
        rejected: null
    },
    answers: []
} 

export function resetLevelTwoRequest(obj: ReqLevelTwo) {
    obj.email_user= {
        value: 'string'
    };
    obj.funds_source= {
        value: [],
        rejected: null
    };
    obj.residency_proof= {
        value: undefined,
        rejected: null
    };
    obj.job_title= {
        value: '',
        rejected: null
    };
    obj.account_uses= {
        value: [],
        rejected: null
    };
    obj.monthly_amount= {
        value: [],
        rejected: null
    };
    obj.work_industry= {
        value: [],
        rejected: null
    };
    obj.other_industry= {
        value: '',
        rejected: null
    };
    obj.answers= []
    return obj
}