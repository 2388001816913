import { AxiosRepository } from '@/http/axios.repository';
import { Message } from '../interfaces/message.interface';

const CHAT_RESOURCE = 'chat';
const MESSAGES_RESOURCE = 'message';


export class ChatRepository extends AxiosRepository {
    
    async sendMessage(data: any): Promise<{message:string}> {
        return await super.postData(data,CHAT_RESOURCE+'/'+MESSAGES_RESOURCE);
    }

    async getMessages(emailUser: string): Promise<Message[]> {
        return await super.get(emailUser,CHAT_RESOURCE+'/'+MESSAGES_RESOURCE);
    }

}

export const chatHttpRepository = new ChatRepository();