import { AxiosRepository } from '@/http/axios.repository';
import { User } from '@/modules/auth/interfaces/user.interface';
import { CryptoMile } from '../interfaces/cryptomile.interface';
import { ReferralOperation } from '../interfaces/referralOperation.interface';
import { ReferralsByCountry, ReferralsByStatus } from '../interfaces/reports.interfaces';

const USERS_RESOURCE = 'users';
const REFERRALS_RESOURCE = '/referrals';
const OPERATIONS_RESOURCE = '/operations';
const AMBASSADOR_RESOURCE = '/ambassador';
const CRYPTOMILES_RESOURCE = '/cryptomiles';

export class AmbassadorsHttpRepository extends AxiosRepository {

    async getReferrals(params: string): Promise<{referrals: User[], total_referrals: number}> { 
        return super.getAll(USERS_RESOURCE+REFERRALS_RESOURCE+params);
    }
    async getReferralsOperations(email_user: string): Promise<{total_operations: number, operations: ReferralOperation[]}> { 
        return super.getAll(USERS_RESOURCE+`/${email_user}`+REFERRALS_RESOURCE+OPERATIONS_RESOURCE);
    }
    async getReferralsByCountries(email_user: string): Promise<ReferralsByCountry[]> { 
        return super.getAll(USERS_RESOURCE+`/${email_user}`+REFERRALS_RESOURCE+'/totalByCountry');
    }
    async getReferralsByStatus(email_user: string): Promise<ReferralsByStatus[]> { 
        return super.getAll(USERS_RESOURCE+`/${email_user}`+REFERRALS_RESOURCE+'/totalByStatus');
    }
    async sendAmbassadorRequest(email_user: string): Promise<{mailResp: string}> { 
        return super.post({},USERS_RESOURCE+`/${email_user}`+AMBASSADOR_RESOURCE+'/request');
    }
    async getCryptomiles(email_user: string): Promise<CryptoMile[]> { 
        return super.get(email_user,CRYPTOMILES_RESOURCE);
    }
    
}

export const ambassadorsHttpRepository = new AmbassadorsHttpRepository();