export default {
    mutations: {
        SET_EXCHANGES: 'SET_EXCHANGES',        
        SET_RATES: 'SET_RATES',        
        SET_RANGE_RATES: 'SET_RANGE_RATES',
        SET_RATES_UPDATED: 'SET_RATES_UPDATED',
        SET_PRE_EXCHANGE: 'SET_PRE_EXCHANGE',
        SET_MIN_AMOUNT: 'SET_MIN_AMOUNT',
        SET_MAX_AMOUNT: 'SET_MAX_AMOUNT',
        RESET_EXCHANGES_DATA: 'RESET_EXCHANGES_DATA',
    },
    getters: {
        GET_EXCHANGES: 'GET_EXCHANGES',        
        GET_RATES: 'GET_RATES',        
        GET_RANGE_RATES: 'GET_RANGE_RATES',
        GET_RATES_UPDATED: 'GET_RATES_UPDATED',
        GET_PRE_EXCHANGE: 'GET_PRE_EXCHANGE',
        GET_MIN_AMOUNT: 'GET_MIN_AMOUNT',
        GET_MAX_AMOUNT: 'GET_MAX_AMOUNT',
    },
    actions: {
        FETCH_EXCHANGES: 'FETCH_EXCHANGES',        
        FETCH_RATES: 'FETCH_RATES',        
        FETCH_RANGE_RATES: 'FETCH_RANGE_RATES',
        SAVE_RATES: 'SAVE_RATES',
        CHANGE_RATES_UPDATED: 'CHANGE_RATES_UPDATED',
        SAVE_PRE_EXCHANGE: 'SAVE_PRE_EXCHANGE',
        GET_PRE_EXCHANGE: 'GET_PRE_EXCHANGE',
        SET_PRE_EXCHANGE: 'SET_PRE_EXCHANGE',
        CANCEL_PRE_EXCHANGE: 'CANCEL_PRE_EXCHANGE',
        SEND_EXCHANGE: 'SEND_EXCHANGE',
        FETCH_LIMIT_AMOUNTS: 'FETCH_LIMIT_AMOUNTS',
    },
};