import { AxiosRepository } from '@/http/axios.repository';
import { OperationRoute } from '../interfaces/route.interface';

const ROUTES_RESOURCE = 'operation_routes';

export class RoutesRepository extends AxiosRepository {
    
    async getOperationRoutes(): Promise<OperationRoute[]> {
        return await super.getAll(ROUTES_RESOURCE);
    }

}

export const routesHttpRepository = new RoutesRepository();