import store from "@/store";
import {VERIF_LEVELS_EMPTY_STATE} from '@/store/verificationLevels/verificationLevels.state'
import {REMITTANCE_EMPTY_STATE} from '@/store/remittance/remittance.state'
import {RATES_EMPTY_STATE} from '@/store/rates/rates.state'
import {PAY_METHODS_EMPTY_STATE} from '@/store/payMethods/payMethods.state'
import {NOTIFICATIONS_EMPTY_STATE} from '@/store/notifications/notifications.state'
import {DOC_TYPE_EMPTY_STATE} from '@/store/docTypes/docTypes.state'
import {CURRENCIES_EMPTY_STATE} from '@/store/currencies/currencies.state'
import {COUNTRIES_EMPTY_STATE} from '@/store/countries/countries.state'
import {CHAT_EMPTY_STATE} from '@/store/chat/chat.state'
import {BENEFICIARIES_EMPTY_STATE} from '@/store/beneficiaries/benficiaries.state'
import {BANKS_EMPTY_STATE} from '@/store/banks/banks.state'
import {BALANCES_EMPTY_STATE} from '@/store/balances/balances.state'
import {AUTH_EMPTY_STATE} from '@/store/auth/auth.state'
import {AMBASSADORS_EMPTY_STATE} from '@/store/ambassadors/ambassadors.state'

export function cleanStore(){
    // store.state.verifLevels = Object.assign({},VERIF_LEVELS_EMPTY_STATE)
    store.state.remittance = Object.assign({},REMITTANCE_EMPTY_STATE)
    store.state.rates = Object.assign({},RATES_EMPTY_STATE)
    store.state.payMethods = Object.assign({},PAY_METHODS_EMPTY_STATE)
    store.state.notifications = Object.assign({},NOTIFICATIONS_EMPTY_STATE)
    store.state.docTypes = Object.assign({},DOC_TYPE_EMPTY_STATE)
    store.state.currencies = Object.assign({},CURRENCIES_EMPTY_STATE)
    store.state.countries = Object.assign({},COUNTRIES_EMPTY_STATE)
    store.state.chat = Object.assign({},CHAT_EMPTY_STATE)
    store.state.beneficiares = Object.assign({},BENEFICIARIES_EMPTY_STATE)
    store.state.banks = Object.assign({},BANKS_EMPTY_STATE)
    store.state.balances = Object.assign({},BALANCES_EMPTY_STATE)
    // store.state.auth = Object.assign({},AUTH_EMPTY_STATE)) // NO, notAuthenticatedUUid connot be deletable
    store.state.ambassadors = Object.assign({},AMBASSADORS_EMPTY_STATE)
}
