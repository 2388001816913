import { AxiosRepository } from '@/http/axios.repository';

const LOGIN_RESOURCE = 'login';
const LOGOUT_RESOURCE = 'logout';
const USERS_RESOURCE = 'users'
const CREATE_CLIENT_RESOURCE = 'users/createNewClient';
const VERIF_CLIENT_RESOURCE = 'users/approveLevelCero';
const SEND_RECOVER_PASS_EMAIL_RESOURCE = 'users/forgotPassword'
const CHANGE_PASSWORD_RESOURCE = 'users/newPassword'
const SEND_SIGNUP_VERIFICATION_RESOURCE_EMAIL = 'users/sendVerificationCodeByEmail'
const SEND_SIGNUP_VERIFICATION_RESOURCE_SMS = 'users/sendVerificationCodeBySMS'
const SEND_SIGNUP_VERIFICATION_RESOURCE_WHATSAPP = 'users/sendVerificationCodeByWhatsApp'
const LEVEL_QUESTIONS_RESOURCE = 'users/getLevelQuestions'
const VERIFY_IDENT_USER_EXISTENCE = 'users/verifyIdentUser'
const DEACTIVATE_USER = 'users/deactivateUser'

export class AuthRepository extends AxiosRepository {
    
    async login(data: any): Promise<any> { 
        return super.post(data,LOGIN_RESOURCE);
    }

    async logout(email_user: string): Promise<any> { 
        return super.get(email_user,LOGOUT_RESOURCE);
    }

    async verifyIdentUserExistence(data: {email_user?: string, phone_number?: string}): Promise<{message: string}>{
        return super.post(data,VERIFY_IDENT_USER_EXISTENCE)
    }

    async verifyIdentUserExistenceExceptThemself(data: { phone_number: string, except: string }): Promise<{ message: string }>
    {
        console.log('data antes de enviar',data);
        return super.post(data,VERIFY_IDENT_USER_EXISTENCE)
    }

    async deactivateUser(data:{email_user: string}): Promise<{message: string}>{
        return super.post(data,DEACTIVATE_USER)
    }

    async createClient(data: any): Promise<any> { 
        return super.post(data,CREATE_CLIENT_RESOURCE);
    }

    async verifClient(email : string): Promise<any>{
        return super.get(email,VERIF_CLIENT_RESOURCE);    
    }

    async sendRecoverPasswordEmail(data: {email_user: string}): Promise<any>{
        return super.post(data,SEND_RECOVER_PASS_EMAIL_RESOURCE)
    }

    async sendRegisterVerificationEmail(data: {email_user: string, id_resid_country: string | number,first_name:string,last_name:string}): Promise<any>{
        return super.post(data,SEND_SIGNUP_VERIFICATION_RESOURCE_EMAIL)
    }

    async sendRegisterVerificationSms(data: {main_phone_full: string,first_name:string,last_name:string}): Promise<any>{
        return super.post(data,SEND_SIGNUP_VERIFICATION_RESOURCE_SMS)
    }

    async sendRegisterVerificationWhatsapp(data: {main_phone_full: string,first_name:string,last_name:string}): Promise<any>{
        return super.post(data,SEND_SIGNUP_VERIFICATION_RESOURCE_WHATSAPP)
    }

    async fetchLevelQuestions(idResidCountry: number): Promise<any>{
        return super.get(idResidCountry,LEVEL_QUESTIONS_RESOURCE)
    }

    async changePassword(data: {email_user: string, new_password: string}): Promise<any>{
        return super.post(data,CHANGE_PASSWORD_RESOURCE)
    }

    async isReferrer(cust_cr_cod_pub: string ): Promise<any>{
        return super.head(USERS_RESOURCE+'/referrers/'+cust_cr_cod_pub)
    }

    async protectedRoute(){
        return super.getAll('protected-route')
    }
}

export const authHttpRepository = new AuthRepository();