import axios from "axios";


export  class ApiFyRepository {
    private host = 'https://api.ipify.org?format=json';

    public async get(): Promise<{ip: string}> {
        return (await axios.get(this.host)).data;
    }

}

export const apifyHttpRepository = new ApiFyRepository();
