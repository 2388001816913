export default {
    mutations: {
        SET_USER_DATA: 'SET_USER_DATA',
        SET_AMBASSADOR_LINK: 'SET_AMBASSADOR_LINK',
        SET_REGISTER_USER_DATA: 'SET_REGISTER_USER_DATA',
        SET_AUTH: 'SET_AUTH',
        SET_COUNTRY_PHONE_CODE: 'SET_COUNTRY_PHONE_CODE',
        SET_ATC_PHONE: 'SET_ATC_PHONE',
        SET_LEVELS_QUESTIONS: 'SET_LEVELS_QUESTIONS',
        CLEAR_AUTH_STATE: 'CLEAR_AUTH_STATE',
        SET_SELECTED_DOC_TYPE: 'SET_SELECTED_DOC_TYPE',
        SET_SELECTED_COUNTRY_DOC: 'SET_SELECTED_COUNTRY_DOC',
        SET_NEW_VERIF_LEVEL:'SET_NEW_VERIF_LEVEL',
        SET_REGISTER_PUBLIC_CODE:'SET_REGISTER_PUBLIC_CODE',
        SET_NOT_AUTHENTICATED_UUID:'SET_NOT_AUTHENTICATED_UUID',
        SET_PUBLIC_IP:'SET_PUBLIC_IP',
        SET_WHOLESALE_PARTNER_INFO:'SET_WHOLESALE_PARTNER_INFO',
        RESET_AUTH_DATA:'RESET_AUTH_DATA',
    },
    getters: {
        GET_USER_DATA: 'GET_USER_DATA',
        GET_AMBASSADOR_LINK: 'GET_AMBASSADOR_LINK',
        GET_REGISTER_USER_DATA: 'GET_REGISTER_USER_DATA',
        GET_MAIN_MENU: 'GET_MAIN_MENU',
        GET_CLIENT_BY_ID: 'GET_CLIENT_BY_ID',
        GET_COUNTRY_PHONE_CODE: 'GET_COUNTRY_PHONE_CODE',
        GET_SELECTED_DOC_TYPE: 'GET_SELECTED_DOC_TYPE',
        GET_SELECTED_COUNTRY_DOC: 'GET_SELECTED_COUNTRY_DOC',
        GET_ATC_PHONE: 'GET_ATC_PHONE',
        GET_AUTHENTICATION: 'GET_AUTHENTICATION',
        GET_LEVELS_QUESTIONS: 'GET_LEVELS_QUESTIONS',
        GET_REGISTER_PUBLIC_CODE: 'GET_REGISTER_PUBLIC_CODE',
        GET_NOT_AUTHENTICATED_UUID:'GET_NOT_AUTHENTICATED_UUID',
        GET_PUBLIC_IP:'GET_PUBLIC_IP',
        GET_WHOLESALE_PARTNER_INFO:'GET_WHOLESALE_PARTNER_INFO',
        HAS_MIGRATED_EXCEPTION:'HAS_MIGRATED_EXCEPTION',
    },
    actions: {
        LOGIN: 'LOGIN',
        LOGOUT: 'LOGOUT',
        SIGNUP_USER: 'SIGNUP_USER',
        FETCH_MAIN_MENU: 'FETCH_MAIN_MENU',
        CREATE_USER: 'CREATE_USER',
        VERIFY_IDENT_USER_EXISTENCE: 'VERIFY_IDENT_USER_EXISTENCE',
        VERIFY_IDENT_USER_EXISTENCE_EXCEPT_THEMSELF: 'VERIFY_IDENT_USER_EXISTENCE_EXCEPT_THEMSELF',
        CREATE_USER_CLIENT: 'CREATE_USER_CLIENT',
        CREATE_CLIENT: 'CREATE_CLIENT',
        DEACTIVATE_USER: 'DEACTIVATE_USER',
        FETCH_CLIENT_BY_ID: 'FETCH_CLIENT_BY_ID',
        FETCH_EMPLOYEE_BY_ID: 'FETCH_EMPLOYEE_BY_ID',
        EDIT_CLIENT: 'EDIT_CLIENT',
        EDIT_EMPLOYEE: 'EDIT_EMPLOYEE',
        BLOCK_CLIENT: 'BLOCK_CLIENT',
        BLOCK_EMPLOYEE: 'BLOCK_EMPLOYEE',
        UNBLOCK_CLIENT: 'UNBLOCK_CLIENT',
        UNBLOCK_EMPLOYEE: 'UNBLOCK_EMPLOYEE',
        VERIF_CLIENT: 'VERIF_CLIENT',
        SEND_EMAIL: 'SEND_EMAIL',
        SEND_SMS: 'SEND_SMS',
        SEND_WHATSAPP: 'SEND_WHATSAPP',
        CHANGE_PASSWORD: 'CHANGE_PASSWORD',
        PROTECTED_ROUTE: 'PROTECTED_ROUTE',
        FETCH_LEVELS_QUESTIONS: 'FETCH_LEVELS_QUESTIONS',
        SAVE_SELECTED_COUNTRY_ID_DOC: 'SAVE_SELECTED_COUNTRY_ID_DOC',
        SAVE_SELECTED_DOC_QUESTION_NUMBER: 'SAVE_SELECTED_DOC_QUESTION_NUMBER',
        SAVE_USER_SIGNUP:'SAVE_USER_SIGNUP',
        SET_NEW_VERIF_LEVEL:'SET_NEW_VERIF_LEVEL',
        SET_NOT_AUTHENTICATED_UUID:'SET_NOT_AUTHENTICATED_UUID',
        CODE_REFERRER_EXISTS:'CODE_REFERRER_EXISTS',
        SAVE_AMBASSADOR_LINK:'SAVE_AMBASSADOR_LINK',
        FETCH_PUBLIC_IP:'FETCH_PUBLIC_IP'
    },
};
