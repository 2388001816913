export default {
    mutations: {
        SET_RATE_TYPES: 'SET_RATE_TYPES',
        SET_RANGE_RATES: 'SET_RANGE_RATES',
        SET_MIN_AMMOUNT_IN_REMITTANCE: 'SET_MIN_AMMOUNT_IN_REMITTANCE',
        SET_FULL_RATES: 'SET_FULL_RATES',
        SET_USER_LOCAL_RATES: 'SET_USER_LOCAL_RATES',
        SET_USER_RATES: 'SET_USER_RATES',
        SET_PROMO_RATES: 'SET_PROMO_RATES',
        SET_USD_TO_ALL_CURRENCIES: 'SET_USD_TO_ALL_CURRENCIES',
        SET_RATES_TAKEN_ADVANTAGE_OF: 'SET_RATES_TAKEN_ADVANTAGE_OF',
        RESET_RATES_DATA: 'RESET_RATES_DATA',
    },
    getters: {
        GET_RATE_TYPES: 'GET_RATE_TYPES',
        GET_RANGE_RATES: 'GET_RANGE_RATES',
        GET_MIN_AMMOUNT_IN_REMITTANCE: 'GET_MIN_AMMOUNT_IN_REMITTANCE',
        GET_FULL_RATES: 'GET_FULL_RATES',
        GET_USER_LOCAL_RATES: 'GET_USER_LOCAL_RATES',
        GET_USER_RATES: 'GET_USER_RATES',
        GET_PROMO_RATES: 'GET_PROMO_RATES',
        GET_USD_TO_ALL_CURRENCIES: 'GET_USD_TO_ALL_CURRENCIES',
        GET_RATES_TAKEN_ADVANTAGE_OF: 'GET_RATES_TAKEN_ADVANTAGE_OF',
    },
    actions: {
        FETCH_RATE_TYPES: 'SAVE_RATE_TYPES',
        FETCH_RANGE_RATES: 'FETCH_RANGE_RATES',
        FETCH_USER_RATES: 'FETCH_USER_RATES',
        SAVE_USER_RATES: 'SAVE_USER_RATES',
        FETCH_FULL_RATES: 'FETCH_FULL_RATES',
        FETCH_PROMO_RATES: 'FETCH_PROMO_RATES',
        SAVE_MIN_AMMOUNT_IN_REMITTANCE: 'SAVE_MIN_AMMOUNT_IN_REMITTANCE',
        FETCH_RATES_TAKEN_ADVANTAGE_OF: 'FETCH_RATES_TAKEN_ADVANTAGE_OF',
    },
};