import { AxiosRepository } from '@/http/axios.repository';
import { Bank } from '../interfaces/bank.interface';
import { BankAccount } from '../interfaces/bankAccounts.interface';

const BANK_RESOURCE = 'banks';
const BANK_ACCOUNTS = '/getBankAccountsById'
const BANK_ACCOUNT = '/getBankAccountById'
const ACCOUNTS = '/accounts'

export class BanksHttpRepository extends AxiosRepository {
    
    async getBanks(params: string): Promise<Bank[]> { 
        return super.getAll(BANK_RESOURCE+'?'+params);
    }

    async getBankById(bankId: number): Promise<Bank> { 
        return super.getAll(BANK_RESOURCE+'/'+bankId);
    }

    async getBankAccounts(countryId: number,currencyId: number): Promise<BankAccount[]> { 
        return super.getAll(BANK_RESOURCE+BANK_ACCOUNTS+'/'+countryId+'/'+currencyId);
    }

    async getBankAccountById(bankAccountId: number): Promise<BankAccount> { 
        return super.get(bankAccountId,BANK_RESOURCE+BANK_ACCOUNT);
    }

    async getBanksByPayMethod(payMethodId: number): Promise<Bank[]>{
        return super.getAll(BANK_RESOURCE+'/byPayMethod/'+payMethodId)
    }
    async getBanksAccountByPayMethod(payMethodId: number): Promise<BankAccount[]>{
        return super.get(payMethodId,BANK_RESOURCE+ACCOUNTS)
    }
}

export const banksHttpRepository = new BanksHttpRepository();