export default {
    mutations: {
        SET_CHAT_REMITTANCE: 'SET_CHAT_REMITTANCE',
        SET_REMITTANCE: 'SET_REMITTANCE',
        SET_NEW_REMITTANCE: 'SET_NEW_REMITTANCE',
        SET_CURRENT_BENEFICIARIES: 'SET_CURRENT_BENEFICIARIES',
        SET_NOTIFICATION_TYPES: 'SET_NOTIFICATION_TYPES',
        SET_LIMITS: 'SET_LIMITS',
        SET_TOTAL_ACUM: 'SET_TOTAL_ACUM',
        SET_WEEKLY_ACUM: 'SET_WEEKLY_ACUM',
        SET_MONTHLY_ACUM: 'SET_MONTHLY_ACUM',
        SET_VALID_OWN_EXT_TRANS: 'SET_VALID_OWN_EXT_TRANS',
        SET_VALID_THIRD_NAT_TRANSF: 'SET_VALID_THIRD_NAT_TRANSF',
        SET_VALID_THIRD_EXT_TRANSF: 'SET_VALID_THIRD_EXT_TRANSF',
        SET_RESID_COUNTRY_CURRENCY: 'SET_RESID_COUNTRY_CURRENCY',
        SET_MAX_BENEFICIARIES_PER_REMITTANCE: 'SET_MAX_BENEFICIARIES_PER_REMITTANCE',
        SET_PRE_REMITTANCE: 'SET_PRE_REMITTANCE',
        SET_MIN_AMOUNTS: 'SET_MIN_AMOUNTS',
        SET_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY: 'SET_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY',
        SET_REMITTANCE_TOTALS_BY_MONTH: 'SET_REMITTANCE_TOTALS_BY_MONTH',
        SET_REMITTANCES_BY_STATUS: 'SET_REMITTANCES_BY_STATUS',
        SET_TOTAL_REMITTANCES: 'SET_TOTAL_REMITTANCES',
        SET_REMITTANCES_QUANTITY_BY_MONTH: 'SET_REMITTANCES_QUANTITY_BY_MONTH',
        RESET_REMITTANCES_DATA: 'RESET_REMITTANCES_DATA',
        SET_WEBPAY_INFO: 'SET_WEBPAY_INFO',
    },
    getters: {
        GET_CHAT_REMITTANCE: 'GET_CHAT_REMITTANCE',
        GET_REMITTANCES: 'GET_REMITTANCES',
        GET_NEW_REMITTANCE: 'GET_NEW_REMITTANCE',
        GET_CURRENT_BENEFICIARIES: 'GET_CURRENT_BENEFICIARIES',
        GET_NOTIFICATION_TYPES: 'GET_NOTIFICATION_TYPES',
        GET_LIMITS: 'GET_LIMITS',
        GET_TOTAL_ACUM: 'GET_TOTAL_ACUM',
        GET_WEEKLY_ACUM: 'GET_WEEKLY_ACUM',
        GET_MONTHLY_ACUM: 'GET_MONTHLY_ACUM',
        GET_MAX_AMMOUNTS: 'GET_MAX_AMMOUNTS',
        GET_VALID_OWN_EXT_TRANS: 'GET_VALID_OWN_EXT_TRANS',
        GET_VALID_THIRD_NAT_TRANSF: 'GET_VALID_THIRD_NAT_TRANSF',
        GET_VALID_THIRD_EXT_TRANSF: 'GET_VALID_THIRD_EXT_TRANSF',
        GET_RESID_COUNTRY_CURRENCY: 'GET_RESID_COUNTRY_CURRENCY',
        GET_MAX_BENEFICIARIES_PER_REMITTANCE: 'GET_MAX_BENEFICIARIES_PER_REMITTANCE',
        GET_PRE_REMITTANCE: 'GET_PRE_REMITTANCE',
        GET_MIN_AMOUNTS: 'GET_MIN_AMOUNTS',
        GET_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY: 'GET_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY',
        GET_REMITTANCE_TOTALS_BY_MONTH: 'GET_REMITTANCE_TOTALS_BY_MONTH',
        GET_REMITTANCES_BY_STATUS: 'GET_REMITTANCES_BY_STATUS',
        GET_TOTAL_REMITTANCES: 'GET_TOTAL_REMITTANCES',
        GET_REMITTANCES_QUANTITY_BY_MONTH: 'GET_REMITTANCES_QUANTITY_BY_MONTH',
        GET_WEBPAY_INFO: 'GET_WEBPAY_INFO',
    },
    actions: {
        SEND_REMITTANCE: 'SEND_REMITTANCE',
        SEND_WEBPAY_REMITTANCE: 'SEND_WEBPAY_REMITTANCE',
        SET_REMITTANCE: 'SET_REMITTANCE',
        FETCH_CHAT_REMITTANCE: 'FETCH_CHAT_REMITTANCE',
        FETCH_REMITTANCES: 'FETCH_REMITTANCES',
        SAVE_CURRENT_BENEFICIARIES: 'SAVE_CURRENT_BENEFICIARIES',
        FETCH_NOTIFICATION_TYPES: 'FETCH_NOTIFICATION_TYPES',
        FETCH_LIMITS: 'FETCH_LIMITS',
        SAVE_PRE_REMITTANCE: 'SAVE_PRE_REMITTANCE',
        GET_PRE_REMITTANCE: 'GET_PRE_REMITTANCE',
        CANCEL_PRE_REMITTANCE: 'CANCEL_PRE_REMITTANCE',
        SET_PRE_REMITTANCE: 'SET_PRE_REMITTANCE',
        FETCH_MIN_AMOUNTS: 'FETCH_MIN_AMOUNTS',
        FETCH_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY: 'FETCH_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY',
        FETCH_REMITTANCE_TOTALS_BY_MONTH: 'FETCH_REMITTANCE_TOTALS_BY_MONTH',
        FETCH_REMITTANCES_BY_STATUS: 'FETCH_REMITTANCES_BY_STATUS',
        FETCH_REMITTANCES_QUANTITY_BY_MONTH: 'FETCH_REMITTANCES_QUANTITY_BY_MONTH',
        CREATE_WEBPAY_TRANSACTION: 'CREATE_WEBPAY_TRANSACTION',
    },
};
