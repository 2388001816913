
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
/* CONSTANTS */
/* INTERFACES */
/* FUNCTIONS */
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { showToast } from '@/utils/global-functions';
import { REMITTANCE_TYPES } from '@/modules/remittance/constants/remittance.constants';

@Component({
    components:{
        SvgIcon
    }
})
export default class SendRemittancePopup extends Vue {
    @Prop() showModal!: boolean;
    show: boolean = false;
    loading: boolean = false;
    answer=null;

    /* MODAL */
        @Watch('showModal')
        async renderModal(){
            this.show = this.showModal
            this.loading = true;
            this.loading = false;
            this.answer = null;
        }

        @Watch('show')
        sendToParent(newVal: boolean){
            this.$emit('changeModalStatus', newVal)
        }
    /* END MODAL */


    continueToRemittance(){
        if (this.answer === null) showToast('Debe seleccionar una opción para continuar','error')
        if (this.answer === true){ 
            if (this.$route.name !== 'SendRemittance' || (this.$route.name === 'SendRemittance' && this.$route.params.modo !== REMITTANCE_TYPES.WITH_CAPTURE))
            this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.WITH_CAPTURE}`}).catch();;
            this.show = false;
        }
        else if (this.answer === false) {
            if (this.$route.name !== 'SendRemittance' || (this.$route.name === 'SendRemittance' && this.$route.params.modo !== REMITTANCE_TYPES.NO_CAPTURE))
            this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.NO_CAPTURE}`}).catch();
            this.show = false;
        }
        
    }

}

