import axios from 'axios';

export class CurrencyFreaksRepository{

    private baseApiUrl = 'https://api.currencyfreaks.com/latest'
    private apiKey = '5c46f95f3f544e8e954a194f73a2f333'

    public async getCurrenciesRatesByBase(base: string,symbols: string): Promise<{date:string,base:string,rates:{}}> {
        return (await (axios.get(this.baseApiUrl+`?base=${base}&symbols=${symbols}&apikey=${this.apiKey}`))).data
    }
}

export const currencyFreaksRepository = new CurrencyFreaksRepository();