
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Loading from 'vue-loading-overlay';

@Component({
    components: {
    }
})
export default class Loader extends Vue {
    isLoading= true;
    fullPage= true
    $loading: any = null;
    loader: any = null;
    @Prop() viewMode?: boolean;

    showLoader() {
        let color = '#333';
        let opacity = 0.5;
        let transition = 'fade';
        if (this.viewMode) {
            color = '#fff'; opacity = 1; transition= 'none'
        }
        this.loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            color: '#ffb81c',
            width: 60,
            height: 60,
            opacity: opacity,
            backgroundColor: color,
            transition: transition
        });
    }
    hideLoader(){
        if (this.loader) this.loader.hide()     
    }
}
