import { namespace } from 'vuex-class';

export const ambassadors = namespace('ambassadors');
export const auth = namespace('auth');
export const balances = namespace('balances');
export const banks = namespace('banks');
export const beneficiaries = namespace('beneficiaries');
export const chat = namespace('chat');
export const countries = namespace('countries');
export const currencies = namespace('currencies');
export const docTypes = namespace('docTypes');
export const exchange = namespace('exchange');
export const notifications = namespace('notifications');
export const operationRoutes = namespace('operationRoutes');
export const payMethods = namespace('payMethods');
export const rates = namespace('rates');
export const remittance = namespace('remittance');
export const users = namespace('users')
export const verifLevels = namespace('verifLevels');
export const wholesalePartners = namespace('wholesalePartners')
