import { Module } from 'vuex';
import CurrenciesMethods from '@/store/currencies/methods/currencies.methods';
import { CURRENCIES_EMPTY_STATE } from './currencies.state';
import { CurrenciesStateInterface } from './interfaces/currencies.interface';
import { currenciesHttpRepository } from '@/modules/currencies/repositories/currencies.repository';
import { dateToDDMMYYY } from '@/utils/date-functions';
import { CryptoCurrency, Currency } from '@/modules/currencies/interfaces/currencies.interface';
import { getParams } from '@/utils/global-functions';
import { CountryCurrency } from '@/modules/countries/interfaces/countries.interface';

const currencies: Module<CurrenciesStateInterface, any> = {
    namespaced: true,
    state: CURRENCIES_EMPTY_STATE,
    getters: {
        [CurrenciesMethods.getters.GET_TOTAL_SENT_BY_CURRENCIES](state): CountryCurrency[]{
            return state.totalSentByCurrency
        },
        [CurrenciesMethods.getters.GET_CRYPTO_CURRENCIES](state): CryptoCurrency[]{
            return state.cryptoCurrencies
        }
    },
    mutations: {
        [CurrenciesMethods.mutations.SET_TOTAL_SENT_BY_CURRENCIES](state, totalSentByCurrency : CountryCurrency[]): void {
            state.totalSentByCurrency = totalSentByCurrency;
        },
        [CurrenciesMethods.mutations.SET_CRYPTO_CURRENCIES](state, cryptoCurrencies : CryptoCurrency[]): void {
            state.cryptoCurrencies = cryptoCurrencies;
        },
        [CurrenciesMethods.mutations.RESET_CURRENCIES_DATA](state): void {
            state.totalSentByCurrency = [],
            state.cryptoCurrencies = []
        },
    },
    actions: {
        async [CurrenciesMethods.actions.FETCH_CURRENCIES_BY_COUNTRIES]({ commit }, params: any): Promise<Currency[] | undefined> {
            try {
                let httParams = getParams(params)
                let currencies : Currency[] = await currenciesHttpRepository.getCurrenciesByCountries(httParams)
                if (currencies.length > 0){
                    return currencies;
                }
                return undefined
            } catch (e) {
                return undefined;
            }
        },
        async [CurrenciesMethods.actions.FETCH_CRYPTO_CURRENCIES]({ commit }): Promise<boolean> {
            try {
                let currencies : CryptoCurrency[] = await currenciesHttpRepository.getCryptoCurrencies()
                if (currencies.length > 0){
                    commit(CurrenciesMethods.mutations.SET_CRYPTO_CURRENCIES,currencies)
                    return true;
                }
                return false
            } catch (e) {
                return false;
            }
        },
        async [CurrenciesMethods.actions.FETCH_TOTAL_SENT_BY_CURRENCIES]({ commit }, email_user: string): Promise<boolean> {
            try {
                let totalByCurrency : CountryCurrency[] = await currenciesHttpRepository.getTotalSentByCurrencies(email_user)
                if (totalByCurrency === null) totalByCurrency = []
                commit(CurrenciesMethods.mutations.SET_TOTAL_SENT_BY_CURRENCIES,totalByCurrency)
                return true
            } catch (e) {
                return false;
            }
        },
    }
};

export default currencies;