import { AmbassadorsStateInterface } from './interfaces/ambassadors.interface';

export const AMBASSADORS_EMPTY_STATE: AmbassadorsStateInterface = {
    referralsInfo:{
        total_referrals:0,
        referrals:[]
    },
    referralsOperations:{
        operations: [],
        total_operations: 0
    },
    referralsByCountries: [],
    referralsByStatus: [],
    cryptoMiles:[]
};
