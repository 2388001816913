import { AxiosRepository } from '@/http/axios.repository';
import { DepositMethod } from '../interfaces/depositMethods.interface';
import { PayMethod } from '../interfaces/payMethods.interface';

const PAY_METHODS_RESOURCE = 'pay_methods';
const DEPOSIT_METHODS = '/depositMethodsByBank'

export class PayMethodsRepository extends AxiosRepository {
    
    async getPayMethodsByCountryId(data: string): Promise<PayMethod[]> {
        return await super.getAll(PAY_METHODS_RESOURCE+data);
    }

    async getPayMethodById(payMethodId: number): Promise<PayMethod> {
        return await super.getAll(PAY_METHODS_RESOURCE+'/'+payMethodId);
    }

    async getDepositMethodByBankId(bankId: number): Promise<DepositMethod[]> {
        return await super.get(bankId,PAY_METHODS_RESOURCE+DEPOSIT_METHODS);
    }

}

export const payMethodHttpRepository = new PayMethodsRepository();