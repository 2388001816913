import { Module } from 'vuex';
import BeneficiariesMethods from '@/store/beneficiaries/methods/beneficiaries.methods';
import { BENEFICIARIES_EMPTY_STATE } from './benficiaries.state';
import { BeneficiariesStateInterface } from './interfaces/beneficiaries.interface';
import { beneficiariesHttpRepository } from '@/modules/beneficiaries/repositories/beneficiaries.repository';
import { FrequentBeneficiary, TopFrequentBeneficiary } from '@/modules/beneficiaries/interfaces/beneficiaries.interfaces';
import { getParams } from '@/utils/global-functions';

const beneficiaries: Module<BeneficiariesStateInterface, any> = {
    namespaced: true,
    state: BENEFICIARIES_EMPTY_STATE,
    getters: {
        [BeneficiariesMethods.getters.GET_FREQUENT_BENEFICIARIES](state): FrequentBeneficiary[]{
            return state.frequentBeneficiaries
        },
        [BeneficiariesMethods.getters.GET_SIMULATED_FREQUENT_BENEFICIARIES](state): FrequentBeneficiary[]{
            return state.simulatedFrequentBeneficiaries
        },
        [BeneficiariesMethods.getters.GET_TOP_FREQUENT_BENEFICIARIES](state): TopFrequentBeneficiary[]{
            return state.topFrequentBeneficiaries
        }
    },
    mutations: {
        [BeneficiariesMethods.mutations.SET_FREQUENT_BENEFICIARIES](state, beneficiaries : FrequentBeneficiary[]): void {
            state.frequentBeneficiaries = beneficiaries;
        },
        [BeneficiariesMethods.mutations.SET_TOP_FREQUENT_BENEFICIARIES](state, beneficiaries : TopFrequentBeneficiary[]): void {
            state.topFrequentBeneficiaries = beneficiaries;
        },
        [BeneficiariesMethods.mutations.RESET_BENEFICIARIES_DATA](state): void {
            state.frequentBeneficiaries= [],
            state.simulatedFrequentBeneficiaries= [],
            state.topFrequentBeneficiaries= []
        },
    },
    actions: {
        async [BeneficiariesMethods.actions.FETCH_FREQUENT_BENEFICIARIES]({ commit },emailObj: any): Promise<boolean> {
            try {
                let emailParam = getParams(emailObj)
                let response : FrequentBeneficiary[] = await beneficiariesHttpRepository.getAllFrequentBeneficiaries(emailObj.email_user)
                if (response.length > 0){
                    response.map((obj) => {
                        obj.check = false;
                        obj.frequent = true
                        return obj;
                    })
                    commit(BeneficiariesMethods.mutations.SET_FREQUENT_BENEFICIARIES,response);
                }
                else commit(BeneficiariesMethods.mutations.SET_FREQUENT_BENEFICIARIES,[]);
                return true
            } catch (e) {
                return false;
            }
        },
        async [BeneficiariesMethods.actions.CREATE_FREQUENT_BENEFICIARY]({ commit },params:{emailObj: any, beneficiary: FrequentBeneficiary,docTypePrefix: string}): Promise<{msg: string, error: boolean,id_beneficiary?: number}> {
            try {
                let emailParam = getParams(params.emailObj)
                params.beneficiary.email_user = params.emailObj.email_user;
                if (params.docTypePrefix) params.beneficiary.identification = params.docTypePrefix+'-'+params.beneficiary.identification;
                let response : FrequentBeneficiary = await beneficiariesHttpRepository.createFrequentBeneficiary(params.beneficiary,emailParam)
                if (response.id_beneficiary){
                    return {msg: 'Se ha creado el beneficiario exitosamente',error: false,id_beneficiary: parseInt(response.id_beneficiary)};
                }
                return {msg: 'Ha ocurrido un error creado el beneficiario',error: true};
            } catch (e) {
                if (e.response.data.msg === "Beneficiary already exists") return {msg: 'El alias ya se encuentra registrado',error: true};
                if (e.response.data.msg === "Limit exceeded") return {msg: 'Usted ha excedido el limite de beneficiarios (20)',error: true};
                return {msg: 'Ha ocurrido un error creando el beneficiario',error: true};
            }
        },
        async [BeneficiariesMethods.actions.UPDATE_FREQUENT_BENEFICIARY]({ commit },params:{beneficiaryId: number, beneficiary: FrequentBeneficiary}): Promise<{msg: string, error: boolean}> {
            try {
                let response : boolean = await beneficiariesHttpRepository.updateFrequentBeneficiary(params.beneficiary,params.beneficiaryId)
                if (response){
                    return {msg: 'Se ha actualizado el beneficiario exitosamente',error: false};
                }
                return {msg: 'Ha ocurrido un error actualizando el beneficiario',error: true};
            } catch (e) {
                return {msg: 'Ha ocurrido un error actualizando el beneficiario',error: true};
            }
        },
        async [BeneficiariesMethods.actions.DELETE_FREQUENT_BENEFICIARY]({ commit },beneficiaryId: string): Promise<{msg: string, error: boolean}> {
            try {
                let response : boolean = await beneficiariesHttpRepository.deleteFrequentBeneficiary(parseInt(beneficiaryId))
                if (response){
                    return {msg: 'Se ha eliminado el beneficiario exitosamente',error: false};
                }
                return {msg: 'Ha ocurrido un error eliminando el beneficiario',error: true};
            } catch (e) {
                return {msg: 'Ha ocurrido un error eliminando el beneficiario',error: true};
            }
        },
        async [BeneficiariesMethods.actions.FETCH_TOP_FREQUENT_BENEFICIARIES]({ commit },email_user: string): Promise<boolean> {
            try {
                let beneficiaries : TopFrequentBeneficiary[] = await beneficiariesHttpRepository.getTopFrequentBeneficiaries(email_user);
                if (beneficiaries === null) beneficiaries = [];
                commit(BeneficiariesMethods.mutations.SET_TOP_FREQUENT_BENEFICIARIES,beneficiaries);
                return true;
            } catch (e) {
                return false;
            }
        },
        async [BeneficiariesMethods.actions.FETCH_BENEFICIARY_CONTACT_REQUIRED]({ commit },countryId: number): Promise<{requiredContact: boolean}> {
            try {
                let contactRequired : any = await beneficiariesHttpRepository.getContactRequirements(countryId);
                return contactRequired;
            } catch (e) {
                return null;
            }
        },
    },
};

export default beneficiaries;