import { AxiosRepository } from '@/http/axios.repository';
import { Rate } from '@/modules/rates/interfaces/rate.interface';
import { WholeSalePartnerUser } from '../interfaces/client.interface';
import { WholeSalePartnerUserRemittance } from '../interfaces/clientRemittance.interface';
import { WholesalePartnerReport } from '../interfaces/reports.interface';

const WP_RESOURCE = 'wholesale_partners';

export class WholesalePartnersRepository extends AxiosRepository {
    
    async sendWholeSalePartnerPageIngo(body: any,email_user: string): Promise<{message: string}> { 
        return super.postData(body,WP_RESOURCE+'/info/'+email_user);
    }

    async getWholeSalePartnerClients(slug: string): Promise<WholeSalePartnerUser[]> { 
        return super.getAll(WP_RESOURCE+'/clients/'+slug+'?full=true');
    }

    async getWholeSalePartnerClientsRemittances(slug: string): Promise<WholeSalePartnerUserRemittance[]> { 
        return super.getAll(WP_RESOURCE+'/clients-remittances/'+slug+'?full=true');
    }

    async updatePercentProfit(slug: string,data:{percentProfit: number}): Promise<{message: string}> { 
        return super.put(data,WP_RESOURCE+'/percent-profit/'+slug);
    }

    async getWholeSalePartnerReports(slug: string): Promise<WholesalePartnerReport> { 
        return super.getAll('reports/'+WP_RESOURCE+'/'+slug);
    }

    async getWholeSalePartnerRates(slug: string): Promise<Rate[]> { 
        return super.get(slug,WP_RESOURCE+'/rates');
    }

}

export const wholeSalePartnerHttpRepository = new WholesalePartnersRepository();