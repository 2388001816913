import { AxiosRepository } from '@/http/axios.repository';
import { Account } from '../interfaces/account.interface';

const USERS_RESOURCE = 'users'
const ACCOUNT_RESOURCE = '/accounts'
const VALIDATE_CODE_RESOURCE = '/validate-code'

export class UsersRepository extends AxiosRepository {
  
    async getUserAccounts(email_user: string) : Promise<Account[]>{
        return super.get(email_user,USERS_RESOURCE+ACCOUNT_RESOURCE)
    }

    async insertUserAccount(email_user: string,body: Partial<Account>){
        return super.post(body,USERS_RESOURCE+ACCOUNT_RESOURCE+`/${email_user}`)
    }

    async deleteUserAccount(id_user_account: number): Promise<{message: string}>{
        return super.delete(id_user_account,USERS_RESOURCE+ACCOUNT_RESOURCE)
    }

    async validateCode(body: {ident_user: string, code: string}): Promise<{ident_user: string, msg:string}>{
        return super.post(body,USERS_RESOURCE+VALIDATE_CODE_RESOURCE)
    }
}

export const usersHttpRepository = new UsersRepository();