export const REMITTANCE_PUBLIC_STATUS = {
    IN_PROGRESS : 'En Proceso',
    IN_PROGRESS_2 : 'En Curso',
    PENDING_FOR_TRANSFER: 'Por Transferir',
    FINISHED: 'Completada',
    CANCELED: 'Cancelada',
    STARTED: 'Iniciada',
    PAUSED: 'Pausada' // Si REMITTANCE_DETOUR_STATUS
}

export const REMITTANCE_DETOUR_STATUS = {
    IN_CLAIM: 'IN-CLAIM',
    CANCELED: 'CANCELED',
    FAIL_BANK_VERF: 'FAIL_BANK_VERF'
}

export const REMITTANCE_TYPES = {
    WITH_CAPTURE: 'con-comprobante',
    NO_CAPTURE: 'sin-comprobante'
}