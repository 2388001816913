export default {
    mutations: {
        SET_FIAT_BALANCE: 'SET_FIAT_BALANCE',
        SET_CRYPTO_BALANCE: 'SET_CRYPTO_BALANCE',
        RESET_BALANCE_DATA: 'RESET_BALANCE_DATA',
    },
    getters: {
        GET_FIAT_BALANCE: 'GET_FIAT_BALANCE',
        GET_CRYPTO_BALANCE: 'GET_CRYPTO_BALANCE',
    },
    actions: {
        FETCH_BALANCE_BY_DESTINY_COUNTRY: 'FETCH_BALANCE_BY_DESTINY_COUNTRY',
    },
};