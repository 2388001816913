import { NewRemittance } from "../interfaces/newRemittance.interface";

export const NEW_REMITTANCE_EMPTY_STATE = {
    idChat: null,
    email_user: '',
    mode: 'app',
    messages: [],
    pending: false,
    holder: {
      idBankAccount: null,
      bankName: '',
      holderName: '',
      accountNumber: '',
      idBank: null,
      pay_method_viewing_name: null
    },
    originCountry: { // YA
      idCountry: '',
      name: '',
      code: ''
    },
    countryCurrency: {
      idCourrency: 0,
      name: '',
      isoCode: '',
      type: ''
    },
    bank: {
      idBank: null,
      name: ''
    },
    thirdPartyTransfer: {
      response: false,
      country:{
        id_country: null,
        name_country: '',
        country_iso_code: '',
        viewing_name: '',
        name: ''
    },
      bank: {
        idBank: null,
        name: ''
    },
      currency:{
        idCourrency: null,
        name: '',
        isoCode: '',
        type: ''
    },
      docType:{
        id_ident_doc_type: null,
        name_doc_type: '',
        id_resid_country: null,
        type_doc_type: '',
        name_country: ''
    },
      holder: '',
      id: '',
      accountNumber: '',
      pathNameDoc: null,
      nameBankDoc: null
  },
    captures: [
            // {
            //       messageId: "",
            //       ammount: 0,
            //       ref: "",
            //       date: "",
            //       content: null
            //   }
            ],
    totalDeposited: 0,
    totalDepositedInLocalCurrency: 0,
    localCurrencyRateType: null,
    localCurrencyRateValue: {
      rate_factor: 0.0,
      operation: ''
    },
    totalDepositedDestiny: 0,
    totalComission: 0,
    percentFee: null,
    amountFee: null,
    totalOriginRemittance: 0,
    totalOriginRemittanceInLocalCurrency: 0,
    totalDestinationRemittance: 0,
    depositMethod:{
      name: '',
      id_country: null,
      id_currency: null,
      id_pay_method: null
  },
    rateType: {
      idRateType: 0,
      name: ''
    },
    rateValue: {
      rate_factor: null,
      operation: ''
    },
    rateCategory: null,
    rangeRatesByOriginCountryAndCurrency: [],
    ratesByCountries: [],
    beneficiariesInfo: {
      originDistribution: true,
      destinationCountry: {
        idCountry: '',
        name: '',
        code: ''
      },
      destinationCurrency: {
        idCourrency: 0,
        name: '',
        isoCode: '',
        type: ''
      },
      quantity: 0,
      beneficiaries: []
    },
    comments: []
  }


  export function clearRemittance(remittance: NewRemittance){
    remittance.idChat= null;
    remittance.email_user= '';
    remittance.mode= 'app';
    remittance.messages= [];
    remittance.pending= false;
    remittance.holder= {
      idBankAccount: null,
      bankName: '',
      holderName: '',
      accountNumber: '',
      idBank: null,
      pay_method_viewing_name: null
    };
    remittance.originCountry= { // YA
      idCountry: '',
      name: '',
      code: ''
    };
    remittance.countryCurrency= {
      idCourrency: 0,
      name: '',
      isoCode: '',
      type: ''
    };
    remittance.bank= {
      idBank: null,
      name: ''
    };
    remittance.thirdPartyTransfer= {
      response: false,
      country:{
        id_country: null,
        name_country: '',
        country_iso_code: '',
        viewing_name: '',
        name: ''
    },
    bank: {
        idBank: null,
        name: ''
    },
      currency:{
        idCourrency: null,
        name: '',
        isoCode: '',
        type: ''
    },
      docType:{
        id_ident_doc_type: null,
        name_doc_type: '',
        id_resid_country: null,
        type_doc_type: '',
        name_country: ''
    },
      holder: '',
      id: '',
      accountNumber: '',
      pathNameDoc: null,
      nameBankDoc: null
  };
  remittance.captures= [];
remittance.totalDeposited= 0;
remittance.totalDepositedDestiny= 0;
remittance.totalComission= 0;
remittance.percentFee= null;
remittance.amountFee= null;
remittance.totalOriginRemittance= 0;
remittance.totalDestinationRemittance= 0;
remittance.depositMethod={
      name: '',
      id_country: null,
      id_currency: null,
      id_pay_method: null
  };
  remittance.rateType= {
      idRateType: 0,
      name: ''
    };
    remittance.rateValue= {
      rate_factor: 0.0,
      operation: ''
    };
    remittance.rangeRatesByOriginCountryAndCurrency= [];
    remittance.ratesByCountries= [];
    remittance.beneficiariesInfo= {
      originDistribution: true,
      destinationCountry: {
        idCountry: '',
        name: '',
        code: ''
      },
      destinationCurrency: {
        idCourrency: 0,
        name: '',
        isoCode: '',
        type: ''
      },
      quantity: 0,
      beneficiaries: []
    },
    remittance.comments= []
  remittance.totalDepositedInLocalCurrency = 0;
  remittance.totalOriginRemittanceInLocalCurrency = 0;
  return remittance
}
