export default {
    mutations: {
        SET_RESIDENCE_COUNTRIES: 'SET_RESIDENCE_COUNTRIES',
        SET_NATIONALITY_COUNTRIES: 'SET_NATIONALITY_COUNTRIES',
        SET_ALL_COUNTRIES: 'SET_ALL_COUNTRIES',
        SET_DESTINY_COUNTRIES: 'SET_DESTINY_COUNTRIES',
        SET_COUNTRY_STATES: 'SET_COUNTRY_STATES',
        SET_ORIGIN_COUNTRY_CURRENCY: 'SET_ORIGIN_COUNTRY_CURRENCY',
        SET_DESTINY_COUNTRY_CURRENCY: 'SET_DESTINY_COUNTRY_CURRENCY',
        SET_USER_ORIGIN_REMITTANCES_COUNTRY_CURRENCY: 'SET_USER_ORIGIN_REMITTANCES_COUNTRY_CURRENCY',
        SET_GLOBAL_DESTINY_COUNTRY_CURRENCY: 'SET_GLOBAL_DESTINY_COUNTRY_CURRENCY',
        SET_GLOBAL_ORIGIN_COUNTRY_CURRENCY: 'SET_GLOBAL_ORIGIN_COUNTRY_CURRENCY',
        SET_FREQUENTS_DESTINATIONS: 'SET_FREQUENTS_DESTINATIONS',
        RESET_COUNTRIES_DATA: 'RESET_COUNTRIES_DATA',
    },
    getters: {
        GET_ALL_RESIDENCE_COUNTRIES: 'GET_ALL_RESIDENCE_COUNTRIES',
        GET_ALL_NATIONALITY_COUNTRIES: 'GET_ALL_NATIONALITY_COUNTRIES',
        GET_ALL_COUNTRIES: 'GET_ALL_COUNTRIES',
        GET_DESTINY_COUNTRIES: 'GET_DESTINY_COUNTRIES',
        GET_COUNTRY_STATES: 'GET_COUNTRY_STATES',
        GET_ORIGIN_COUNTRY_CURRENCY: 'GET_ORIGIN_COUNTRY_CURRENCY',
        GET_DESTINY_COUNTRY_CURRENCY: 'GET_DESTINY_COUNTRY_CURRENCY',
        GET_USER_ORIGIN_REMITTANCES_COUNTRY_CURRENCY: 'GET_USER_ORIGIN_REMITTANCES_COUNTRY_CURRENCY',
        GET_GLOBAL_DESTINY_COUNTRY_CURRENCY: 'GET_GLOBAL_DESTINY_COUNTRY_CURRENCY',
        GET_GLOBAL_ORIGIN_COUNTRY_CURRENCY: 'GET_GLOBAL_ORIGIN_COUNTRY_CURRENCY',
        GET_FREQUENTS_DESTINATIONS: 'GET_FREQUENTS_DESTINATIONS',
    },
    actions: {
        FETCH_ALL_RESIDENCE_COUNTRIES: 'FETCH_ALL_RESIDENCE_COUNTRIES',
        FETCH_DESTINY_COUNTRIES: 'FETCH_DESTINY_COUNTRIES',
        FETCH_ALL_NATIONALITY_COUNTRIES: 'FETCH_ALL_NATIONALITY_COUNTRIES',
        FETCH_ALL_COUNTRIES: 'FETCH_ALL_COUNTRIES',
        FETCH_COUNTRY_ID_BY_NAME: 'FETCH_COUNTRY_ID_BY_NAME',
        FETCH_COUNTRY_ID_BY_NAME_CLIENT: 'FETCH_COUNTRY_ID_BY_NAME_CLIENT',
        GET_ISO_CODE: 'GET_ISO_CODE',
        POLITICALLY_EXPOSE_QUESTION: 'POLITICALLY_EXPOSE_QUESTION',
        FETCH_COUNTRY_STATES_BY_RESID_COUNTRY: 'FETCH_COUNTRY_STATES_BY_RESID_COUNTRY',
        FETCH_ORIGN_DEST_COUNTRY_CURRENCY: 'FETCH_ORIGN_DEST_COUNTRY_CURRENCY',
        SAVE_GLOBAL_DESTINY_COUNTRY_CURRENCY: 'SAVE_GLOBAL_DESTINY_COUNTRY_CURRENCY',
        SAVE_GLOBAL_ORIGIN_COUNTRY_CURRENCY: 'SAVE_GLOBAL_ORIGIN_COUNTRY_CURRENCY',
        FETCH_FREQUENTS_DESTINATIONS: 'FETCH_FREQUENTS_DESTINATIONS',
    },
};