import { AxiosRepository } from '@/http/axios.repository';
import { NationalityCountry } from '@/modules/countries/interfaces/countries.interface';
import { Limitation, LimitationType } from '../interfaces/limitations.interface';
import { MigratedData } from '../interfaces/migrated.interface';
import { MigrationStatus } from '../interfaces/migrationStatus.interface';
import { Question } from '../interfaces/questions.interface';
import { ReqWholeSaleParter } from '../interfaces/requests.interface';

const LEVELS_QUESTIONS_RESOURCE = 'users/getLevelQuestions';
const REQ_LEVEL_TWO_RESOURCE = 'users/requestLevelTwo';
const REQ_WHOLESALE_PARTNER_RESOURCE = 'veriflevels/requestWholesalePartner';
const AVAILABLE_REDID_COUNTRIES_RESOURCE = 'veriflevels/getWholesalePartnerRequestsCountries';
const MIGRATION_STATUS_RESOURCE = 'veriflevels/getMigrationStatus';
const MIGRATED_DATA_RESOURCE = 'users/migrated';
const LIMITATIONS_RESOURCE = 'veriflevels/getLimitationsByCountry';
const VERIF_LEVEL_REQ_RESOURCE = 'veriflevels/getVerifLevelRequirements';
const WHOLESALEPARTNER_REQ_RESOURCE = 'veriflevels/getWholesalePartnerRequestsRequirementsByEmail'
const VERIFY_LEVEL_ONE = 'users/requestLevelOne1stQ'
const VERIFY_LEVEL_ONE_2 = 'users/requestLevelOne2ndQ'
const VERIFY_LEVEL_ONE_3 = 'users/requestLevelOne3rdQ'
const UPDATE_CLIENT_PHONE = 'users/edit-phone'
const UPDATE_LEVEL_ONE_INFO = 'users/edit-level-one-info'

export class LevelsRepository extends AxiosRepository {
    
    async getLevelQuestions(idResidCountry: number): Promise<Question[]> { 
        return super.get(idResidCountry,LEVELS_QUESTIONS_RESOURCE);
    }

    async requestLevelOneFirstQuestion(data: any ): Promise<any>{
        return super.postData(data,VERIFY_LEVEL_ONE);
    }
    async requestLevelOneSecondQuestion(data: any ): Promise<any>{
        return super.postData(data,VERIFY_LEVEL_ONE_2);
    }
    async requestLevelOneThirdQuestion(data: any ): Promise<any>{
        return super.postData(data,VERIFY_LEVEL_ONE_3);
    }

    async editClientPhone(data: any): Promise<any>
    {
        return super.patch(data.uuid_user,UPDATE_CLIENT_PHONE, data);
    }

    async updateLevelOneInfo(data: any): Promise<any>
    {
        return super.post(data,UPDATE_LEVEL_ONE_INFO);
    }

    async requestLevelTwo(data: FormData): Promise<any>{ 
        return super.postData(data,REQ_LEVEL_TWO_RESOURCE)
    }

    async requestWholeSale(data: ReqWholeSaleParter): Promise<any>{ 
        return super.post(data,REQ_WHOLESALE_PARTNER_RESOURCE)
    }

    async getMigrationStatus(): Promise<MigrationStatus[]> { 
        return super.getAll(MIGRATION_STATUS_RESOURCE);
    }

    async getMigratedData(idMigrated: number): Promise<MigratedData> { 
        return super.get(idMigrated, MIGRATED_DATA_RESOURCE);
    }

    async getAvailableResidCountries(): Promise<NationalityCountry[]> { 
        return super.getAll(AVAILABLE_REDID_COUNTRIES_RESOURCE);
    }

    async getLimitationsByCountryId(countryId: string): Promise<{limitations: Limitation[], name_limitations: LimitationType[]}>{
        return super.getAll(LIMITATIONS_RESOURCE+'/'+countryId)
    }

    async getVerifLevelRequirements(emailUser: string): Promise<any>{
        return super.getAll(VERIF_LEVEL_REQ_RESOURCE+'/'+emailUser)
    }

    async getWholeSalePartnerRequirements(emailUser: string): Promise<any>{
        return super.getAll(WHOLESALEPARTNER_REQ_RESOURCE+'/'+emailUser)
    }
}

export const verifLevelHttpRepository = new LevelsRepository();